import type { ArrayElement } from '@lib/types/arrayElement'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import { PowerOntologyInclusiveSetAny } from '../../parseEligibilityCriteria/types'
import { getDiseaseStageTermsFromEligibilityCriteria } from './getDiseaseStageTermsFromEligibilityCriteria'

/**
 * Retrieve a string array set of terms representing the inclusion for disease stage for
 * this trial. Should be of the form ["Stage I", "Stage II"] or ["any"]. If no terms are found, add "any" for
 * inclusion criteria
 */
export function getDiseaseStageInclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const diseaseStages = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria(),
    new Set<string>(),
  )

  if (diseaseStages.size === 0) {
    diseaseStages.add(PowerOntologyInclusiveSetAny)
  }

  return Array.from(diseaseStages).sort()
}

/**
 * Retrieve a string array set of terms representing the exclusion set for disease stage for
 * this trial. Should be of the form ["Stage I"]
 */
export function getDiseaseStageExclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const diseaseStages = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria({ isInclusion: false }),
    new Set<string>(),
  )

  return Array.from(diseaseStages).sort()
}

function extractTermsFromEligibilityCriteria(
  { isInclusion }: { isInclusion: boolean } = { isInclusion: true },
) {
  return (
    terms: Set<string>,
    criterion: ArrayElement<
      TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
    >,
  ) =>
    getDiseaseStageTermsFromEligibilityCriteria({
      criterion,
      isInclusion,
      terms,
    })
}
