import hasUpdateInLastTwoYears from '@modules/trials/helpers/hasUpdateInLastTwoYears'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'

export default function getCustomRankScore(trial: TrialInDatabaseForAlgolia) {
  const rankHasUpdateInLastTwoYears = hasUpdateInLastTwoYears(trial) ? 1 : 0
  const rankScorePhase = getRankScorePhase(trial.phaseList)

  return {
    rankHasUpdateInLastTwoYears,
    rankScorePhase,
  }
}

// Phases: Phase 3 - 9; Phase 4 - 8; Phase 2 - 7; Phase 1, Early Phase 1 - 6; Not Applicable, NULL - 5;
function getRankScorePhase(phaseList: string[]) {
  if (phaseList.includes('Phase 3')) {
    return 9
  } else if (phaseList.includes('Phase 4')) {
    return 8
  } else if (phaseList.includes('Phase 2')) {
    return 7
  } else if (
    phaseList.includes('Phase 1') ||
    phaseList.includes('Early Phase 1')
  ) {
    return 6
  }

  return 5
}
