import Button from '@components/primitives/Button'
import { Icon } from '@components/primitives/Icon'
import { classMerge } from '@components/utilities/classMerge'
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import Routes from '@lib/routes'
import type { MouseEvent } from 'react'

const searchButtonProps = (onClick?: (event: MouseEvent) => void) => {
  return {
    className: 'rounded-full w-full h-14',
    color: 'warm' as 'warm',
    onClick,
    value: (
      <div className='flex flex-row items-center'>
        <Icon icon={faSearch} />
      </div>
    ),
  }
}

const SearchButton = ({
  onClick,
}: {
  hideOnMobile?: boolean
  onClick?: () => void
}) => {
  return (
    <Button
      {...searchButtonProps(onClick)}
      className={classMerge('rounded-full', searchButtonProps().className)}
    />
  )
}

const LinkedSearchButton = ({
  onClick,
}: {
  hideOnMobile?: boolean
  onClick?: (event: MouseEvent) => void
}) => {
  return (
    <Button.Link
      {...searchButtonProps(onClick)}
      className={classMerge('rounded-full', searchButtonProps().className)}
      href={Routes.search}
    />
  )
}

SearchButton.Link = LinkedSearchButton

export default SearchButton
