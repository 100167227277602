import { Icon } from '@components/primitives/Icon'
import { faLocation } from '@fortawesome/pro-regular-svg-icons/faLocation'
import type { Coordinates } from '../LocationConfiguration'
import LocationConfiguration from '../LocationConfiguration'

export const LocationPicker = ({
  conditionPlaceholder,
  interventionPlaceholder,
  locationPlaceholder,
  queryCoordinates,
  setQueryCoordinates,
}: {
  conditionPlaceholder?: string
  interventionPlaceholder?: string
  locationPlaceholder?: string
  queryCoordinates: Coordinates
  setQueryCoordinates: (coord: Coordinates) => void
}) => {
  return (
    <div className='flex flex-row items-start justify-start'>
      <div className='w-6 pt-1'>
        <Icon className='mr-2' icon={faLocation} />
      </div>
      <div className='w-9/10'>
        <LocationConfiguration
          conditionPlaceholder={conditionPlaceholder}
          interventionPlaceholder={interventionPlaceholder}
          locationPlaceholder={locationPlaceholder}
          placeholderText={'Nearest City'}
          queryCoordinates={queryCoordinates}
          setQueryCoordinates={setQueryCoordinates}
        />
      </div>
    </div>
  )
}
