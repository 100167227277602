import { ShimmerProgressBar } from '../ShimmerProgressBar'
import { Text } from '../Text'

export const ShimmerLoader = ({
  isPromptVisible = false,
}: {
  isPromptVisible?: boolean
}) => {
  return (
    <>
      <Text styleName='h1' value={`Loading...`} />
      <div className='mx-auto mt-24 w-3/4 md:w-1/2'>
        <ShimmerProgressBar
          bgColor='bg-primary500'
          className='h-10 rounded-full'
        />
        <Text
          className={`mt-4 transition-all duration-1000 ${isPromptVisible ? 'opacity-100' : 'opacity-0'}`}
          styleName='p'
          value={`Hang tight! We're almost done.`}
        />
      </div>
    </>
  )
}
