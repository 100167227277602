// https://www.algolia.com/doc/api-reference/api-parameters/hitsPerPage/
export const TRIALS_PER_PAGE_MAX = 100

type TherapeuticArea = {
  keywords: string[]
  name: string
}

export const THERAPEUTIC_AREA_NAMES = {
  cardiovascular: 'cardiovascular',
  cns: 'cns',
  dermatology: 'dermatology',
  diabetes: 'diabetes',
  endocrinology: 'endocrinology',
  gastroenterology: 'gastroenterology',
  haematology: 'haematology',
  immunology: 'immunology',
  infectiousDiseases: 'infectiousDiseases',
  inflammation: 'inflammation',
  internalDiseases: 'internalDiseases',
  metabolicDiseases: 'metabolicDiseases',
  nephrology: 'nephrology',
  neurology: 'neurology',
  oncology: 'oncology',
  ophthalmology: 'ophthalmology',
  orthopaedics: 'orthopaedics',
  osteoporosis: 'osteoporosis',
  psychiatry: 'psychiatry',
  pulmonology: 'pulmonology',
  respiratory: 'respiratory',
  rheumatology: 'rheumatology',
  surgery: 'surgery',
  thrombosis: 'thrombosis',
  urology: 'urology',
  vaccines: 'vaccines',
}

export const THERAPEUTIC_AREAS_MAP: TherapeuticArea[] = [
  {
    keywords: ['cardiovascular', 'heart', 'vascular'],
    name: THERAPEUTIC_AREA_NAMES.cardiovascular,
  },
  {
    keywords: [
      'central nervous system',
      'nervous system',
      'central nervous',
      'cerebrospinal',
    ],
    name: THERAPEUTIC_AREA_NAMES.cns,
  },
  {
    keywords: ['dermatology', 'skin'],
    name: THERAPEUTIC_AREA_NAMES.dermatology,
  },
  {
    keywords: [
      'diabetes',
      'diabetes mellitus',
      'wolfram syndrome',
      'donohue syndrome',
      'prediabetic state',
      'diabetic',
      'fetal macrosomia',
    ],
    name: THERAPEUTIC_AREA_NAMES.diabetes,
  },
  {
    keywords: [
      'endocrinology',
      'metabolism',
      'endocrine',
      'endocrinology',
      'metabolic',
      'neuroendocrinology',
    ],
    name: THERAPEUTIC_AREA_NAMES.endocrinology,
  },
  {
    keywords: [
      'gastroenterology',
      'hepatology',
      'gastrointestinal',
      'cholera infantum',
      'barrett esophagus',
      'deglutition disorders',
      'esophageal',
      'gastroenteritis',
      'appendicitis',
      'cholera morbus',
      'colitis',
      'diverticular diseases',
      'dysentery',
      'enteritis',
      'enterocolitis',
      'esophagitis',
      'gastritis',
      'inflammatory bowel diseases',
      'mucositis',
      'proctitis',
      'stomach neoplasms',
      'zollinger-ellison syndrome',
      'intestinal diseases',
      'rumination syndrome',
      'stomach diseases',
      'tuberculosis, gastrointestinal',
      'visceral prolapse',
    ],
    name: THERAPEUTIC_AREA_NAMES.gastroenterology,
  },
  {
    keywords: [
      'blood',
      'blood-forming',
      'transfusion',
      'hematologic',
      'anemia',
      'coagulation',
      'bone marrow diseases',
      'hemoglobinopathies',
      'hemorrhagic disorders',
      'leukocyte disorders',
      'methemoglobinemia',
      'pancytopenia',
      'polycythemia',
      'preleukemia',
      'sulfhemoglobinemia',
      'thrombophilia',
      'transfusion reaction',
      'hematology',
    ],
    name: THERAPEUTIC_AREA_NAMES.haematology,
  },
  {
    keywords: ['immunology', 'allergy'],
    name: THERAPEUTIC_AREA_NAMES.immunology,
  },
  {
    keywords: ['communicable disease', 'infectious disease'],
    name: THERAPEUTIC_AREA_NAMES.infectiousDiseases,
  },
  {
    keywords: ['inflammation', 'inflammatory'],
    name: THERAPEUTIC_AREA_NAMES.inflammation,
  },
  {
    keywords: ['internal disease', 'internal medicine'],
    name: THERAPEUTIC_AREA_NAMES.internalDiseases,
  },
  {
    keywords: [
      'metabolic diseases',
      'metabolic',
      'thesaurismosis',
      'thesaurismoses',
    ],
    name: THERAPEUTIC_AREA_NAMES.metabolicDiseases,
  },
  {
    keywords: [
      'kidney',
      'nephrology',
      'anuria',
      'renal',
      'perinephritis',
      'nephrosis',
      'nephritis',
      'pyelitis',
      'nephrocalcinosis',
      'nephrolithiasis',
      'nephrosclerosis',
      'diabetic nephropathies',
      'hepatorenal syndrome',
      'hydronephrosis',
      'pyonephrosis',
      'hyperoxaluria',
      'renovascular',
      'kidney neoplasms',
      'carcinoma, renal cell',
      'nephroma, mesoblastic',
      'wilms tumor',
    ],
    name: THERAPEUTIC_AREA_NAMES.nephrology,
  },
  {
    keywords: ['neurology', 'neuropathology', 'neurotology', 'nervous system'],
    name: THERAPEUTIC_AREA_NAMES.neurology,
  },
  {
    keywords: [
      'neoplasm',
      'carcinoma',
      'cancer',
      'sarcoma',
      'multiple myeloma',
      'tumor',
    ],
    name: THERAPEUTIC_AREA_NAMES.oncology,
  },
  {
    keywords: [
      'eye',
      'eye disease',
      'ocular',
      'orthoptics',
      'ophthalmogical',
      'vision',
      'asthenopia',
      'cogan syndrome',
      'conjunctival diseases',
    ],
    name: THERAPEUTIC_AREA_NAMES.ophthalmology,
  },
  {
    keywords: ['osteology', 'orthopaedic', 'orthopedic'],
    name: THERAPEUTIC_AREA_NAMES.orthopaedics,
  },
  {
    keywords: ['osteoporosis', 'osteoporoses', 'bone loss'],
    name: THERAPEUTIC_AREA_NAMES.osteoporosis,
  },
  {
    keywords: [
      'psychiatrist',
      'psychiatry',
      'psychological illness',
      'psychology',
    ],
    name: THERAPEUTIC_AREA_NAMES.psychiatry,
  },
  {
    keywords: [
      'respiratory',
      'pulmonary',
      'pulmonology',
      'pneumology',
      'pneumonology',
    ],
    name: THERAPEUTIC_AREA_NAMES.pulmonology,
  },
  {
    keywords: ['respiratory', 'respiratory system', 'respiratory tract'],
    name: THERAPEUTIC_AREA_NAMES.respiratory,
  },
  {
    keywords: [
      'rheumatic',
      'rheumatism',
      'rheumatoid',
      'arthritis',
      'caplan syndrome',
      'felty syndrome',
      "sjogren's syndrome",
      "still's disease",
      'hyperostosis, sternocostoclavicular',
      'polymyalgia rheumatica',
    ],
    name: THERAPEUTIC_AREA_NAMES.rheumatology,
  },
  {
    keywords: [
      'surgery',
      'operative therapy',
      'invasive procedures',
      'operative procedures',
      'operations',
      'perioperative',
      'intraoperative',
      'peroperative',
      'preoperative',
    ],
    name: THERAPEUTIC_AREA_NAMES.surgery,
  },
  {
    keywords: [
      'thrombosis',
      'thromboses',
      'thrombus',
      'blood clot',
      'blood coagulation',
      'thrombectomy',
      'coronary thrombosis',
      'livedoid vasculopathy',
      'thromboinflammation',
      'venous thrombosis',
      'budd-chiari syndrome',
      'postthrombotic syndrome',
      'retinal vein occlusion',
      'thrombophlebitis',
      'upper extremity deep vein thrombosis',
    ],
    name: THERAPEUTIC_AREA_NAMES.thrombosis,
  },
  {
    keywords: [
      'urologic',
      'andrology',
      'urinary tract',
      'ureteral',
      'ureterocele',
      'ureterolithiasis',
      'urethral',
      'epispadias',
      'urethritis',
      'urinary bladder',
      'anuria',
      'enuresis',
      'glycosuria',
      'hematuria',
      'oliguria',
      'polyuria',
      'proteinuria',
      'urinary incontinence',
      'urinary retention',
    ],
    name: THERAPEUTIC_AREA_NAMES.urology,
  },
  {
    keywords: ['vaccines', 'immunotherapy'],
    name: THERAPEUTIC_AREA_NAMES.vaccines,
  },
]

export const STAGES_OF_DISEASE = [
  'Metastatic',
  'Locally Advanced',
  'Class I',
  'Class II',
  'Class III',
  'Class IV',
  'Class V',
  'Class VI',
  'Grade I',
  'Grade II',
  'Grade III',
  'Grade IV',
  'Stage I',
  'Stage II',
  'Stage III',
  'Stage IV',
  'Low Risk',
  'Standard Risk',
  'High Risk',
]
