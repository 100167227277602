import { searchParamFromUrl } from '@components/hooks/ParamsProvider/searchParams'
import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import { distanceOptions } from '@components/primitives/DistancePicker'
import { Icon } from '@components/primitives/Icon'
import ModalOverlay from '@components/primitives/Modal/ModalOverlay'
import { classMerge } from '@components/utilities/classMerge'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { MILES_TO_METERS } from '@lib/utilities/calculateDistance'
import pickBy from 'lodash/pickBy'
import { useSearchParams } from 'next/navigation'
import { useEffect, useMemo, useState, type ChangeEvent } from 'react'
import { Configure } from 'react-instantsearch'

interface DistanceConfigurationProps {
  conditionPlaceholder?: string
  interventionPlaceholder?: string
  locationPlaceholder?: string
  placeholderDistanceText?: string
}

export const MAX_DISTANCE_IN_MILES = 90
export const MIN_DISTANCE_IN_MILES = 10
export const DEFAULT_DISTANCE_IN_MILES = MAX_DISTANCE_IN_MILES // default to "any"

const distanceDisplayLabel = (distance: number) =>
  distance < MAX_DISTANCE_IN_MILES ? `${distance} Miles` : 'Any'

/**
 * Construct a <Configure /> Algolia element to provide a radius for which to expand a search.
 * The radius really only matters when there's a lat / lng provided. See `<LocationConfiguration />`
 * for the lat / lng provider.
 *
 * @see https://www.algolia.com/doc/api-reference/widgets/configure/react/
 */
export default function DistanceConfiguration({
  conditionPlaceholder,
  interventionPlaceholder,
  locationPlaceholder,
  placeholderDistanceText,
}: DistanceConfigurationProps) {
  const initialDistance = searchParamFromUrl('distance')
  const [distanceMenuVisible, setDistanceMenuVisible] = useState(false)
  const [distanceInInput, setDistanceInInput] = useState(
    initialDistance ? parseInt(initialDistance, 10) : DEFAULT_DISTANCE_IN_MILES,
  )
  const searchParams = useSearchParams()
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()

  const onClick = (value: number) => {
    setDistanceInInput(value)
    triggerStateChange(value)
  }

  const triggerStateChange = (value: number) => {
    updateUrlToReflectSearchState(
      pickBy({
        condition: conditionPlaceholder,
        currentLocation: locationPlaceholder,
        distance: value,
        query: interventionPlaceholder,
      }),
    )
  }

  const visibilityHandler = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    distanceMenuVisible ? undefined : setDistanceMenuVisible(true)
  }

  const algoliaDistanceFilterProps = useMemo(
    () => urlDistanceToAlgoliaParam(distanceInInput),
    [distanceInInput],
  )

  useEffect(() => {
    const distanceFromUrl = searchParamFromUrl('distance')
    setDistanceInInput(
      distanceFromUrl
        ? parseInt(distanceFromUrl, 10)
        : DEFAULT_DISTANCE_IN_MILES,
    )
  }, [searchParams])

  return (
    <>
      <label
        className='pb-2 text-sm font-normal leading-[140%]'
        htmlFor={'search-bar-distance-input'}
      >
        Distance
      </label>
      <Configure {...algoliaDistanceFilterProps} />
      <div className='flex items-center'>
        <input
          autoComplete='off'
          className='w-full text-lg font-semibold leading-[180%] placeholder:text-black focus:outline-none'
          id='search-bar-distance-input'
          onChange={visibilityHandler}
          onFocus={visibilityHandler}
          placeholder={placeholderDistanceText ?? '50 Miles'}
          type='text'
          value={distanceDisplayLabel(distanceInInput)}
        />
        <div
          className='cursor-pointer'
          onClick={() => {
            setDistanceMenuVisible(!distanceMenuVisible)
          }}
        >
          <Icon icon={faChevronDown} />
        </div>
      </div>
      {/* List of items to refine given the input search box above */}
      <div
        className={`relative z-modalSearchPanel mx-auto my-2 ml-[-18px] w-[190px] rounded-2xl border-2 border-neutral200 bg-white py-2`}
        hidden={!distanceMenuVisible}
      >
        <div className='mx-auto mt-3 flex w-full flex-col gap-2 px-2'>
          {distanceOptions.map((distance) => {
            const isSelected = parseInt(distance.value, 10) === distanceInInput
            return (
              <div
                className={classMerge(
                  `my-0 flex cursor-pointer flex-row items-center justify-between rounded-2xl px-4 py-2 text-lg hover:bg-neutral200`,
                  isSelected ? 'bg-primary100' : '',
                )}
                key={distance.value}
                onClick={() => {
                  onClick(parseInt(distance.value, 10))
                  setDistanceMenuVisible(false)
                }}
              >
                {distance.label}
                {isSelected && (
                  <Icon className='text-primary400' icon={faCheckCircle} />
                )}
              </div>
            )
          })}
        </div>
      </div>
      <ModalOverlay
        hidden={!distanceMenuVisible}
        onClick={() => setDistanceMenuVisible(false)}
      />
    </>
  )
}

const urlDistanceToAlgoliaParam = (
  distanceValue: number,
): { aroundPrecision: number; aroundRadius: 'all' | number } => {
  // Use 'all' for no boundary on max distance
  const aroundRadius =
    distanceValue >= MAX_DISTANCE_IN_MILES
      ? 'all'
      : Math.round(
          Math.max(distanceValue, MIN_DISTANCE_IN_MILES) * MILES_TO_METERS,
        )

  return {
    aroundPrecision: Math.round(1 * MILES_TO_METERS), // Group items within a mile together
    aroundRadius,
  }
}
