import { convertFromDescriptiveToClassic } from '@lib/utilities/diseaseStage/convertFromDescriptiveToClassic'
import type { DiseaseStageDescriptive } from '@lib/utilities/diseaseStage/types'
import type { Prisma } from '@prisma/client' // eslint-disable-line @typescript-eslint/no-restricted-imports
import { literal, object, string } from 'zod'
import { PowerOntologyClass } from '../parseEligibilityCriteria/types'

// Example: {"type": "classic", "value": "III", "status": "include"}
const DiseaseStageRawDataValidator = object({
  status: literal('inclusion').or(literal('exclusion')),
  type: literal(PowerOntologyClass.Classic).or(
    literal(PowerOntologyClass.Descriptive),
  ),
  value: string(),
})

export function parseDiseaseStageFromRawData({
  rawData,
}: {
  rawData: Prisma.JsonValue
}) {
  const parsedValue = DiseaseStageRawDataValidator.safeParse(rawData)
  if (!parsedValue.success) {
    return []
  }
  const classicMatch = extractClassicMatch({ term: parsedValue.data.value })
  if (classicMatch.length > 0) {
    return Array.from(new Set(classicMatch))
  }
  const descriptiveMatch = extractDescriptiveMatch({
    term: parsedValue.data.value,
  })
  if (descriptiveMatch.length > 0) {
    return Array.from(new Set(descriptiveMatch))
  }

  // if we can't map to a known disease stage then we don't want to show in the concept
  // as this would propogate to Algolia and Apply flow/MTA
  return []
}

export function extractClassicMatch({ term }: { term: string }): string[] {
  // Strips letters a-d first and then finds stage regex match
  const letterReplacement = /[a-dA-D]/g
  const stageRegex = /\b(?:I{1,3}|IV)\b/i
  const classicMatch = term.replace(letterReplacement, '').match(stageRegex)

  if (!classicMatch) {
    return []
  }
  return [`Stage ${classicMatch[0]}`]
}

export function extractDescriptiveMatch({ term }: { term: string }) {
  const descriptiveMatch = convertFromDescriptiveToClassic([
    term as DiseaseStageDescriptive,
  ])
  if (!descriptiveMatch) {
    return []
  }
  return descriptiveMatch
}
