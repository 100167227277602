import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'

interface GradientInterstitialProps {
  backgroundGradient?: string
  backgroundGradientGrainy?: string
  children: React.ReactNode
  heading: string
  subheading: string
}

export function GradientInterstitial({
  backgroundGradient = 'bg-organicDawn',
  backgroundGradientGrainy = 'bg-organicDawn-grainy',
  children,
  grainy = false,
}: {
  backgroundGradient?: string
  backgroundGradientGrainy?: string
  children: React.ReactNode
  grainy?: boolean
}) {
  return (
    <div
      className={classMerge(
        'rounded-xl px-2 py-6 lg:p-16',
        grainy ? backgroundGradientGrainy : backgroundGradient,
      )}
    >
      {children}
    </div>
  )
}
/** Provide a CTA card **/
export function GradientInterstitialCta({
  backgroundGradient = 'bg-organicDawn',
  backgroundGradientGrainy = 'bg-organicDawn-grainy',
  children,
  heading,
  subheading,
}: GradientInterstitialProps) {
  return (
    <div className='pb-10'>
      <GradientInterstitial
        backgroundGradient={backgroundGradient}
        backgroundGradientGrainy={backgroundGradientGrainy}
        grainy
      >
        <div className='mx-auto grid grid-cols-1 gap-4 px-4 lg:px-0'>
          <Text
            className='text-center text-white'
            styleName='h3'
            value={heading}
          />
          <Text
            className='text-center text-white'
            styleName='p-strong'
            value={subheading}
          />
          <div className='flex flex-row items-center justify-center gap-4 text-center'>
            {children}
          </div>
        </div>
      </GradientInterstitial>
    </div>
  )
}
