import { searchParamFromUrl } from '@components/hooks/ParamsProvider/searchParams'
import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import { ANYWHERE_DISTANCE } from '@components/primitives/DistancePicker'
import ModalOverlay from '@components/primitives/Modal/ModalOverlay'
import Routes from '@lib/routes'
import type { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList'
import debounce from 'lodash/debounce'
import { useSearchParams, usePathname } from 'next/navigation'
import type { ChangeEvent, MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useRefinementList } from 'react-instantsearch'

type ConditionAutocompleteProps = {
  autoFocus?: boolean
  conditionPlaceholder?: string
  includeResultCount?: boolean
  locationPlaceholder?: string
  onInputChange: (value: string) => void
}

const DEFAULT_ITEM_CLASSNAMES =
  'mx-2 my-0 px-2 py-0 rounded-2xl flex flex-row items-center justify-between hover:cursor-pointer'

export const DropdownItem = ({
  includeResultCount,
  item,
  selectedItemHandler,
}: {
  includeResultCount: boolean
  item: RefinementListItem
  selectedItemHandler: (
    itemValue: string,
  ) => (event: MouseEvent<HTMLLIElement>) => void
}) => {
  return (
    <li
      className={`${
        item.isRefined
          ? 'bg-success50 hover:bg-neutral100'
          : 'bg-white hover:bg-neutral200'
      } ${DEFAULT_ITEM_CLASSNAMES}`}
      key={item.label}
      onClick={selectedItemHandler(item.value)}
    >
      <div className={'m-0 p-2'}>{item.label}</div>
      {includeResultCount && (
        <div
          className={`m-2 rounded-2xl bg-neutral100 p-2 hover:bg-neutral200`}
        >
          {item.count > 1000 ? '1000+' : item.count}
        </div>
      )}
    </li>
  )
}

/**
 * Condition autocomplete component connected to our global Algolia search index
 */
export const ConditionAutocomplete = ({
  autoFocus = false,
  conditionPlaceholder,
  includeResultCount = true,
  locationPlaceholder,
  onInputChange,
}: ConditionAutocompleteProps) => {
  const location =
    locationPlaceholder ?? searchParamFromUrl('currentLocation') ?? undefined
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const attribute = 'searchConditions' // The name of the algolia attribute we're filtering on
  const { items, searchForItems } = useRefinementList({
    attribute,
    limit: 5, // The number of items to display in the dropdown
  })

  const condition = searchParamFromUrl('condition')

  const placeholderText = condition
    ? condition
    : conditionPlaceholder ?? 'Type Condition'

  const [refinementListPickerVisible, setRefinementListPickerVisible] =
    useState(false)

  const [inputValue, setInputValue] = useState<string>(
    conditionPlaceholder ? conditionPlaceholder : '',
  )
  const [isValueSelected, setIsValueSelected] = useState(condition !== null)

  const { clearSearchState, updateUrlToReflectSearchState } =
    useSearchUrlParameters()

  useEffect(() => {
    const conditionFromUrl = searchParamFromUrl('condition')
    setInputValue(conditionFromUrl ? conditionFromUrl : '')
  }, [searchParams, pathname])

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (!refinementListPickerVisible) {
      setRefinementListPickerVisible(true)
    }

    setInputValue(event.currentTarget.value)
    onInputChange(event.currentTarget.value)

    if (event.currentTarget.value.length >= 1) {
      searchForItems(event.currentTarget.value)
    } else {
      setRefinementListPickerVisible(false)
    }
  }
  const debouncedInputHandler = debounce(inputChangeHandler, 250, {
    leading: true,
    trailing: false,
  })

  const selectedItemHandler =
    (selectedItem: string) => (event: MouseEvent<HTMLLIElement>) => {
      event.preventDefault()
      setInputValue(selectedItem)
      setIsValueSelected(true)
      const matchingItem = findMatchingItem(items, selectedItem)
      updateUrlToReflectSearchState(
        matchingItem
          ? {
              condition: selectedItem,
              conditions: undefined,
              currentLocation: location,
              distance: location ? ANYWHERE_DISTANCE : undefined,
            }
          : {
              currentLocation: location,
              distance: location ? ANYWHERE_DISTANCE : undefined,
              query: `"${selectedItem}"`,
            },
      )
      onClose()
    }

  const findMatchingItem = (items: RefinementListItem[], query: string) => {
    const matchingItem = items.find(
      (item) => item.label.toLowerCase() === query.toLowerCase(),
    )

    return matchingItem
  }

  /**
   * When the user clicks on a list item in the condition dropdown
   * @param query - the text of the list item they clicked on
   */
  const userClicksOnCondition =
    (listItemText: string) => (event: MouseEvent<HTMLLIElement>) => {
      event.preventDefault()
      // Check if there are match in the list
      const matchingItem = findMatchingItem(items, listItemText)
      updateUrlToReflectSearchState(
        matchingItem
          ? {
              condition: listItemText,
              conditions: undefined,
              currentLocation: location,
              distance: location ? ANYWHERE_DISTANCE : undefined,
            }
          : {
              currentLocation: location,
              distance: location ? ANYWHERE_DISTANCE : undefined,
              query: listItemText,
            },
      )
      setIsValueSelected(true)
      onClose()
    }

  /**
   * When a user navigates away from an "unfinished" condition search, we want to pick the first option in the list!
   */
  const pickBestOption = () => {
    if (!isValueSelected && inputValue.length > 0) {
      const fuzzyMatchToSelect = items.find((item) =>
        item.label.includes(inputValue),
      )?.label

      if (fuzzyMatchToSelect) {
        setInputValue(fuzzyMatchToSelect)
        updateUrlToReflectSearchState({
          condition: fuzzyMatchToSelect,
          conditions: undefined,
          currentLocation: location,
          distance: location ? ANYWHERE_DISTANCE : undefined,
        })
      }
    }
  }

  const onClose = () => setRefinementListPickerVisible(false)

  // Need to focus manually as autoFocus={true} property on input not working.
  const conditionInput = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (autoFocus && conditionInput.current) {
      conditionInput.current.focus()
    }
  }, [autoFocus])

  return (
    <div className='static'>
      <input
        autoComplete='off'
        className='w-full text-ellipsis text-lg font-semibold leading-[180%] placeholder:text-neutral500 focus:outline-none'
        id='search-bar-condition-input'
        onBlur={pickBestOption}
        onChange={debouncedInputHandler}
        onClick={() => {
          if (pathname === Routes.search) {
            clearSearchState()
            setIsValueSelected(false)
          }
        }}
        placeholder={placeholderText}
        ref={conditionInput}
        type='text'
        value={inputValue.length > 0 ? inputValue : conditionPlaceholder ?? ''}
      />
      {/* List of items to refine given the input search box above */}
      <ul
        className={`absolute left-0 z-modalSearchPanel mx-auto my-4 w-full rounded-2xl border-2 border-neutral200 bg-white py-2 md:w-96`}
        hidden={!refinementListPickerVisible}
      >
        <>
          {/*  todo - hide the item if its already in the refinementsList so that we don't have duplicates!*/}
          <li
            className={`bg-white hover:bg-neutral200 ${DEFAULT_ITEM_CLASSNAMES}`}
            onClick={userClicksOnCondition(inputValue)}
          >
            <div className='m-0 p-2'>{inputValue}</div>
          </li>
          {items.map((item, index) => (
            <DropdownItem
              includeResultCount={includeResultCount}
              item={item}
              key={index}
              selectedItemHandler={selectedItemHandler}
            />
          ))}
        </>
      </ul>
      <ModalOverlay hidden={!refinementListPickerVisible} onClick={onClose} />
    </div>
  )
}
