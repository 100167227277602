/**
 * Returns an array of normalized age list facets to pass to Algolia for AgeList filtering.
 *
 * @param maybeNumber Patient age as stringified number
 */
export function getAgeFacetsForAgeInput(
  maybeNumber: string | number | undefined,
) {
  const age = parseInt((maybeNumber ?? '').toString(), 10)
  if (isNaN(age)) {
    return ['Child', 'Adult', 'Older Adult']
  }

  if (age < 18) {
    return ['Child']
  }

  if (age >= 18 && age < 50) {
    return ['Adult']
  }

  if (age >= 50 && age < 70) {
    return ['Adult', 'Older Adult']
  }

  if (age >= 70) {
    return ['Older Adult']
  }

  return ['Child', 'Adult', 'Older Adult']
}
