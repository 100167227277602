export function doesOntologyStatusMatchCriterionStatus({
  isInclusion,
  ontologyIdentifier,
}: {
  isInclusion: boolean
  ontologyIdentifier: string
}) {
  // Exit early if we are looking for inclusion and this is exclusion criterion or vice versa
  const statusHasExclusion = ontologyIdentifier.includes('exclu')

  if (
    (isInclusion && statusHasExclusion) ||
    (!isInclusion && !statusHasExclusion)
  ) {
    return false
  }

  return true
}
