import transformForAlgolia from '@modules/trials/etl/transformForAlgolia'
import transformSiteLocationForAlgolia from '@modules/trials/etl/transformForAlgolia/transformSiteLocationForAlgolia'
import type {
  TrialBySiteLocationInAlgolia,
  TrialInAlgolia,
} from '@modules/trials/types/TrialInAlgolia'
import { mockTrialInDatabaseForAlgolia } from './trialMocks'

/**
 * Mock a trial from Algolia. Keep this in a separate file to reduce import tree
 * dragging in `transformForAlgolia`
 */
export const mockTrialInAlgolia: TrialInAlgolia = transformForAlgolia({
  trial: mockTrialInDatabaseForAlgolia,
})

export const mockSiteLocationsInAlgolia = transformSiteLocationForAlgolia(
  mockTrialInDatabaseForAlgolia,
  mockTrialInDatabaseForAlgolia.trialLocations[0]!,
)

export const mockTrialBySiteLocationsInAlgolia: TrialBySiteLocationInAlgolia = {
  ...mockTrialInAlgolia,
  ...mockSiteLocationsInAlgolia,
}
