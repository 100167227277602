import { useIsTabletOrMobileScreen } from '@components/hooks/useIsMobileScreen'
import useIsPartnerPage from '@components/hooks/useIsPartnerPage'
import { AnimatedAccordion } from '@components/primitives/AnimatedAccordion'
import { Icon } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import { Tooltip } from '@components/primitives/Tooltip'
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo'
import { useState, type ReactNode } from 'react'

export const AccordionFilterGroupContainer = ({
  bgColorOverride,
  children,
  isRefinedCount = 0,
  title,
  tooltip,
}: {
  bgColorOverride?: string
  children: ReactNode
  isRefinedCount?: number
  title?: string
  tooltip?: ReactNode
}) => {
  const viewingPartnerPage = useIsPartnerPage()
  const isMobile = useIsTabletOrMobileScreen()
  const [accordionOpen, toggleAccordion] = useState<boolean>(!isMobile)

  const getBgColor = () => {
    if (viewingPartnerPage) {
      return 'bg-white'
    } else if (!accordionOpen) {
      return ''
    } else if (bgColorOverride) {
      return bgColorOverride
    } else {
      return 'bg-neutral200'
    }
  }

  return (
    <div className={`rounded-lg py-2 ${getBgColor()}`}>
      <div
        className='flex cursor-pointer items-center justify-between'
        onClick={() => toggleAccordion(!accordionOpen)}
      >
        {title && (
          <div className='flex pl-4'>
            <Text styleName='p-strong' value={title} />{' '}
            {tooltip && (
              <Tooltip
                description={tooltip}
                label={
                  <Icon
                    className='ml-2 text-base text-neutral600'
                    icon={faCircleInfo}
                  />
                }
              />
            )}
          </div>
        )}

        <div className='flex items-center'>
          {isRefinedCount > 0 && (
            <div className='mr-2 rounded-full bg-primary100 px-2.5 py-1 text-xs text-primary800'>
              {isRefinedCount}
            </div>
          )}
          <Icon
            className={`mr-4 cursor-pointer ${
              accordionOpen ? '' : 'rotate-180'
            } transform transition-all duration-500`}
            icon={faChevronUp}
          />
        </div>
      </div>

      <AnimatedAccordion isExpanded={accordionOpen}>
        {children}
      </AnimatedAccordion>
    </div>
  )
}
