import type { Geolocation } from '@lib/types/Geolocation'
import { MILES_TO_METERS } from '@lib/utilities/calculateDistance'

// Minimum length before Google autocomplete query fires
const QUERY_MIN_LENGTH = 3

interface UseGetPlacePredictionsProps {
  autocompleteService?: google.maps.places.AutocompleteService

  geolocation?: Geolocation
  sessionToken?: google.maps.places.AutocompleteSessionToken
}

/**
 * The generated function takes a query and a callback.
 * The callback is invoked with the results from the query. The query should be a string
 * describing a place ("Houston, TX"). The results will be a list of Google Places Autocomplete
 * Prediction objects.
 *
 * @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#place_autocomplete_service
 *
 * @param props.autocompleteService Google Places Autocomplete service
 * @param props.geolocation User's current geolocation to bias the prediction results
 * @param props.sessionToken Google Places Autocomplete session token: we won't be charged until a Google Place is Geocoded.
 */
const getPlacePredictionsFactory = ({
  autocompleteService,
  geolocation,
  sessionToken,
}: UseGetPlacePredictionsProps) => {
  /**
   * Given a location string (i.e. "Houst"), invoke a callback with a list of Google Place Autocomplete Predictions.
   */
  const getPlacePredictions = (
    query: string,
    callback: (
      suggestions: google.maps.places.AutocompletePrediction[],
    ) => void,
    resultTypes = ['geocode'],
  ) => {
    if (!autocompleteService) {
      return callback([])
    }
    if (query.length < QUERY_MIN_LENGTH) {
      return callback([])
    }

    const autocompleteRequest: google.maps.places.AutocompletionRequest = {
      componentRestrictions: { country: ['us', 'ca'] }, // restrict to US and Canadian addresses
      input: query,
      types: resultTypes, // Limit queryable entities: https://developers.google.com/maps/documentation/javascript/supported_types#table3
    }

    // Provide a geolocation bias based on the current users' lat / lng
    if (geolocation && geolocation.latitude && geolocation.longitude) {
      autocompleteRequest.location = new google.maps.LatLng({
        lat: geolocation.latitude,
        lng: geolocation.longitude,
      })
      autocompleteRequest.radius = 1000 * MILES_TO_METERS
    }

    // Attach this search to a session, so we are billed on Place Details lookup
    if (sessionToken) {
      autocompleteRequest.sessionToken = sessionToken
    }

    autocompleteService.getPlacePredictions(
      autocompleteRequest,
      (suggestions, _status) => {
        callback(suggestions ?? [])
      },
    )
  }

  return getPlacePredictions
}

export default getPlacePredictionsFactory
