import type { ArrayElement } from '@lib/types/arrayElement'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { EligibilityCriterionConcept, Prisma } from '@prisma/client'
import { literal, object, string } from 'zod'
import {
  PowerOntologyInclusiveSetAny,
  PowerOntologySource,
  PowerOntologyVocabulary,
} from '../../parseEligibilityCriteria/types'

/**
 * Retrieve a string array set of terms representing the inclusion set for line of therapies for
 * this trial. Should be of the form ["2", "3", "4+"] or ["any"]. If no terms are found, add "any" for
 * inclusion criteria
 */
export function getLineOfTherapyInclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const lineOfTherapies = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria(),
    new Set<string>(),
  )

  if (lineOfTherapies.size === 0 || lineOfTherapies.size === 4) {
    return [PowerOntologyInclusiveSetAny]
  }

  return Array.from(lineOfTherapies).sort()
}

/**
 * Retrieve a string array set of terms representing the exclusive set for line of therapies for
 * this trial. Should be of the form ["2", "3", "4+"]
 */
export function getLineOfTherapyExclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const lineOfTherapies = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria({ isInclusion: false }),
    new Set<string>(),
  )

  return Array.from(lineOfTherapies).sort()
}

function extractTermsFromEligibilityCriteria(
  { isInclusion }: { isInclusion: boolean } = { isInclusion: true },
) {
  return (
    terms: Set<string>,
    criterion: ArrayElement<
      TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
    >,
  ) => getTermsFromEligibilityCriteria(terms, criterion, isInclusion)
}

function getTermsFromEligibilityCriteria(
  terms: Set<string>,
  criterion: ArrayElement<
    TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
  >,
  isInclusion: boolean,
) {
  for (const component of criterion.criterionComponents) {
    if (hasValidLineOfTherapyData(component)) {
      const linesOfTherapy = parseLineOfTherapyFromRawData(
        component.rawData,
        isInclusion,
      )
      if (linesOfTherapy && linesOfTherapy.length > 0) {
        linesOfTherapy.map((term) => {
          if (typeof term === 'string') {
            terms.add(term)
          }
        })
      }
    }
  }

  return terms
}

function hasValidLineOfTherapyData({
  eligibilityCriterionConcept,
  rawData,
}: {
  eligibilityCriterionConcept: Pick<
    EligibilityCriterionConcept,
    | 'ontologyClass'
    | 'ontologyIdentifier'
    | 'ontologySource'
    | 'ontologyVocabulary'
  >
  rawData: Prisma.JsonValue
}) {
  return (
    hasValidRawData(rawData) &&
    eligibilityCriterionConcept.ontologySource === PowerOntologySource &&
    eligibilityCriterionConcept.ontologyVocabulary ===
      PowerOntologyVocabulary.Treatments
  )
}

// Example: {"status": "include", "priorTreatment": "Neoadjuvant Chemotherapy", "line_of_therapy": ["2", "3", "4+"]}
// We want a term for each of "2, 3, 4+", so that we have "2 include", "3 include", "4+ include"
const LineOfTherapyRawDataValidator = object({
  lineOfTherapy: string().array(),
  priorTreatment: string(),
  status: literal('include').or(literal('exclude')),
})

function hasValidRawData(rawData: Prisma.JsonValue) {
  return LineOfTherapyRawDataValidator.safeParse(rawData).success
}

function parseLineOfTherapyFromRawData(
  rawData: Prisma.JsonValue,
  useInclusion: boolean,
) {
  const parsedValue = LineOfTherapyRawDataValidator.safeParse(rawData)
  if (!parsedValue.success) {
    return []
  }

  // Exit early if we are looking for inclusion and this is exclusion criterion or vice versa
  const statusHasExclusion = parsedValue.data.status.includes('exclu')
  if (
    (useInclusion && statusHasExclusion) ||
    (!useInclusion && !statusHasExclusion)
  ) {
    return []
  }

  // Terms should be one of  ["1", "2", "3", "4+"]
  const rawTerms = parsedValue.data.lineOfTherapy.sort()
  const validTerms = ['1', '2', '3', '4+']
  const correctedTerms = new Set<string>()
  for (const rawTerm of rawTerms) {
    if (validTerms.includes(rawTerm)) {
      correctedTerms.add(rawTerm)
    } else if (/^1/.test(rawTerm)) {
      correctedTerms.add('1')
    } else if (['4', '5', '6'].includes(rawTerm)) {
      correctedTerms.add('4+')
    }
  }

  return convertLineOfTherapyNumberToPriorTreatment(
    Array.from(correctedTerms).sort(),
  )
}

const LineOfTherapyToPriorTreatmentMap = [
  { lineOfTherapy: '1', priorTreatment: '0 Prior Treatments' },
  { lineOfTherapy: '2', priorTreatment: '1 Prior Treatment' },
  { lineOfTherapy: '3', priorTreatment: '2 Prior Treatments' },
  { lineOfTherapy: '4+', priorTreatment: '3+ Prior Treatments' },
]

function convertLineOfTherapyNumberToPriorTreatment(linesOfTherapy: string[]) {
  return linesOfTherapy
    .flatMap((lineOfTherapy) =>
      LineOfTherapyToPriorTreatmentMap.find(
        (map) => map.lineOfTherapy === lineOfTherapy,
      ),
    )
    .flatMap((map) => map?.priorTreatment)
    .filter((v) => typeof v === 'string')
}
