import { useEffect } from 'react'

type BeforeUnloadCallback = () => unknown

// Code based on https://github.com/jacobbuck/react-beforeunload/issues/13 with minor modifications
export default function useBeforeUnload(callback: BeforeUnloadCallback) {
  useEffect(() => {
    function handleBeforeUnload(_e: BeforeUnloadEvent) {
      callback()
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [callback])
}
