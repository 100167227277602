import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'

/**
 * Safety Score, lowest first if multiple phases present
 *
 * - Phase 3/4: 3
 * - Phase 2: 2
 * - Phase 1: 1
 * - Unphased: 1
 *
 * @param trial Trial in database with phase list
 */ export default function getSafetyScoreForTrial(
  trial: Pick<TrialAssociation, 'phaseList'>,
) {
  if (
    trial.phaseList.includes('Phase 1') ||
    trial.phaseList.includes('Not Applicable') ||
    trial.phaseList.includes('N/A')
  ) {
    return 1
  }

  if (trial.phaseList.includes('Phase 2')) {
    return 2
  }

  if (
    trial.phaseList.includes('Phase 3') ||
    trial.phaseList.includes('Phase 4')
  ) {
    return 3
  }

  return 1
}

export function hasOneFdaApprovedIntervention(
  interventions: TrialAssociation['interventions'],
) {
  return interventions.some((intervention) =>
    intervention.drugsJoin.some((drugsJoin) => drugsJoin.drug.approved),
  )
}
