export const ETL_TAG = 'ETL'
export const SPONSOR_MONTHLY_BRIEF_CSV_UPLOAD_TAG =
  'SponsorMonthlyBriefCsvUpload'

export enum ContactTag {
  ETL = ETL_TAG,
  SPONSOR_MONTHLY_BRIEF_CSV_UPLOAD = SPONSOR_MONTHLY_BRIEF_CSV_UPLOAD_TAG,
}

export enum ContactStatus {
  PARTIALLY_VERIFIED = 'PartiallyVerified',
  // Lauren has been manually marking contacts as "preferred" when we get an indication that they are the right person to talk to for a patient referral
  // We automatically mark a site contact as preferred if they send an email relay message
  PREFERRED = 'Preferred',
  SPONSOR_MONTHLY_BRIEF_CSV_UPLOAD = SPONSOR_MONTHLY_BRIEF_CSV_UPLOAD_TAG, // contacts uploaded via CSV for  sponsor's monthly brief email
  UNVERIFIED = 'Unverified',
  VERIFIED = 'Verified', // Lauren manually marks contacts as "verified" when they reply to a campaign we sent them
}
