import type { ArrayElement } from '@lib/types/arrayElement'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { EligibilityCriterionConcept, Prisma } from '@prisma/client'
import {
  PowerOntologyClass,
  PowerOntologySource,
  PowerOntologyVocabulary,
} from '../../parseEligibilityCriteria/types'
import { parseDiseaseStageFromRawData } from '../getDiseaseStageFromCriterion'
import { doesOntologyStatusMatchCriterionStatus } from './doesOntologyStatusMatchCriterionStatus'

/**
 * Extracts disease stage for inclusion and exclusion from Concept terms
 * Concept terms mapped and parsed in modules/trials/helpers/extractEntitiesFromEligibilityCriteria/getDiseaseStageFromCriterion.ts
 * We assume that mapping in concept is correct and don't further parse here other than if no inclusion is listed we default to "any"
 * @param {ArrayElement<TrialInDatabaseForAlgolia['parsedEligibilityCriteria']>} criterion - Trial criterion
 * @param {boolean} isInclusion - Label of inclusion or exclusion on the criterion
 * @param {Set<string>} terms - Set to upsert to Algolia
 * @returns {terms} Disease stage unique terms from concept data
 */
export function getDiseaseStageTermsFromEligibilityCriteria({
  criterion,
  isInclusion,
  terms,
}: {
  criterion: ArrayElement<
    TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
  >
  isInclusion: boolean
  terms: Set<string>
}): Set<string> {
  for (const component of criterion.criterionComponents) {
    if (!hasValidDiseaseStagingData(component)) {
      continue
    }

    if (!component.eligibilityCriterionConcept.ontologyIdentifier) {
      continue
    }

    const ontologyStatusMatchCriterionStatus =
      doesOntologyStatusMatchCriterionStatus({
        isInclusion,
        ontologyIdentifier:
          component.eligibilityCriterionConcept.ontologyIdentifier,
      })
    if (!ontologyStatusMatchCriterionStatus) {
      continue
    }

    const parsedValue = parseDiseaseStageFromRawData({
      rawData: component.rawData,
    })

    if (parsedValue.length > 0) {
      for (const value of parsedValue) {
        terms.add(value)
      }
    }
  }
  // if no stage of disease for inclusion we return any such that Algolia filters show all trials
  // we don't want to assume any for exclusion so we return nothing
  if (terms.size === 0 && isInclusion) {
    return terms.add('any')
  }
  return terms
}

// Lots of noise in the table, keep just to Power ontology and isolated to class "classic"
function hasValidDiseaseStagingData({
  eligibilityCriterionConcept,
}: {
  eligibilityCriterionConcept: Pick<
    EligibilityCriterionConcept,
    | 'name'
    | 'ontologyClass'
    | 'ontologyIdentifier'
    | 'ontologySource'
    | 'ontologyVocabulary'
  >
  rawData: Prisma.JsonValue
}) {
  return (
    eligibilityCriterionConcept.ontologyIdentifier &&
    (eligibilityCriterionConcept.ontologyClass === PowerOntologyClass.Classic ||
      eligibilityCriterionConcept.ontologyClass ===
        PowerOntologyClass.Descriptive) &&
    eligibilityCriterionConcept.ontologySource === PowerOntologySource &&
    eligibilityCriterionConcept.ontologyVocabulary ===
      PowerOntologyVocabulary.DiseaseStaging
  )
}
