import type { TrialHelpfulInformationForPatientEntries } from '@modules/trials/types/TrialHelpfulInformationForPatientEntries'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import { optimizePatientQuotes } from './mergeQuotes'

export default function getPatientQuotesFromTrial(
  trial: Pick<TrialInDatabaseForAlgolia, 'helpfulInformationForPatients'>,
) {
  const originalQuotes = trial.helpfulInformationForPatients.find(
    (info) => info.title === 'Why did patients apply to this trial?',
  )

  const entries = originalQuotes?.entries as
    | TrialHelpfulInformationForPatientEntries
    | undefined

  const quoteStrings = entries?.map((quote) => quote.value as string) ?? []
  return optimizePatientQuotes(quoteStrings)
}
