import type { Coordinates } from '@components/app/TrialSearch/LocationConfiguration'
import { addScript } from '@components/utilities/addScript'
import debounce from 'lodash/debounce'
import type { ReactNode } from 'react'
import { createContext, useCallback, useEffect, useState } from 'react'

const googlePlacesApiSrc = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_GEOCODE_API_KEY}&libraries=places`
const googlePlacesApiScriptId = 'google-places-api-script'

export type GoogleMapsServices = {
  autocompleteService?: google.maps.places.AutocompleteService
  geocoderService?: google.maps.Geocoder
  placesService?: google.maps.places.PlacesService
  sessionToken?: google.maps.places.AutocompleteSessionToken
}

/**
 * Context to hold Google Places Autocomplete Services.
 */
export const GoogleMapsContext = createContext<{
  getLatLngFromPlaceName: (placeName: string) => Promise<Coordinates>
  googleMapsServices: GoogleMapsServices
}>({
  getLatLngFromPlaceName: () => Promise.resolve({}),
  googleMapsServices: {},
})

interface GoogleMapsServicesProviderProps {
  children?: ReactNode
  deferScript?: boolean
}

/**
 * Context provider to make available services related to Google Places Autocomplete functionality. This includes
 * the script loader for the Google Maps libraries for the Places API. It renders children and then inserts a provider
 * once the script loads to ensure that the Google Maps libraries are available to the children components as soon
 * as possible. The Google Places API script is loaded by an event listener based on mouse move or keyboard press.
 *
 * We use a Context to hold the Google Maps Services so that we can keep the same session keys across service usages.
 *
 * @see https://developers.google.com/maps/documentation/javascript/places-autocomplete
 *
 * @param props.autocompleteService Google Maps Places Autocomplete Service instantiation, used for finding Google Places given a query
 * @param props.geocoderService Google Maps Geocoder Service instantiation, used for finding the lat / lng for a given Place ID
 * @param props.sessionToken Google Maps Places API session token, tied to our API key
 */
export const GoogleMapsServicesProvider = ({
  children,
  deferScript = false,
}: GoogleMapsServicesProviderProps) => {
  const [googleMapsServices, setGoogleMapsServices] =
    useState<GoogleMapsServices>()

  // Initialize all Google Maps related services - see <GooglePlacesScript /> for actual script loads
  const initializeGoogleMapsServices = useCallback(() => {
    // already spawned, so exit early
    if (googleMapsServices) {
      return
    }

    const spawnedServices = spawnGoogleMapsServices()
    if (spawnedServices) {
      setGoogleMapsServices(spawnedServices)
    }
  }, [googleMapsServices])

  const addGooglePlacesApiScriptTag = () => {
    if (!window.google?.maps) {
      addScript({
        id: googlePlacesApiScriptId,
        onLoad: () => {
          initializeGoogleMapsServices()
          removeEventListenersToLoadScript()
        },
        src: googlePlacesApiSrc,
      })
    } else {
      initializeGoogleMapsServices()
    }
  }

  const debouncedAddGooglePlacesApiScript = debounce(
    addGooglePlacesApiScriptTag,
    250,
    {
      leading: true,
      trailing: false,
    },
  )

  // We don't want to use `load` here, as Lighthouse detects this as page render dependent library
  // It slows down our PageSpeed scores dramatically, as we block on loading the GoogleMaps APIs
  // See https://github.com/with-power/www-withpower-com/pull/2827 and
  // https://linear.app/withpower/issue/POW-3761 for more info
  const addEventListenersToLoadScript = () => {
    window.addEventListener('mousemove', debouncedAddGooglePlacesApiScript, {
      once: true,
    })
    window.addEventListener('keydown', debouncedAddGooglePlacesApiScript, {
      once: true,
    })
  }

  const removeEventListenersToLoadScript = () => {
    window.removeEventListener('keydown', debouncedAddGooglePlacesApiScript)
    window.removeEventListener('mousemove', debouncedAddGooglePlacesApiScript)
  }

  /**
   * Get the lat/lng of the location passed in as a string
   * @param locationName A string with an approximate location name - like "Denver CO", "New York", "Toronto, Canada".
   * @returns Lat/Lng coordinates for of Google Map's best guess for the location.
   */
  const getLatLngFromPlaceName = async (
    locationName: string,
  ): Promise<Coordinates> => {
    const { autocompleteService, geocoderService } = googleMapsServices ?? {}

    if (!geocoderService || !autocompleteService) {
      return {}
    }

    const autocompleteResponse = await autocompleteService.getPlacePredictions({
      componentRestrictions: { country: ['us', 'ca'] }, // restrict to US and Canadian addresses
      input: locationName,
      types: ['geocode'],
    })
    const firstResult = autocompleteResponse.predictions[0]

    if (!firstResult) {
      return {}
    }

    const geocodeResponse = await geocoderService.geocode({
      placeId: firstResult.place_id,
    })

    const result = geocodeResponse.results[0]?.geometry?.location

    return { lat: result?.lat(), lng: result?.lng() }
  }

  useEffect(() => {
    if (!deferScript) {
      debouncedAddGooglePlacesApiScript()
    }

    addEventListenersToLoadScript()

    return () => {
      removeEventListenersToLoadScript()
    }
  }, [])

  return (
    <>
      {googleMapsServices ? (
        <GoogleMapsContext.Provider
          value={{ getLatLngFromPlaceName, googleMapsServices }}
        >
          {children}
        </GoogleMapsContext.Provider>
      ) : (
        <>
          {/* Keep this render here for JS-less crawlers to see a DOM */}
          {children}
        </>
      )}
    </>
  )
}

function errorLogger(...args: any[]) {
  // eslint-disable-next-line no-console
  console.error(...args)
}

export const spawnGoogleMapsServices = () => {
  if (!window.google) {
    errorLogger('[GoogleMapsServicesProvider]: Google namespace is not loaded')
    return
  }
  if (!window.google.maps) {
    errorLogger(
      '[GoogleMapsServicesProvider]: Google maps namespace is not loaded',
    )
    return
  }
  if (!window.google.maps.places) {
    errorLogger(
      '[GoogleMapsServicesProvider]: Google maps places namespace is not loaded',
    )
    return
  }

  return {
    autocompleteService: new google.maps.places.AutocompleteService(),
    geocoderService: new google.maps.Geocoder(),
    // HACK: this service needs a div to display the map
    placesService: new google.maps.places.PlacesService(
      document.createElement('div'),
    ),
    sessionToken: new google.maps.places.AutocompleteSessionToken(),
  }
}
