import type { ArrayElement } from '@lib/types/arrayElement'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { EligibilityCriterionConcept } from '@prisma/client'
import {
  PowerOntologySource,
  PowerOntologyVocabulary,
} from '../../parseEligibilityCriteria/types'
import { disallowTermsFilter } from './disallowTermsFilter'
import { doesOntologyStatusMatchCriterionStatus } from './doesOntologyStatusMatchCriterionStatus'

export function getGeneticMarkerTermsFromEligibilityCriteria({
  criterion,
  isInclusion,
  terms,
}: {
  criterion: ArrayElement<
    TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
  >
  isInclusion: boolean
  terms: Set<string>
}): Set<string> {
  for (const component of criterion.criterionComponents) {
    if (!hasValidReceptorData(component.eligibilityCriterionConcept)) {
      continue
    }

    const name = component.eligibilityCriterionConcept.name // like "HER2 positive"

    if (
      !name ||
      !hasParseableStatus(name) ||
      !component.eligibilityCriterionConcept.ontologyIdentifier
    ) {
      continue
    }

    const ontologyStatusMatchCriterionStatus =
      doesOntologyStatusMatchCriterionStatus({
        isInclusion,
        ontologyIdentifier:
          component.eligibilityCriterionConcept.ontologyIdentifier,
      })
    if (!ontologyStatusMatchCriterionStatus) {
      continue
    }

    // For "any" hits, add both "positive" and "negative"
    if (hasAnyInIdentifier(name)) {
      // should be "ER any"
      const base = getBaseIdentifierWithoutAny(name) // should be "ER"
      terms.add(`${base} positive`) // add "ER positive"
      terms.add(`${base} negative`) // add "ER negative"
    } else {
      terms.add(name) // should be "ER positive" or "ER negative"
    }
  }

  return terms
}

// Lots of noise in the table, keep just to Power ontology and isolated to class "receptor"
function hasValidReceptorData(
  concept: Pick<
    EligibilityCriterionConcept,
    | 'name' // like "HER2 positive"
    | 'ontologyClass'
    | 'ontologyIdentifier'
    | 'ontologySource'
    | 'ontologyVocabulary'
  >,
) {
  const hasValidOntologyClass = ['gene', 'ligand', 'receptor'].includes(
    concept.ontologyClass?.toLowerCase() ?? '',
  )
  return (
    hasValidOntologyClass &&
    !disallowTermsFilter(concept.name ?? '') &&
    concept.name &&
    concept.ontologyIdentifier &&
    concept.ontologySource === PowerOntologySource &&
    concept.ontologyVocabulary === PowerOntologyVocabulary.GeneticReceptors
  )
}

function hasAnyInIdentifier(identifier: string) {
  return identifier.includes(' any')
}

function getBaseIdentifierWithoutAny(identifier: string) {
  return identifier.split(' any')[0]!
}

function hasParseableStatus(identifier: string) {
  return ['positive', 'negative', 'any'].some((status) =>
    identifier.includes(status),
  )
}
