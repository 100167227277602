import { searchParamFromUrl } from '@components/hooks/ParamsProvider/searchParams'
import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import {
  ANYWHERE_DISTANCE,
  DistancePicker,
  type DistanceValue,
} from '@components/primitives/DistancePicker'
import { useState } from 'react'

export const TrialSearchDistancePicker = () => {
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()
  const [distance, setDistance] = useState(
    (searchParamFromUrl('distance') as DistanceValue) ?? ANYWHERE_DISTANCE,
  )

  const onChange = (value: DistanceValue) => {
    setDistance(value)
    updateUrlToReflectSearchState({
      distance: value,
    })
  }

  return <DistancePicker distance={distance} setDistance={onChange} />
}
