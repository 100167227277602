import ConditionName from '@components/app/ConditionName'
import Button from '@components/primitives/Button'
import { GradientInterstitial } from '@components/primitives/Gradient/GradientInterstitial'
import { EmailInput } from '@components/primitives/Input/EmailInput'
import { Text } from '@components/primitives/Text'
import subscribeUserToTrialNotifications from '@lib/api/patients/trial-notifications/subscribeUserToTrialNotifications'
import { TrackingSource } from '@lib/tracking'
import type { TrialInAlgolia } from '@modules/trials/types/TrialInAlgolia'
import { useState } from 'react'

type GetNotifiedAboutTrialsCardProps = {
  hasSubmittedEmail: boolean
  onSubmit: () => void
  trial: TrialInAlgolia
}
export const GetNotifiedAboutTrialsCard = ({
  hasSubmittedEmail,
  onSubmit,
  trial,
}: GetNotifiedAboutTrialsCardProps) => {
  const [email, setEmail] = useState('')

  const registerUserForTrialRecommendations = async () => {
    if (!email) {
      return
    }

    await subscribeUserToTrialNotifications({
      email,
      nctId: trial.nctId,
      source: TrackingSource.GET_NOTIFIED_ABOUT_TRIALS_CARD,
    })

    onSubmit()
  }

  const conditionName = ConditionName(trial.primaryCondition)

  return (
    <GradientInterstitial grainy>
      <div className='px-4'>
        <Text
          className='text-white'
          styleName='blockquote-small'
          value={`Get notified when new ${conditionName} trials are posted`}
        />
        <Text
          className='text-white'
          styleName='p'
          value={`We'll send you an email whenever new trials are posted`}
        />
        {hasSubmittedEmail ? (
          <Text
            className='text-white'
            value={`Thanks for submitting your email! We will be in touch 😊`}
          />
        ) : (
          <div className='mt-4 flex flex-wrap items-center'>
            <div className='mb-2 w-full lg:mb-0 lg:mr-20 lg:w-1/2'>
              <EmailInput
                placeholder='Enter your email'
                setValue={setEmail}
                value={email}
              />
            </div>
            <div className='w-full lg:w-1/3'>
              <Button
                className='w-full'
                disabled={!email}
                onClick={registerUserForTrialRecommendations}
                value='Turn On Alerts'
              />
            </div>
          </div>
        )}
      </div>
    </GradientInterstitial>
  )
}
