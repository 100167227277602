import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import { PaginationControlEllipsis } from '@components/primitives/PaginationControl/PaginationControlEllipsis'
import { PaginationControlPageLink } from '@components/primitives/PaginationControl/PaginationControlPageLink'
import { trackEventWithFacebook, TrackingEvent } from '@lib/tracking'

export const PageLinks = ({
  currentPage,
  totalPages,
}: {
  currentPage: number
  totalPages: number
}) => {
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()

  const pageMarkers = pagination(currentPage, totalPages)

  return (
    <>
      {pageMarkers.map((pageNumber) =>
        typeof pageNumber === 'string' ? (
          <PaginationControlEllipsis key={pageNumber} />
        ) : (
          <PaginationControlPageLink
            currentPage={currentPage}
            key={pageNumber}
            linkText={pageNumber?.toString()}
            onClick={() => {
              trackEventWithFacebook(TrackingEvent.SEARCH_PAGE_NUMBER_CLICKED, {
                type: 'navigate-to-page-number',
                val: pageNumber,
              })
              updateUrlToReflectSearchState({ page: pageNumber?.toString() })
            }}
            pageNumber={pageNumber}
          />
        ),
      )}
    </>
  )
}

type PaginationArray = Array<number | 'firstEllipsis' | 'secondEllipsis'>
// Lifted from https://gist.github.com/kottenator/9d936eb3e4e3c3e02598?permalink_comment_id=3413141#gistcomment-3413141
export const pagination = (current: number, total: number): PaginationArray => {
  const center = [current - 2, current - 1, current, current + 1, current + 2],
    filteredCenter: PaginationArray = center.filter((p) => p > 1 && p < total),
    includeThreeLeft = current === 5,
    includeThreeRight = current === total - 4,
    includeLeftDots = current > 5,
    includeRightDots = current < total - 4

  if (includeThreeLeft) filteredCenter.unshift(2)
  if (includeThreeRight) filteredCenter.push(total - 1)

  if (includeLeftDots) filteredCenter.unshift('firstEllipsis')
  if (includeRightDots) filteredCenter.push('secondEllipsis')

  return [1, ...filteredCenter, total]
}
