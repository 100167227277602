import type { SearchParam } from '@components/hooks/ParamsProvider/searchParams'
import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import { Icon } from '@components/primitives/Icon'
import Input from '@components/primitives/Input'
import ModalOverlay from '@components/primitives/Modal/ModalOverlay'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faMagicWandSparkles } from '@fortawesome/pro-regular-svg-icons/faMagicWandSparkles'
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import debounce from 'lodash/debounce'
import { useState, type ChangeEvent } from 'react'

export type QuickFilterItem = {
  attribute: SearchParam
  attributeDisplay: string
  isSelected: boolean
  value: string
}

/**
 * Super cool search bar that lets you search by facet values, or enter a free text search
 */
export const QuickSearchBox = ({ items }: { items: QuickFilterItem[] }) => {
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()

  const [menuVisible, setMenuVisible] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (!menuVisible) {
      setMenuVisible(true)
    }

    setInputValue(event.currentTarget.value)
  }

  const debouncedInputHandler = debounce(inputChangeHandler, 250, {
    leading: true,
    trailing: false,
  })

  const closeMenu = () => setMenuVisible(false)

  const filtersToShow = items.filter((item) =>
    item.value.toLowerCase().includes(inputValue.toLowerCase()),
  )

  return (
    <div className='relative'>
      <Input
        className='w-full rounded-lg border-2 border-neutral200 py-2 pl-10'
        icon={faSearch}
        noDefaultStyles
        onChange={debouncedInputHandler}
        onFocus={() => setMenuVisible(true)}
        placeholder='Filter Your Results'
        type='text'
        value={inputValue}
      />
      {/* List of items to refine given the input search box above */}
      <ul
        className={`absolute top-12 z-modalSearchPanel mx-auto max-h-60 w-full overflow-y-auto rounded-lg border-2 border-neutral200 bg-white`}
        hidden={!menuVisible}
      >
        {filtersToShow.map((filterItem) => (
          <li
            className='group bg-white p-2'
            key={`facet-picker-${filterItem.attribute}-${filterItem.value}`}
            onClick={() => {
              updateUrlToReflectSearchState({
                [filterItem.attribute]: filterItem.value,
              })
              setInputValue('')
              closeMenu()
            }}
          >
            <div className='flex cursor-pointer items-center group-hover:bg-primary50'>
              <span
                className={classMerge(
                  'mr-2 inline-block size-6 rounded border border-neutral600 group-hover:bg-primary600',
                  filterItem.isSelected ? 'bg-primary600' : '',
                )}
              >
                <Icon className='px-1.5 text-sm text-white' icon={faCheck} />
              </span>
              <Text className='inline' styleName='p-small'>
                {filterItem.value}
              </Text>
            </div>
          </li>
        ))}
        {filtersToShow.length === 0 && (
          <li className='bg-white p-2 text-center'>
            <Text styleName='p-small'>{`No filter found for "${inputValue}"`}</Text>
            <Text
              className='cursor-pointer text-primary600 hover:bg-primary50'
              onClick={() => {
                updateUrlToReflectSearchState({ query: inputValue })
                setInputValue('')
                closeMenu()
              }}
              styleName='p-small'
            >
              <Icon className='mr-2' icon={faMagicWandSparkles} />
              Apply as a custom filter
            </Text>
          </li>
        )}
      </ul>
      <ModalOverlay hidden={!menuVisible} onClick={closeMenu} />
    </div>
  )
}
