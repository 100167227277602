import { TrialCardList } from '@components/app/TrialSearchResults/ConnectedComponents/TrialCardList'
import type { TrackingSource } from '@lib/tracking'
import { CustomPagination } from './PaginationControl'
import { SearchPills } from './SearchPills'
import { TotalTrialsCount } from './TotalTrialsCount'
import { FilterList, QuickSearch } from './TrialFilters'

export const TrialSearchListings = ({
  includeResults,
  trackingSource,
}: {
  includeResults: boolean
  trackingSource: TrackingSource
}) => {
  if (!includeResults) {
    return <></>
  }

  return (
    <div className='relative flex gap-4 pb-4'>
      <div className='hidden min-w-[330px] max-w-[330px] md:flex md:flex-col'>
        <div className='w-full self-start rounded-t-lg border-r-neutral300 py-4 pr-4'>
          <QuickSearch />
        </div>
        <div className='rounded-b-lg border-r-neutral300 pb-6 pr-4 md:flex md:flex-col'>
          <FilterList />
        </div>
      </div>

      <div className='w-full'>
        <section className='flex flex-col gap-4 pt-4'>
          <div className='flex w-full flex-row gap-4'>
            <div className='hidden md:flex'>
              <TotalTrialsCount />
            </div>
            <SearchPills />
          </div>
          <TrialCardList trackingSource={trackingSource} />
          <CustomPagination />
        </section>
      </div>
    </div>
  )
}
