const ALGOLIA_INDEX_VERSION = 'v0'

function getAlgoliaEnv() {
  if (process.env.NEXT_PUBLIC_ALGOLIA_ENV) {
    return process.env.NEXT_PUBLIC_ALGOLIA_ENV
  } else if (process.env.ALGOLIA_ENV) {
    return process.env.ALGOLIA_ENV
  } else {
    return 'production' // fallback to production
  }
}

const algoliaEnv = getAlgoliaEnv()

/**
 * Helper method to compose the index name for a given category.
 */
export function getAlgoliaSearchIndex(
  category: string,
  env = algoliaEnv,
): string {
  return [env, 'power', ALGOLIA_INDEX_VERSION, category].join('-')
}
