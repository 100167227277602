import { hasMatch, parseInclusionStringFromTrial } from '.'
import type { TrialInDatabaseForAlgolia } from '../../types/TrialInDatabaseForAlgolia'
import type { FilterItem } from './ngramData'
import { monthlyVisitsFilterOptions } from './ngramData'

export default function hasMonthlyVisitsRequiredFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const trialTextContent = parseInclusionStringFromTrial(trial)

  const trialMatchesTag = (tag: FilterItem) =>
    tag.matches.some((match) => hasMatch(trialTextContent, match))

  return monthlyVisitsFilterOptions.some(trialMatchesTag)
}
