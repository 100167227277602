import {
  DEFAULT_SEARCH_STATE,
  type SearchParamsObject,
} from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import LocalStorageItem, {
  type LocalStorageKey,
} from '@lib/browsers/localStorage/LocalStorageItem'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'

export const TRIAL_SEARCH_LOCAL_STORAGE_KEY =
  'trialSearchHistory' as LocalStorageKey

export const pushSearchHistoryIntoLocalStorage = (
  query: SearchParamsObject,
) => {
  const searchHistory = new LocalStorageItem(TRIAL_SEARCH_LOCAL_STORAGE_KEY)

  const history = searchHistory.getItem() as {
    [key: string]: SearchParamsObject
  }

  if (query.condition && !isEqualDefaultSearchState(query)) {
    // push the current search state to the history with the condition as the key
    searchHistory.setItem({ ...history, [query.condition]: query })
  }
}

export const isEqualDefaultSearchState = (query: SearchParamsObject) => {
  return (
    isEqual(
      pickBy(
        omit(query, [
          'currentLocation',
          'lat',
          'lng',
          'distance',
          'ageList',
          'hasNoPlacebo',
        ]),
      ),
      {
        ...DEFAULT_SEARCH_STATE,
        condition: query.condition,
      },
    ) ||
    isEqual(pickBy(query), {
      condition: query.condition,
    })
  )
}
