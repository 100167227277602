/**
 * Simple DOM manipulation to load an async <script>. You really should be using `next/script` unless you need
 * to defer loading to avoid initial page load penalties.
 */
export function addScript({
  id,
  onLoad,
  src,
}: {
  id: string
  onLoad?: () => void
  src: string
}): HTMLElement {
  const existing = document.getElementById(id)
  if (existing) {
    return existing
  }

  const script = document.createElement('script')
  script.src = src
  script.id = id
  script.async = true
  script.onload = (): void => {
    if (onLoad) {
      onLoad()
    }
  }
  document.body.appendChild(script)

  return script
}

/**
 * Remove a <script> from the DOM. You really shouldn't need to use this.
 */
export function removeScript({ id }: { id: string }): void {
  const script = document.getElementById(id)
  if (script) {
    document.body.removeChild(script)
  }
}
