import useIsPartnerPage from '@components/hooks/useIsPartnerPage'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { useStats } from '@lib/utilities/algolia/useStats'
import pluralize from '@lib/utilities/text/pluralize'
import type { StatsConnectorParams } from 'instantsearch.js/es/connectors/stats/connectStats'

export const TotalTrialsCount = (props: StatsConnectorParams) => {
  const { nbHits } = useStats(props)
  const viewingPartnerPage = useIsPartnerPage()

  let totalTrials = pluralize('trial', nbHits, { includeCount: true })
  if (viewingPartnerPage) {
    totalTrials += ' found via Power'
  }
  return nbHits > 0 ? (
    <Text
      className={classMerge(
        'whitespace-nowrap break-keep',
        viewingPartnerPage ? 'text-black' : 'text-neutral600',
      )}
      styleName={viewingPartnerPage ? 'p-strong' : 'p'}
      value={totalTrials}
    />
  ) : (
    <></>
  )
}
