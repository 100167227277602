import { ContactStatus } from '@modules/contacts/types'
import isResponsiveOrMonetized from '@modules/trials/helpers/isResponsiveOrMonetized'
import parseLocationNamesFromLocations from '@modules/trials/helpers/parseLocationNamesFromLocations'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { TrialSiteLocationInDatabaseForAlgolia } from '@modules/trials/types/TrialSiteLocationInDatabaseForAlgolia'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import getCustomRankScoreFromSiteLocation from './getCustomRankScoreFromSiteLocation'

export default function transformSiteLocationForAlgolia(
  trial: TrialInDatabaseForAlgolia,
  trialLocation: TrialSiteLocationInDatabaseForAlgolia,
) {
  const _geoloc =
    trialLocation.lat && trialLocation.lng
      ? [
          {
            lat: parseFloat(trialLocation.lat),
            lng: parseFloat(trialLocation.lng),
          },
        ]
      : []

  const locationNames = parseLocationNamesFromLocations([trialLocation])
  const hasSiteContactWithEmail = getHasSiteContactWithEmail(trialLocation)
  const hasSiteContactWithPastReply =
    getHasSiteContactWithPastReply(trialLocation)
  const hasVerifiedSiteContact = getHasVerifiedSiteContact(trialLocation)
  const customRankScores = getCustomRankScoreFromSiteLocation(
    trial,
    trialLocation,
  )
  const isResponsive = isResponsiveOrMonetized(trial, trialLocation)

  const strippedTrialLocation = {
    ...omit(trialLocation, 'contacts'),
    contacts: trialLocation.contacts.map((siteContact) => ({
      ...siteContact,
      contact: pick(siteContact.contact, [
        'email',
        'name',
        'phone',
        'phoneExt',
      ]),
    })),
  }

  return {
    hasSiteContactWithEmail,
    hasSiteContactWithPastReply,
    hasVerifiedSiteContact,
    isResponsive,
    ...customRankScores,
    _geoloc,
    // add custom rank scores specific to this site location
    // Overwrite with current trial location specific content if present
    objectID: [strippedTrialLocation.nctId, strippedTrialLocation.id].join('-'),
    trialLocations: [strippedTrialLocation],
    ...locationNames,
  }
}

export function getHasSiteContactWithEmail(
  trialLocation: TrialSiteLocationInDatabaseForAlgolia,
) {
  return trialLocation.contacts.some(
    (siteContact) => !isEmpty(siteContact.contact.email),
  )
}

export function getHasSiteContactWithPastReply(
  trialLocation: TrialSiteLocationInDatabaseForAlgolia,
) {
  return trialLocation.contacts.some((siteContact) =>
    siteContact.contact.relayThreads.some(
      (relayThread) =>
        relayThread.smsRelays.length > 0 || // SMS has a where filter to only include Patient recipients
        relayThread.emailRelaysToPatient.length > 0 ||
        relayThread.phoneRelays.length > 0, // phone has a where filter to only include Patient recipients
    ),
  )
}

export function getHasVerifiedSiteContact(
  trialLocation: TrialSiteLocationInDatabaseForAlgolia,
) {
  return trialLocation.contacts.some(
    (siteContact) => siteContact.status !== ContactStatus.UNVERIFIED,
  )
}
