import type { ArrayElement } from '@lib/types/arrayElement'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { EligibilityCriterionConcept, Prisma } from '@prisma/client'
import { literal, object, string } from 'zod'
import {
  PowerOntologyInclusiveSetAny,
  PowerOntologySource,
  PowerOntologyVocabulary,
} from '../../parseEligibilityCriteria/types'
import { disallowTermsFilter } from './disallowTermsFilter'

/**
 * Retrieve a string array set of terms representing the inclusion set for prior treatments for
 * this trial. Should be of the form ["Chemotherapy", "Immunotherapy"] or ["any"]. If no terms
 * are found, add "any" for inclusion criteria
 */
export function getPriorTreatmentsInclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const priorTreatments = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria(),
    new Set<string>(),
  )

  if (priorTreatments.size === 0) {
    priorTreatments.add(PowerOntologyInclusiveSetAny)
  }

  return Array.from(priorTreatments).sort()
}

/**
 * Retrieve a string array set of terms representing the exclusion set for prior treatments for
 * this trial. Should be of the form ["Adjuvant Chemotherapy"]
 */
export function getPriorTreatmentsExclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const priorTreatments = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria({ isInclusion: false }),
    new Set<string>(),
  )

  return Array.from(priorTreatments).sort()
}

function extractTermsFromEligibilityCriteria(
  { isInclusion }: { isInclusion: boolean } = { isInclusion: true },
) {
  return (
    terms: Set<string>,
    criterion: ArrayElement<
      TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
    >,
  ) => getTermsFromEligibilityCriteria(terms, criterion, isInclusion)
}

function getTermsFromEligibilityCriteria(
  terms: Set<string>,
  criterion: ArrayElement<
    TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
  >,
  isInclusion: boolean,
) {
  for (const component of criterion.criterionComponents) {
    if (hasValidPriorTreatmentData(component)) {
      const priorTreatments = parsePriorTreatmentFromRawData(
        component.rawData,
        isInclusion,
      )
      if (priorTreatments && priorTreatments.length > 0) {
        priorTreatments.map(terms.add, terms)
      }
    }
  }

  return terms
}

function hasValidPriorTreatmentData({
  eligibilityCriterionConcept,
  rawData,
}: {
  eligibilityCriterionConcept: Pick<
    EligibilityCriterionConcept,
    | 'ontologyClass'
    | 'ontologyIdentifier'
    | 'ontologySource'
    | 'ontologyVocabulary'
  >
  rawData: Prisma.JsonValue
}) {
  return (
    hasValidRawData(rawData) &&
    eligibilityCriterionConcept.ontologySource === PowerOntologySource &&
    eligibilityCriterionConcept.ontologyVocabulary ===
      PowerOntologyVocabulary.Treatments
  )
}

// Example: {"status": "include", "priorTreatment": "Neoadjuvant Chemotherapy", "line_of_therapy": ["2", "3", "4+"]}
const PriorTherapyRawDataValidator = object({
  line_of_therapy: string().array(),
  priorTreatment: string(),
  status: literal('include').or(literal('exclude')),
})

function hasValidRawData(rawData: Prisma.JsonValue) {
  return PriorTherapyRawDataValidator.safeParse(rawData).success
}

function parsePriorTreatmentFromRawData(
  rawData: Prisma.JsonValue,
  useInclusion: boolean,
) {
  const parsedValue = PriorTherapyRawDataValidator.safeParse(rawData)
  if (!parsedValue.success) {
    return []
  }

  // Exit early if we are looking for inclusion and this is exclusion criterion or vice versa
  const statusHasExclusion = parsedValue.data.status.includes('exclu')
  if (
    (useInclusion && statusHasExclusion) ||
    (!useInclusion && !statusHasExclusion)
  ) {
    return []
  }

  // Terms should look like ["Neoadjuvant Chemotherapy"]
  const term = parsedValue.data.priorTreatment.trim()

  // ignore "line of standard therapy" or "line of therapy"
  if (disallowTermsFilter(term)) {
    return []
  }

  return [term]
}
