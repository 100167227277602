export type HelpfulInformationForPatientCategory =
  | 'how-responsive-is-this-trial'
  | 'who-else-is-applying'

export const HELPFUL_INFORMATION_FOR_PATIENTS_CATEGORIES: Record<
  HelpfulInformationForPatientCategory,
  HelpfulInformationForPatientCategory
> = {
  'how-responsive-is-this-trial': 'how-responsive-is-this-trial',
  'who-else-is-applying': 'who-else-is-applying',
}
