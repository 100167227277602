import type { ArrayElement } from '@lib/types/arrayElement'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import { getComorbidityTermsFromEligibilityCriteria } from './getComorbidityTermsFromEligibilityCriteria'

/**
 * Retrieve a string array set of terms representing the inclusion set for co-morbidities for
 * this trial. Should be of the form ["prostate cancer", "diabetes"] or ["any"]. If no terms
 * are found, add "any" for inclusion criteria
 */
export function getComorbiditiesInclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const comorbidities = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria(),
    new Set<string>(),
  )

  // No comment about comorbidities for this inclusion criteria, so add "any"
  if (comorbidities.size === 0) {
    return []
  }

  return Array.from(comorbidities).sort()
}

/**
 * Retrieve a string array set of terms representing the exclusion set for comorbidities for
 * this trial. Should be of the form ["prostate cancer"]
 */
export function getComorbiditiesExclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const comorbidities = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria({ isInclusion: false }),
    new Set<string>(),
  )

  return Array.from(comorbidities).sort()
}

function extractTermsFromEligibilityCriteria(
  { isInclusion }: { isInclusion: boolean } = { isInclusion: true },
) {
  return (
    terms: Set<string>,
    criterion: ArrayElement<
      TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
    >,
  ) =>
    getComorbidityTermsFromEligibilityCriteria({
      criterion,
      isInclusion,
      terms,
    })
}
