import isInCurrentMonth from '@lib/utilities/time/isInCurrentMonth'
import type { TrialInDatabaseForAlgolia } from '../types/TrialInDatabaseForAlgolia'

/**
 * Is this a new trial this month? Add a boolean to show that it is
 */
export default function isNewThisMonthFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  return isInCurrentMonth(trial.createdAt)
}
