import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { PaginatedButtonClassNames } from './paginationCssClasses'

export const PaginationControlEllipsis = () => {
  return (
    <Text
      className={classMerge(PaginatedButtonClassNames, 'text-neutral900')}
      value={'…'}
    />
  )
}
