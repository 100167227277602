import type { ReactNode } from 'react'
import { ConditionAutocomplete } from '../SearchableRefinementList'

export const ConditionSearchInput = ({
  autoFocus = true,
  conditionPlaceholder,
  includeResultCount = true,
  locationPlaceholder,
  setConditionInput,
}: {
  autoFocus?: boolean
  conditionPlaceholder?: string
  includeResultCount?: boolean
  locationPlaceholder?: string
  setConditionInput: (val: string) => void
}) => {
  return (
    <div className='h-14 w-10/12 border-r-0 border-neutral100 px-4 md:w-3/12 md:border-r-2'>
      <LabelledPicker htmlFor={'search-bar-condition-input'} label='Condition'>
        <ConditionAutocomplete
          autoFocus={autoFocus}
          conditionPlaceholder={conditionPlaceholder}
          includeResultCount={includeResultCount}
          locationPlaceholder={locationPlaceholder}
          onInputChange={setConditionInput}
        />
      </LabelledPicker>
    </div>
  )
}

export const LabelledPicker = ({
  children,
  htmlFor,
  label,
}: {
  children: ReactNode
  htmlFor: string
  label: string
}) => {
  return (
    <div className='grid grid-cols-1'>
      <label
        className='pb-2 text-sm font-normal leading-[140%]'
        htmlFor={htmlFor}
      >
        {label}
      </label>
      {children}
    </div>
  )
}
