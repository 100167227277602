import type { ArrayElement } from '@lib/types/arrayElement'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { EligibilityCriterionConcept, Prisma } from '@prisma/client'
import { literal, object, string } from 'zod'
import {
  PowerOntologyInclusiveSetAny,
  PowerOntologySource,
  PowerOntologyVocabulary,
} from '../../parseEligibilityCriteria/types'

/**
 * Retrieve a string array set of terms representing the inclusion set for grade or gleason scores for
 * this trial. Should be of the form ["Grade 1", "Gleason Score 6"] or ["any"]. If no terms
 * are found, add "any" for inclusion criteria
 */
export function getGradeGleasonScoreInclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const gradeGleasonScores = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria(),
    new Set<string>(),
  )

  // No comment about grade or gleason score for this inclusion criteria, so add "any"
  if (gradeGleasonScores.size === 0) {
    gradeGleasonScores.add(PowerOntologyInclusiveSetAny)
  }

  return Array.from(gradeGleasonScores).sort()
}

/**
 * Retrieve a string array set of terms representing the exclusion set for grade or gleason score for
 * this trial. Should be of the form ["Gleason Score 8", "Gleason Score 9", "Gleason Score 10"]
 */
export function getGradeGleasonScoreExclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const gradeGleasonScores = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria({ isInclusion: false }),
    new Set<string>(),
  )

  return Array.from(gradeGleasonScores).sort()
}

function extractTermsFromEligibilityCriteria(
  { isInclusion }: { isInclusion: boolean } = { isInclusion: true },
) {
  return (
    terms: Set<string>,
    criterion: ArrayElement<
      TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
    >,
  ) => getTermsFromEligibilityCriteria(terms, criterion, isInclusion)
}

function getTermsFromEligibilityCriteria(
  terms: Set<string>,
  criterion: ArrayElement<
    TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
  >,
  isInclusion: boolean,
) {
  for (const component of criterion.criterionComponents) {
    if (hasValidGradeGleasonScoreData(component)) {
      const gradeGleasonScores = parseGradeGleasonFromRawData(
        component.rawData,
        isInclusion,
      )
      if (gradeGleasonScores && gradeGleasonScores.length > 0) {
        gradeGleasonScores.map(terms.add, terms)
      }
    }
  }

  return terms
}

function hasValidGradeGleasonScoreData({
  eligibilityCriterionConcept,
  rawData,
}: {
  eligibilityCriterionConcept: Pick<
    EligibilityCriterionConcept,
    | 'name'
    | 'ontologyClass'
    | 'ontologyIdentifier'
    | 'ontologySource'
    | 'ontologyVocabulary'
  >
  rawData: Prisma.JsonValue
}) {
  return (
    hasValidRawData(rawData) &&
    eligibilityCriterionConcept.ontologySource === PowerOntologySource &&
    eligibilityCriterionConcept.ontologyVocabulary ===
      PowerOntologyVocabulary.GradeGleasonScore
  )
}

// Example: {"status": "include", "type": "grade", "values": ["2", "3", "4"]}
const GradeGleasonScoreRawDataValidator = object({
  status: literal('include').or(literal('exclude')),
  type: literal('grade').or(literal('gleason')),
  value: string().array(),
})

function hasValidRawData(rawData: Prisma.JsonValue) {
  return GradeGleasonScoreRawDataValidator.safeParse(rawData).success
}

function parseGradeGleasonFromRawData(
  rawData: Prisma.JsonValue,
  useInclusion: boolean,
) {
  const parsedValue = GradeGleasonScoreRawDataValidator.safeParse(rawData)
  if (!parsedValue.success) {
    return []
  }

  // Exit early if we are looking for inclusion and this is exclusion criterion or vice versa
  const statusHasExclusion = parsedValue.data.status.includes('exclu')
  if (
    (useInclusion && statusHasExclusion) ||
    (!useInclusion && !statusHasExclusion)
  ) {
    return []
  }

  // Terms should look like ["Grade 4", "Grade 5", "Gleason Score 6"]
  const validGrades = ['1', '2', '3', '4', '5']
  const validGleasonScores = ['6', '7', '8', '9', '10']

  const termsSet = new Set<string>()
  parsedValue.data.value.map((value) => {
    if (parsedValue.data.type === 'grade' && validGrades.includes(value)) {
      termsSet.add(`Grade ${value}`)
    } else if (
      parsedValue.data.type === 'gleason' &&
      validGleasonScores.includes(value)
    ) {
      termsSet.add(`Gleason Score ${value}`)
    }
  })

  return Array.from(termsSet)
}
