import {
  EFFECTOR_NSCLC,
  HENGENIX_SCLC_EXTENSIVE_STAGE,
  LUNGEVITY_NEOPLASMS,
} from './index'

export function handleHengenixSclcLimitedStageCustomRecommendationLogic(
  strippedColloquialTerms: string[],
) {
  if (strippedColloquialTerms.length > 0) {
    return [HENGENIX_SCLC_EXTENSIVE_STAGE, LUNGEVITY_NEOPLASMS]
  }

  return [EFFECTOR_NSCLC, LUNGEVITY_NEOPLASMS]
}
