import { classMerge } from '@components/utilities/classMerge'
import { faArrowSquareLeft } from '@fortawesome/pro-regular-svg-icons/faArrowSquareLeft'
import { faArrowSquareRight } from '@fortawesome/pro-regular-svg-icons/faArrowSquareRight'
import { Icon } from '../Icon'
import { Text } from '../Text'
import { PaginatedDisabledButtonClassNames } from './paginationCssClasses'

export const PaginationControlDisabledModifier = ({
  direction,
  identifier,
}: {
  direction: 'decrement' | 'increment'
  identifier?: number | string
}) => (
  <Text
    className={classMerge(
      'mx-1 flex size-8 items-center justify-center hover:cursor-pointer',
      PaginatedDisabledButtonClassNames,
    )}
    element='div'
    key={identifier}
    value={
      <Icon
        icon={
          direction === 'decrement' ? faArrowSquareLeft : faArrowSquareRight
        }
      />
    }
  />
)
