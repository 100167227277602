import SearchButton from '@components/app/Button/SearchButton'
import useIsPartnerPage from '@components/hooks/useIsPartnerPage'
import Button from '@components/primitives/Button'
import { Icon } from '@components/primitives/Icon'
import { classMerge } from '@components/utilities/classMerge'
import { faSlidersH } from '@fortawesome/pro-regular-svg-icons/faSlidersH'
import { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { AgeRefinementList } from '../AgeRefinementList'
import DistanceConfiguration from '../DistanceConfiguration'
import type { Coordinates } from '../LocationConfiguration'
import { ConditionSearchInput, LabelledPicker } from './ConditionSearchInput'
import { LocationPicker } from './LocationPicker'
import { MobileSearchFilterModal } from './MobileSearchFilterModal'

export type SearchConfig = {
  autoFocus: boolean
  isSearchPage: boolean
  shadowStyle?: string
}

export type SearchCallbacks = {
  conditionPlaceholder?: string
  interventionPlaceholder?: string
  locationPlaceholder?: string
  queryCoordinates: Coordinates
  searchButtonClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
  setConditionInput: (value: string) => void
  setQueryCoordinates: (value: Coordinates) => void
}

const TriggerFilterModalButton = ({
  setModalVisible,
}: {
  setModalVisible: (val: boolean) => void
}) => {
  return (
    <div className='size-14 px-0 text-right md:pl-4'>
      <Button
        className='h-14 w-full rounded-full'
        color={'mediumGrey'}
        onClick={() => setModalVisible(true)}
        value={<Icon className='block pr-0' icon={faSlidersH} />}
      />
    </div>
  )
}

export const SearchInputs = ({
  autoFocus,
  conditionPlaceholder,
  interventionPlaceholder,
  isSearchPage,
  locationPlaceholder,
  queryCoordinates,
  searchButtonClick,
  setConditionInput,
  setQueryCoordinates,
  shadowStyle,
}: SearchCallbacks & SearchConfig) => {
  const [modalVisible, setModalVisible] = useState(false)
  const { inView, ref } = useInView({ threshold: 0 })
  const isPartnerPage = useIsPartnerPage()

  return (
    <>
      <div
        className={classMerge(
          'relative mx-auto flex flex-row items-center rounded-full border-2 border-neutral50 bg-white p-4',
          shadowStyle,
        )}
        ref={ref}
      >
        <ConditionSearchInput
          autoFocus={autoFocus}
          conditionPlaceholder={conditionPlaceholder ?? interventionPlaceholder} // We show the intervention in the condition input form
          locationPlaceholder={locationPlaceholder}
          setConditionInput={setConditionInput}
        />

        <div className='relative hidden h-14 w-3/12 border-r-2 border-neutral100 px-4 md:block'>
          <LabelledPicker
            htmlFor={'search-bar-location-input'}
            label='Location'
          >
            <LocationPicker
              conditionPlaceholder={conditionPlaceholder}
              interventionPlaceholder={interventionPlaceholder}
              locationPlaceholder={locationPlaceholder}
              queryCoordinates={queryCoordinates}
              setQueryCoordinates={setQueryCoordinates}
            />
          </LabelledPicker>
        </div>
        <div className='hidden h-14 w-2/12 border-r-2 border-neutral100 px-4 md:block'>
          <div className='grid grid-cols-1'>
            <DistanceConfiguration
              conditionPlaceholder={conditionPlaceholder}
              interventionPlaceholder={interventionPlaceholder}
              locationPlaceholder={locationPlaceholder}
            />
          </div>
        </div>
        <div className='hidden h-14 w-2/12 px-4 md:block'>
          <LabelledPicker htmlFor={'search-bar-age-input'} label='Age'>
            <AgeRefinementList
              conditionPlaceholder={conditionPlaceholder}
              interventionPlaceholder={interventionPlaceholder}
              locationPlaceholder={locationPlaceholder}
              placeholderText='Type Age'
            />
          </LabelledPicker>
        </div>
        <div className='block md:hidden'>
          <TriggerFilterModalButton setModalVisible={setModalVisible} />
        </div>

        <div className='hidden h-14 w-2/12 px-0 text-right md:block md:pl-4'>
          <SearchButton.Link onClick={searchButtonClick} />
        </div>
      </div>

      {isSearchPage && !isPartnerPage && (
        <div className='fixed right-6 top-3 z-filterBar block md:hidden'>
          <div className='px-0 text-right md:pl-4'>
            <Button
              className='rounded-full px-3'
              color={inView ? 'mediumGrey' : 'black'}
              onClick={() => setModalVisible(true)}
              value={
                <>
                  <Icon className='mr-2 block' icon={faSlidersH} />
                  Filter
                </>
              }
            />
          </div>
        </div>
      )}
      <MobileSearchFilterModal
        {...{
          conditionPlaceholder,
          modalVisible,
          queryCoordinates,
          setConditionInput,
          setModalVisible,
          setQueryCoordinates,
        }}
      />
    </>
  )
}
