import {
  deserializeSearchParamValues,
  getAllSearchParams,
  INCLUSION_CRITERIA_FILTERS,
  MULTI_SELECT_FILTERS,
  OTHER_FILTERS,
  type SearchParam,
  type SearchParams,
} from '@components/hooks/ParamsProvider/searchParams'
import {
  DEFAULT_SEARCH_STATE,
  FILTERS_TO_CLEAR,
} from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import { usePrevious } from '@components/hooks/usePrevious'
import { ALL_SPECIAL_VALUE } from '@components/primitives/ButtonBar'
import { trackEvent, TrackingEvent } from '@lib/tracking'
import { getAgeFacetsForAgeInput } from '@modules/trials/helpers/algolia/getAgeFacetsForAgeInput'
import isEqual from 'lodash/isEqual'
import type { ReadonlyURLSearchParams } from 'next/navigation'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { useInstantSearch, useRefinementList } from 'react-instantsearch'

const extractScores = (score: string | null) => {
  if (!score) return []
  if (score === ALL_SPECIAL_VALUE) return []
  return score.split(',')
}

const allMultiFilters = [...INCLUSION_CRITERIA_FILTERS, ...MULTI_SELECT_FILTERS]
const allFilters = [...allMultiFilters, ...OTHER_FILTERS]

const CLEAR_ALL_DIFF = FILTERS_TO_CLEAR.reduce(
  (acc, curr) => ({ ...acc, [curr]: '' }),
  {},
)

export const transformQueryParamsIntoAlgoliaRequest = (
  urlSearchState: SearchParams,
) => {
  const {
    ageList: age,
    condition,
    effectivenessScore,
    hasNoPlacebo,
    page,
    query,
    safetyScore,
  } = urlSearchState

  const refinements = {} as { [key: string]: string[] }
  allMultiFilters.forEach((filter) => {
    refinements[filter] = deserializeSearchParamValues(
      filter,
      urlSearchState[filter],
    )
  })

  return {
    page: parseInt(page ?? '1', 10),
    query: query ? query : undefined,
    refinementList: {
      ...refinements,
      ageList: age ? getAgeFacetsForAgeInput(age) : [],
      conditions: condition ? [condition] : [],
      effectivenessScore: extractScores(effectivenessScore),
      hasNoPlacebo: hasNoPlacebo === 'true' ? ['true'] : ['true', 'false'],
      safetyScore: extractScores(safetyScore),
      searchConditions: condition ? [condition] : [],
    },
  }
}

const trackSearchEvent = ({
  newQuery,
  oldQuery,
}: {
  newQuery?: ReadonlyURLSearchParams | null
  oldQuery?: ReadonlyURLSearchParams | null
}) => {
  if (!oldQuery || !newQuery) return

  const filtersChanged = {} as { [key: string]: string | undefined }

  newQuery.forEach((val, key) => {
    if (oldQuery.get(key) !== newQuery.get(key)) {
      filtersChanged[key] = newQuery.get(key) as string | undefined
    }
  })

  if (
    isEqual(filtersChanged, DEFAULT_SEARCH_STATE) ||
    isEqual(filtersChanged, CLEAR_ALL_DIFF)
  ) {
    // Special case where we don't want to track these events
    return
  }

  // Track each filter that has been changed
  Object.entries(filtersChanged).forEach(([key, val]) => {
    trackEvent(TrackingEvent.DIRECTORY_FILTER_MODIFIED, {
      type: key,
      val,
    })
  })
}

/**
 * This component is responsible taking the search page's query parameters from the URL and applying them to the
 * algolia search state via `setIndexUiState`. Some query parameters can be applied directly, but others need to be
 * transformed or de-serialized before being applied to the search state.
 *
 * We use this one "top-level" function rather than having sub-components that are responsible for applying 'refine()' themselves
 */
export const AlgoliaSearchStateManager = () => {
  const { setIndexUiState } = useInstantSearch()
  const pathName = usePathname()
  const searchParams = useSearchParams()
  const oldQuery = usePrevious(searchParams)

  useEffect(() => {
    trackSearchEvent({ newQuery: searchParams, oldQuery })
    const queryParams = getAllSearchParams()
    const newAlgoliaSearchState =
      transformQueryParamsIntoAlgoliaRequest(queryParams)
    setIndexUiState(newAlgoliaSearchState)
  }, [pathName, searchParams])

  return (
    <>
      {allFilters.map((filter) => (
        <Refinement attribute={filter} key={filter} />
      ))}
    </>
  )
}

/**
 * These are necessary so that the search state actually gets refined, if we don't have this component,
 * the search state does not filter properly.
 */
const Refinement = ({ attribute }: { attribute: SearchParam }) => {
  useRefinementList({ attribute })
  return <></>
}
