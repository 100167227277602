import { cancerSubtypes } from './cancerSubtypes'

export const conditionSubtypes = [
  { condition: 'Breast Cancer', subtypes: ['Ductal Carcinoma'] },
  {
    condition: 'Cancer',
    subtypes: cancerSubtypes,
  },
  {
    condition: 'Prostate Cancer',
    subtypes: ['Adenocarcinoma', 'Prostate Carcinoma'],
  },
  {
    condition: 'Obesity',
    subtypes: [
      'Abdominal Obesity',
      'Metabolic Syndrome',
      'Postpartum Weight Retention',
    ],
  },
  {
    condition: 'Depression',
    subtypes: ['Major Depressive Disorder', 'Persistent Depressive Disorder'],
  },
  {
    condition: 'Colorectal Cancer',
    subtypes: ['Adenomatous Polyposis', 'Colon Cancer', 'Rectal Cancer'],
  },
  {
    condition: 'Lung Cancer',
    subtypes: [
      'Adenocarcinoma',
      'Non-Small Cell Lung Cancer',
      'Small Cell Lung Cancer',
    ],
  },
  {
    condition: 'Pancreatic Cancer',
    subtypes: [
      'Adenocarcinoma',
      'Pancreatic Adenocarcinoma',
      'Pancreatic Endocrine Carcinoma',
      'Pancreatic Neuroendocrine Tumors',
    ],
  },
  {
    condition: "Non-Hodgkin's Lymphoma",
    subtypes: [
      'Burkitt Lymphoma',
      'Cutaneous T-Cell Lymphoma',
      'Diffuse Large B-Cell Lymphoma',
      'Follicular Lymphoma',
      'Lymphomatoid Granulomatosis',
      'MALT Lymphoma',
      'Mantle Cell Lymphoma',
      'Marginal Zone Lymphoma',
      'Plasmablastic Lymphoma',
      'Primary Effusion Lymphomas',
    ],
  },
  {
    condition: 'Acute Myeloid Leukemia',
    subtypes: [
      'Acute Myelogenous Leukemia',
      'Acute Myelomonocytic Leukemia',
      'Acute Promyelocytic Leukemia',
      'Adult Acute Myeloid Leukemia',
      'Megakaryoblastic Leukemia',
      'Monoblastic Leukemia',
      'Monocytic Leukemia',
      'Prolymphocytic Leukemia',
    ],
  },
  {
    condition: 'Ovarian Cancer',
    subtypes: ['Endometrioid Adenocarcinoma', 'Ovarian Tumors'],
  },
  {
    condition: 'Stroke',
    subtypes: [
      'Brain Stem Stroke',
      'Cerebral Ischemia',
      'Hemorrhagic Stroke',
      'Ischemic Stroke',
      'Mini Stroke',
      'Subarachnoid Hemorrhage',
    ],
  },
  {
    condition: 'Lymphoma',
    subtypes: [
      'Burkitt Lymphoma',
      'Cutaneous T-Cell Lymphoma',
      'Diffuse Large B-Cell Lymphoma',
      'Follicular Lymphoma',
      'Large Cell Lymphoma',
      'Lymphomatoid Granulomatosis',
      'MALT Lymphoma',
      'Mantle Cell Lymphoma',
      'Marginal Zone Lymphoma',
      'Plasmablastic Lymphoma',
      'Primary Effusion Lymphomas',
    ],
  },
  {
    condition: 'Brain Tumor',
    subtypes: [
      'Anaplastic Astrocytoma',
      'Astrocytoma',
      'Brain Stem Glioma',
      'Diffuse Astrocytoma',
      'Diffuse Intrinsic Pontine Glioma',
      'Ganglioglioma',
      'Gliomas',
      'Gliosarcoma',
      'Medulloblastoma',
      'Meningioma',
      'Pilocytic Astrocytoma',
      'Pituitary Adenomas',
      'Pituitary Tumors',
      'Spinal Tumors',
    ],
  },
  {
    condition: 'Heart Failure',
    subtypes: [
      'Left Ventricular Dysfunction',
      'Right Ventricular Dysfunction',
      'Takotsubo Syndrome',
    ],
  },
  {
    condition: 'Coronavirus',
    subtypes: [
      'Coronavirus Disease',
      'COVID-19',
      'COVID-19 Infection',
      'Post-COVID Syndrome',
    ],
  },
  { condition: 'Osteoarthritis', subtypes: ['Hip Osteoarthritis'] },
  {
    condition: "Parkinson's Disease",
    subtypes: ['Lewy Body Dementia', 'Lewy Body Disease'],
  },
  { condition: 'High Blood Pressure', subtypes: ['Hypertension'] },
  {
    condition: 'Head and Neck Cancers',
    subtypes: ['Head and Neck Squamous Cell Carcinoma'],
  },
  {
    condition: 'Acute Lymphoblastic Leukemia',
    subtypes: ['T-Cell Lymphoblastic Leukemia'],
  },
  {
    condition: 'Myelodysplastic Syndrome',
    subtypes: ['Myelomonocytic Leukemia'],
  },
  {
    condition: 'Diabetes',
    subtypes: [
      'Diabetes Mellitus',
      'Gestational Diabetes',
      'Type 1 Diabetes',
      'Type 2 Diabetes',
      'Neonatal Hypoglycemia',
      'Congenital Hyperinsulinism',
      'Hypoglycemia Unawareness',
      'Prediabetes',
    ],
  },
  {
    condition: 'Cardiovascular Disease',
    subtypes: [
      'Abdominal Aortic Aneurysm',
      'Acute Coronary Syndrome',
      'Aneurysm',
      'Aortic Aneurysm',
      'Aortic Disease',
      'Aortic Dissection',
      'Aortic Regurgitation',
      'Aortic Stenosis',
      'Aortic Valve Disease',
      'Aortic Valve Stenosis',
      'Arterial Occlusive Disease',
      'Atherosclerosis',
      'Atrial Fibrillation',
      'Cardiomyopathy',
      'Carotid Artery Disease',
      'Churg-Strauss Syndrome',
      'Congenital Heart Defects',
      'Congestive Heart Failure',
      'Coronary Artery Disease',
      'Coronary Artery Stenosis',
      'Coronary Heart Disease',
      'Critical Limb Ischemia',
      'Deep Vein Thrombosis',
      'Dilated Cardiomyopathy',
      'Fibromuscular Dysplasia',
      'Giant Cell Arteritis',
      'Heart Valve Disease',
      'High Blood Pressure',
      'High Cholesterol',
      'Hypertension',
      'Hypertrophic Cardiomyopathy',
      'Intermittent Claudication',
      'Kidney Transplant Rejection',
      'Left Ventricular Dysfunction',
      'Low Blood Pressure',
      'Lupus',
      'Macular Edema',
      'Mitral Regurgitation',
      'Mitral Valve Disease',
      'Mitral Valve Regurgitation',
      'Myocardial Ischemia',
      'Myocarditis',
      'Peripheral Arterial Disease',
      'Peripheral Vascular Disease',
      'Polyarteritis Nodosa',
      'Portal Hypertension',
      'Pre-eclampsia',
      'Pulmonary Embolism',
      'Pulmonary Hypertension',
      'Pulmonary Valve Regurgitation',
      'Sarcoidosis',
      'Slow Heart Rate',
      'Stroke',
      'Supraventricular Tachycardia',
      'Systemic Sclerosis',
      'Takayasu Arteritis',
      'Thoracic Aortic Aneurysm',
      'Thoracoabdominal Aortic Aneurysms',
      'Thromboembolic Pulmonary Hypertension',
      'Transthyretin Amyloid Cardiomyopathy',
      'Tricuspid Regurgitation',
      'Valvular Heart Disease',
      'Vascular Disease',
      'Vasculitis',
      'Ventricular Arrhythmia',
      'Ventricular Tachycardia',
      'Von Willebrand Disease',
    ],
  },
  { condition: 'Liver Cancer', subtypes: ['Hepatocellular Carcinoma'] },
  {
    condition: 'Blood Cancers',
    subtypes: [
      'Acute Leukemia',
      'Acute Lymphoblastic Leukemia',
      'Acute Myelogenous Leukemia',
      'Chronic Lymphocytic Leukemia',
      'Chronic Myeloid Leukemia',
      'Multiple Myeloma',
    ],
  },
  {
    condition: 'Kidney Cancer',
    subtypes: ['Kidney Cell Carcinoma', 'Renal Cell Cancer'],
  },
  {
    condition: 'Dementia',
    subtypes: [
      'Agitation in Dementia',
      'Alzheimer Disease',
      "Alzheimer's Dementia",
      "Alzheimer's Disease",
      'Mild Cognitive Impairment',
    ],
  },
  { condition: 'Autism Spectrum Disorder', subtypes: ['Asperger Syndrome'] },
  {
    condition: 'Traumatic Brain Injury',
    subtypes: ['Concussion', 'Post-Concussion Syndrome'],
  },
  {
    condition: 'Tumors',
    subtypes: [
      'Adenocarcinoma',
      'Adenoid Cystic Carcinoma',
      'Adenoma',
      'Adenomatous Polyposis',
      'Adrenal Cancer',
      'Alveolar Rhabdomyosarcoma',
      'Alveolar Soft Part Sarcoma',
      'Ampullary Cancer',
      'Anal Canal Carcinoma',
      'Anal Cancer',
      'Anal Squamous Cell Carcinoma',
      'Anaplastic Astrocytoma',
      'Anaplastic Ependymoma',
      'Angiosarcoma',
      'Appendiceal Cancer',
      'Astrocytoma',
      'Atypical Teratoid/Rhabdoid Tumor',
      "Barrett's Esophagus",
      'Basal Cell Carcinoma',
      'Bile Duct Cancer',
      'Biliary Cancers',
      'Biliary Tract Cancer',
      'Bone Cancer',
      'Bone Tumors',
      'Brain Cancer',
      'Brain Metastases',
      'Brain Stem Glioma',
      'Brain Tumor',
      'Breast Cancer',
      'Breast Carcinoma',
      'Bronchial Tumors',
      'Carcinoid Syndrome',
      'Carcinoid Tumor',
      'Carcinoma',
      'Carcinosarcoma',
      'Central Nervous System Cancer',
      'Central Nervous System Tumor',
      'Cerebral Cavernous Malformations',
      'Cervical Adenocarcinoma',
      'Cervical Carcinoma',
      'Cervical Dysplasia',
      'Cervical Neoplasia',
      'Childhood Brain Tumors',
      'Childhood Cancer',
      'Cholangiocarcinoma',
      'Chondrosarcoma',
      'Chordoma',
      'Clear Cell Carcinoma',
      'Clear Cell Sarcoma of Soft Tissue',
      'Clinically Isolated Syndrome',
      'Colon Adenomas',
      'Colon Cancer',
      'Colorectal Adenomas',
      'Colorectal Cancer',
      'Colorectal Cancer Screening',
      'Colorectal Tumor',
      'Craniopharyngioma',
      'Desmoid Tumor',
      'Desmoplastic Small Round Cell Tumor',
      'Diabetic Macular Edema',
      'Diffuse Astrocytoma',
      'Diffuse Intrinsic Pontine Glioma',
      'Diffuse Midline Glioma',
      'Digestive System Tumors',
      'Ductal Carcinoma',
      'Embryonal Tumor',
      'Endocrine Tumors',
      'Endometrial Adenocarcinoma',
      'Ependymoma',
      'Epithelioid Sarcoma',
      'Esophageal Adenocarcinoma',
      'Esophageal Cancer',
      'Esophageal Carcinoma',
      'Esophageal Neoplasms',
      'Esthesioneuroblastoma',
      'Ewing Sarcoma',
      'Extranodal Lymphoma',
      'Fallopian Tube Adenocarcinoma',
      'Fallopian Tube Carcinoma',
      'Fallopian Tube Serous Adenocarcinoma',
      'Fibroid',
      'Frozen Shoulder',
      'Gallbladder Cancer',
      'Ganglioglioma',
      'Ganglioneuroblastoma',
      'Gastric Adenocarcinoma',
      'Gastric Cancer',
      'Gastric Neoplasm',
      'Gastroesophageal Junction Adenocarcinoma',
      'Gastroesophageal Junction Cancer',
      'Gastrointestinal Cancer',
      'Gastrointestinal Stromal Tumor',
      'Gastrointestinal Stromal Tumors',
      'Gastrointestinal Tumors',
      'Genital Neoplasms',
      'Genitourinary Cancers',
      'Germ Cell Tumors',
      'Giant Cell Tumor',
      'Glioblastoma',
      'Gliomas',
      'Gliosarcoma',
      'Head and Neck Cancers',
      'Head and Neck Neoplasms',
      'Head and Neck Squamous Cell Carcinoma',
      'Hemangiosarcoma',
      'Hepatoblastoma',
      'Hepatocellular Carcinoma',
      'Hypopharyngeal Cancer',
      'Insulinoma',
      'Intrahepatic Cholangiocarcinoma',
      'Kaposi Sarcoma',
      "Kaposi's Sarcoma",
      'Lambert-Eaton Myasthenic Syndrome',
      'Laryngeal Cancer',
      'Laryngeal Squamous Cell Carcinoma',
      'Leiomyosarcoma',
      'Lip and Oral Cavity Cancer',
      'Liposarcoma',
      'Liver Cancer',
      'Liver Metastases',
      'Liver Metastasis',
      'Liver Tumors',
      'Low Grade Glioma',
      'Lung Adenocarcinoma',
      'Lung Cancer',
      'Lung Carcinoma',
      'Lymphoma',
      'Lynch Syndrome',
      'Major Salivary Gland Cancer',
      'Malignant Glioma',
      'Malignant Mesothelioma',
      'Malignant Pleural Effusion',
      'Malignant Pleural Mesothelioma',
      'Malignant Rhabdoid Tumor',
      'Medulloblastoma',
      'Megakaryoblastic Leukemia',
      'Melanoma',
      'Meningioma',
      'Merkel Cell Carcinoma',
      'Mesothelioma',
      'Metastases',
      'Mole',
      'Monoblastic Leukemia',
      'Monocytic Leukemia',
      "Morton's Neuroma",
      'Multiple Endocrine Neoplasia',
      'Myasthenia Gravis',
      'Myelodysplastic Syndrome',
      'Myelodysplastic Syndromes',
      'Myeloid Leukemia',
      'Nasopharyngeal Cancer',
      'Nasopharyngeal Carcinoma',
      'Neck Cancer',
      'Neoplasm',
      'Neoplasms',
      'Neuroblastoma',
      'Neuroectodermal Tumors',
      'Neuroendocrine Carcinoma',
      'Neuroendocrine Tumors',
      'Neurofibroma',
      'Neurofibromatosis',
      'Non-Small Cell Carcinoma',
      'Non-Small Cell Lung Cancer',
      'Non-small Cell Lung Cancer (NSCLC)',
      'Oligoastrocytoma',
      'Oligodendroglioma',
      'Oral Cancers',
      'Oral Leukoplakia',
      'Oral Squamous Cell Carcinoma',
      'Oropharyngeal Cancer',
      'Oropharyngeal Carcinoma',
      'Osteosarcoma',
      'Ovarian Adenocarcinoma',
      'Ovarian Carcinoma',
      'Pancreatic Adenocarcinoma',
      'Pancreatic Cancer',
      'Pancreatic Carcinoma',
      'Pancreatic Cyst',
      'Pancreatic Cysts',
      'Pancreatic Endocrine Carcinoma',
      'Pancreatic Neoplasm',
      'Pancreatic Neuroendocrine Tumors',
      'Pancreatic Tumors',
      'Paraganglioma',
      'Parathyroid Tumors',
      'Pelvic Cancer',
      'Penile Cancer',
      'Penile Squamous Cell Carcinoma',
      'Peritoneal Adenocarcinoma',
      'Peritoneal Cancer',
      'Peritoneal Carcinomatosis',
      'Peritoneal Neoplasm',
      'Peritoneal Tumors',
      'Pheochromocytoma',
      'Pilocytic Astrocytoma',
      'Pinealoblastoma',
      'Pituitary Adenomas',
      'Pituitary Tumors',
      'Plantar Fasciitis',
      'Plasmacytoma',
      'Pleomorphic Sarcoma',
      'Polyps',
      'Precancerous Conditions',
      'Primary Central Nervous System Neoplasm',
      'Primary Hyperparathyroidism',
      'Primary Sclerosing Cholangitis',
      'Primitive Neuroectodermal Tumor',
      'Prolymphocytic Leukemia',
      'Prostate Adenocarcinoma',
      'Prostate Cancer',
      'Prostate Carcinoma',
      'Pulmonary Nodules',
      'Radiculopathy',
      'Rectal Cancer',
      'Renal Pelvis Cancer',
      'Retinoblastoma',
      'Rhabdoid Tumor',
      'Rhabdomyosarcoma',
      'Salivary Gland Cancer',
      'Salivary Gland Carcinoma',
      'Sarcoma',
      'Seminoma',
      'Skin Cancer',
      'Small Bowel Cancer',
      'Small Cell Carcinoma',
      'Small Cell Lung Cancer',
      'Small Intestinal Adenocarcinoma',
      'Small Intestinal Cancer',
      'Small Intestine Cancer',
      'Soft Tissue Sarcoma',
      'Soft Tissue Sarcomas',
      'Solid Tumors',
      'Spinal Tumors',
      'Spinocerebellar Ataxias',
      'Squamous Cell Cancer',
      'Squamous Cell Carcinoma',
      'Stomach Cancer',
      'Stomach Tumors',
      'Synovial Sarcoma',
      'Systemic Mastocytosis',
      'Teratoma',
      'Testicular Cancer',
      'Testicular Germ Cell Tumor',
      'Throat Cancer',
      'Thrombocytopenia',
      'Thymic Cancer',
      'Thymic Carcinoma',
      'Thymoma',
      'Thyroid Cancer',
      'Thyroid Carcinoma',
      'Thyroid Gland Carcinoma',
      'Thyroid Nodule',
      'Tongue Cancer',
      'Transitional Cell Carcinoma',
      'Transthyretin Amyloidosis',
      'Uterine Fibroids',
      'Uterine Leiomyosarcoma',
      'Uveal Melanoma',
      'Warts',
      'Yolk Sac Tumor',
    ],
  },
  {
    condition: 'Leukemia',
    subtypes: [
      'Acute Lymphoblastic Leukemia',
      'Acute Myelogenous Leukemia',
      'Acute Myeloid Leukemia',
      'Acute Myelomonocytic Leukemia',
      'Acute Promyelocytic Leukemia',
      'Adult Acute Myeloid Leukemia',
      'Chronic Myeloid Leukemia',
      'Megakaryoblastic Leukemia',
      'Monoblastic Leukemia',
      'Monocytic Leukemia',
      'Myeloid Leukemia',
      'Prolymphocytic Leukemia',
      'T-Cell Lymphoblastic Leukemia',
    ],
  },
  {
    condition: 'Stomach Cancer',
    subtypes: ['Adenocarcinoma', 'Gastric Adenocarcinoma'],
  },
  {
    condition: 'Esophageal Cancer',
    subtypes: ['Adenocarcinoma', 'Squamous Cell Carcinoma'],
  },
  {
    condition: 'Schizophrenia',
    subtypes: [
      'First Episode Psychosis',
      'Schizoaffective Disorder',
      'Schizophreniform Disorder',
    ],
  },
  {
    condition: 'Pain',
    subtypes: [
      'Acute Pain',
      'Back Pain',
      'Cancer Pain',
      'Chronic Pain',
      'Chronic Pelvic Pain Syndrome',
      'Chronic Postsurgical Pain',
      'Facial Pain',
      'Fibromyalgia',
      'Headache',
      'Labor Pain',
      'Menstrual Cramps',
      'Migraine',
      'Muscle Pain',
      'Musculoskeletal Pain',
      'Neck Pain',
      'Neuropathic Pain',
      'Pelvic Pain',
      'Peripheral Neuropathy',
      'Postoperative Pain',
      'Post-Surgical Pain',
      'Procedural Pain',
      'Spinal Cord Injury',
    ],
  },
  {
    condition: 'Human Immunodeficiency Virus Infection',
    subtypes: [
      'Acquired Immunodeficiency Syndrome',
      'AIDS',
      'Encephalopathy',
      'Neuropathy',
    ],
  },
  {
    condition: 'Chronic Kidney Disease',
    subtypes: [
      'Chronic Kidney Failure',
      'Kidney Disease',
      'Kidney Failure',
      'Renal Disease',
    ],
  },
  { condition: 'Pregnancy', subtypes: ['Miscarriage', 'Preterm Labor'] },
  {
    condition: "Hodgkin's Lymphoma",
    subtypes: ['B-Cell Lymphoma', 'Large B-Cell Lymphoma', 'T-Cell Lymphoma'],
  },
  {
    condition: 'Chronic Obstructive Pulmonary Disease',
    subtypes: ['Chronic Bronchitis', 'Emphysema'],
  },
  {
    condition: 'B-Cell Lymphoma',
    subtypes: [
      'Diffuse Large B-Cell Lymphoma',
      'MALT Lymphoma',
      'Marginal Zone Lymphoma',
      'Primary Effusion Lymphomas',
    ],
  },
  {
    condition: 'Epilepsy',
    subtypes: [
      'Dravet Syndrome',
      'Lennox Gastaut Syndrome',
      'Partial Seizures',
      'Temporal Lobe Epilepsy',
    ],
  },
  {
    condition: 'Stress',
    subtypes: [
      'Burnout',
      'Caregiver Burden',
      'Caregiver Burnout',
      'Workplace Stress',
    ],
  },
  {
    condition: 'Sarcoma',
    subtypes: [
      'Alveolar Soft Part Sarcoma',
      'Angiosarcoma',
      'Chondrosarcoma',
      'Desmoplastic Small Round Cell Tumor',
      'Hemangiosarcoma',
      'Liposarcoma',
      'Rhabdomyosarcoma',
      'Soft Tissue Sarcoma',
      'Synovial Sarcoma',
    ],
  },
  {
    condition: 'Kidney Failure',
    subtypes: [
      'Acute Kidney Injury',
      'Chronic Kidney Disease',
      'Chronic Kidney Failure',
    ],
  },
  {
    condition: 'Soft Tissue Sarcoma',
    subtypes: [
      'Alveolar Soft Part Sarcoma',
      'Angiosarcoma',
      'Chondrosarcoma',
      'Desmoplastic Small Round Cell Tumor',
      'Hemangiosarcoma',
      'Liposarcoma',
      'Rhabdomyosarcoma',
      'Synovial Sarcoma',
    ],
  },
  {
    condition: 'Inflammatory Bowel Disease',
    subtypes: ["Crohn's Disease", 'Ulcerative Colitis'],
  },
  {
    condition: 'Skin Cancer',
    subtypes: ['Basal Cell Carcinoma', 'Squamous Cell Carcinoma'],
  },
  {
    condition: 'Malignant Solid Tumor',
    subtypes: [
      'Adenocarcinoma',
      'Adenoid Cystic Carcinoma',
      'Adenoma',
      'Adenomatous Polyposis',
      'Adrenal Cancer',
      'Alveolar Rhabdomyosarcoma',
      'Alveolar Soft Part Sarcoma',
      'Ampullary Cancer',
      'Anal Canal Carcinoma',
      'Anal Squamous Cell Carcinoma',
      'Angiosarcoma',
      'Astrocytoma',
      'Atypical Teratoid/Rhabdoid Tumor',
      "Barrett's Esophagus",
      'Basal Cell Carcinoma',
      'Bile Duct Cancer',
      'Biliary Cancers',
      'Biliary Tract Cancer',
      'Bone Cancer',
      'Bone Tumors',
      'Brain Cancer',
      'Breast Cancer',
      'Breast Carcinoma',
      'Bronchial Tumors',
      'Carcinoid Tumor',
      'Carcinoma',
      'Carcinosarcoma',
      'Central Nervous System Cancer',
      'Central Nervous System Tumor',
      'Cervical Adenocarcinoma',
      'Cervical Carcinoma',
      'Childhood Brain Tumors',
      'Childhood Cancer',
      'Cholangiocarcinoma',
      'Chondrosarcoma',
      'Chordoma',
      'Clear Cell Carcinoma',
      'Clear Cell Sarcoma of Soft Tissue',
      'Colon Adenomas',
      'Colon Cancer',
      'Colorectal Adenomas',
      'Colorectal Cancer',
      'Colorectal Tumor',
      'Craniopharyngioma',
      'Desmoid Tumor',
      'Desmoplastic Small Round Cell Tumor',
      'Embryonal Tumor',
      'Endocrine Tumors',
      'Endometrial Adenocarcinoma',
      'Ependymoma',
      'Epithelioid Sarcoma',
      'Esophageal Adenocarcinoma',
      'Esophageal Cancer',
      'Esophageal Carcinoma',
      'Esthesioneuroblastoma',
      'Ewing Sarcoma',
      'Fallopian Tube Adenocarcinoma',
      'Fallopian Tube Carcinoma',
      'Fallopian Tube Serous Adenocarcinoma',
      'Fibroid',
      'Gallbladder Cancer',
      'Ganglioglioma',
      'Ganglioneuroblastoma',
      'Gastric Adenocarcinoma',
      'Gastric Cancer',
      'Gastric Neoplasm',
      'Gastroesophageal Junction Adenocarcinoma',
      'Gastroesophageal Junction Cancer',
      'Gastrointestinal Cancer',
      'Gastrointestinal Stromal Tumor',
      'Gastrointestinal Stromal Tumors',
      'Gastrointestinal Tumors',
      'Genital Neoplasms',
      'Genitourinary Cancers',
      'Germ Cell Tumors',
      'Giant Cell Tumor',
      'Glioblastoma',
      'Gliomas',
      'Gliosarcoma',
      'Head and Neck Cancers',
      'Head and Neck Neoplasms',
      'Head and Neck Squamous Cell Carcinoma',
      'Hemangiosarcoma',
      'Hepatoblastoma',
      'Hepatocellular Carcinoma',
      'Hypopharyngeal Cancer',
      'Intrahepatic Cholangiocarcinoma',
      'Kaposi Sarcoma',
      "Kaposi's Sarcoma",
      'Laryngeal Cancer',
      'Laryngeal Squamous Cell Carcinoma',
      'Leiomyosarcoma',
      'Lip and Oral Cavity Cancer',
      'Liposarcoma',
      'Liver Cancer',
      'Liver Metastases',
      'Liver Metastasis',
      'Liver Tumors',
      'Low Grade Glioma',
      'Lung Adenocarcinoma',
      'Lung Cancer',
      'Lung Carcinoma',
      'Lymphoma',
      'Major Salivary Gland Cancer',
      'Malignant Glioma',
      'Malignant Mesothelioma',
      'Malignant Pleural Effusion',
      'Malignant Pleural Mesothelioma',
      'Malignant Rhabdoid Tumor',
      'Medulloblastoma',
      'Melanoma',
      'Meningioma',
      'Merkel Cell Carcinoma',
      'Mesothelioma',
      'Metastases',
      'Monoblastic Leukemia',
      'Monocytic Leukemia',
      'Nasopharyngeal Cancer',
      'Nasopharyngeal Carcinoma',
      'Neck Cancer',
      'Neoplasm',
      'Neoplasms',
      'Neuroblastoma',
      'Neuroectodermal Tumors',
      'Neuroendocrine Carcinoma',
      'Neuroendocrine Tumors',
      'Non-Small Cell Carcinoma',
      'Non-Small Cell Lung Cancer',
      'Oligoastrocytoma',
      'Oligodendroglioma',
      'Oral Cancers',
      'Oral Leukoplakia',
      'Oral Squamous Cell Carcinoma',
      'Oropharyngeal Cancer',
      'Oropharyngeal Carcinoma',
      'Osteosarcoma',
      'Ovarian Adenocarcinoma',
      'Ovarian Carcinoma',
      'Pancreatic Adenocarcinoma',
      'Pancreatic Cancer',
      'Pancreatic Carcinoma',
      'Pancreatic Cyst',
      'Pancreatic Cysts',
      'Pancreatic Endocrine Carcinoma',
      'Pancreatic Neoplasm',
      'Pancreatic Neuroendocrine Tumors',
      'Pancreatic Tumors',
      'Paraganglioma',
      'Parathyroid Tumors',
      'Pelvic Cancer',
      'Penile Cancer',
      'Penile Squamous Cell Carcinoma',
      'Peritoneal Adenocarcinoma',
      'Peritoneal Cancer',
      'Peritoneal Carcinomatosis',
      'Peritoneal Neoplasm',
      'Peritoneal Tumors',
      'Pheochromocytoma',
      'Pilocytic Astrocytoma',
      'Pinealoblastoma',
      'Pituitary Adenomas',
      'Pituitary Tumors',
      'Plasmacytoma',
      'Pleomorphic Sarcoma',
      'Polyps',
      'Precancerous Conditions',
      'Primary Central Nervous System Neoplasm',
      'Primary Hyperparathyroidism',
      'Primary Sclerosing Cholangitis',
      'Primitive Neuroectodermal Tumor',
      'Prolymphocytic Leukemia',
      'Prostate Adenocarcinoma',
      'Prostate Cancer',
      'Prostate Carcinoma',
      'Pulmonary Nodules',
      'Radiculopathy',
      'Rectal Cancer',
      'Renal Pelvis Cancer',
      'Retinoblastoma',
      'Rhabdoid Tumor',
      'Rhabdomyosarcoma',
      'Salivary Gland Cancer',
      'Salivary Gland Carcinoma',
      'Sarcoma',
      'Seminoma',
      'Skin Cancer',
      'Small Bowel Cancer',
      'Small Cell Carcinoma',
      'Small Cell Lung Cancer',
      'Small Intestinal Adenocarcinoma',
      'Small Intestinal Cancer',
      'Small Intestine Cancer',
      'Soft Tissue Sarcoma',
      'Soft Tissue Sarcomas',
      'Solid Tumors',
      'Spinal Tumors',
      'Spinocerebellar Ataxias',
      'Squamous Cell Cancer',
      'Squamous Cell Carcinoma',
      'Stomach Cancer',
      'Stomach Tumors',
      'Synovial Sarcoma',
      'Systemic Mastocytosis',
      'Testicular Cancer',
      'Testicular Germ Cell Tumor',
      'Throat Cancer',
      'Thrombocytopenia',
      'Thymic Cancer',
      'Thymic Carcinoma',
      'Thymoma',
      'Thyroid Cancer',
      'Thyroid Carcinoma',
      'Thyroid Gland Carcinoma',
      'Thyroid Nodule',
      'Tongue Cancer',
      'Transitional Cell Carcinoma',
      'Transthyretin Amyloidosis',
      'Tumors',
      'Uterine Fibroids',
      'Uterine Leiomyosarcoma',
      'Uveal Melanoma',
      'Warts',
      'Yolk Sac Tumor',
    ],
  },
  {
    condition: 'Thyroid Cancer',
    subtypes: [
      'Thyroid Carcinoma',
      'Thyroid Gland Carcinoma',
      'Thyroid Nodule',
    ],
  },
  {
    condition: 'Inflammation',
    subtypes: [
      'Cellulitis',
      'Central Line Bloodstream Infection',
      'Neonatal Sepsis',
      'Osteomyelitis',
      'Sepsis',
      'Septic Shock',
    ],
  },
  {
    condition: 'Attention Deficit Hyperactivity Disorder (ADHD)',
    subtypes: ['Attention Deficit Disorder'],
  },
  {
    condition: 'Substance Abuse',
    subtypes: [
      'Alcohol Abuse',
      'Alcohol Intoxication',
      'Alcoholism',
      'Alcohol Use Disorder',
      'Amphetamine Use Disorder',
      'Cannabis Abuse',
      'Cannabis Dependence',
      'Cannabis Use',
      'Cannabis Use Disorder',
      'Cocaine Dependence',
      'Cocaine Use Disorder',
      'Nicotine Addiction',
      'Opioid Abuse',
      'Opioid Addiction',
      'Opioid Use Disorder',
      'Problem Drinking',
      'Substance Addiction',
      'Substance Use Disorder',
      'Tobacco Addiction',
      'Tobacco Use Disorder',
    ],
  },
  {
    condition: 'Substance Use Disorder',
    subtypes: [
      'Alcohol Use Disorder',
      'Amphetamine Use Disorder',
      'Cannabis Use Disorder',
      'Cocaine Use Disorder',
      'Opioid Use Disorder',
      'Substance Abuse',
      'Substance Addiction',
      'Tobacco Use Disorder',
    ],
  },
  {
    condition: 'Suicide',
    subtypes: ['Suicidal Ideation', 'Suicidal Thoughts'],
  },
  {
    condition: 'Obsessive-Compulsive Disorder',
    subtypes: ['Hoarding Disorder'],
  },
  {
    condition: 'Prostate Adenocarcinoma',
    subtypes: ['Adenocarcinoma', 'Prostate Carcinoma'],
  },
  { condition: 'Bile Duct Cancer', subtypes: ['Cholangiocarcinoma'] },
  {
    condition: 'Acute Leukemia',
    subtypes: [
      'Acute Lymphoblastic Leukemia',
      'Acute Myelogenous Leukemia',
      'Acute Myeloid Leukemia',
      'Acute Myelomonocytic Leukemia',
      'Acute Promyelocytic Leukemia',
      'Adult Acute Myeloid Leukemia',
      'Megakaryoblastic Leukemia',
      'Monoblastic Leukemia',
      'Monocytic Leukemia',
      'Prolymphocytic Leukemia',
      'T-Cell Lymphoblastic Leukemia',
    ],
  },
  {
    condition: 'Liver Disease',
    subtypes: [
      'Autoimmune Hepatitis',
      'Chronic Hepatitis B',
      'Chronic Hepatitis C',
      'Cirrhosis',
      'Fatty Liver',
      'Fatty Liver Disease',
      'Non-alcoholic Fatty Liver Disease',
      'Nonalcoholic Steatohepatitis',
      'Portal Hypertension',
    ],
  },
  {
    condition: 'Pancreatic Adenocarcinoma',
    subtypes: [
      'Adenocarcinoma',
      'Pancreatic Carcinoma',
      'Pancreatic Endocrine Carcinoma',
      'Pancreatic Neuroendocrine Tumors',
    ],
  },
  {
    condition: 'Gastroesophageal Junction Adenocarcinoma',
    subtypes: ['Gastric Adenocarcinoma'],
  },
  {
    condition: 'Acute Myelogenous Leukemia',
    subtypes: [
      'Acute Promyelocytic Leukemia',
      'Megakaryoblastic Leukemia',
      'Monoblastic Leukemia',
      'Monocytic Leukemia',
    ],
  },
  {
    condition: 'Gastrointestinal Cancer',
    subtypes: [
      'Adenomatous Polyposis',
      'Anal Cancer',
      'Appendiceal Cancer',
      "Barrett's Esophagus",
      'Colon Cancer',
      'Colorectal Cancer',
      'Colorectal Cancer Screening',
      'Colorectal Tumor',
      'Esophageal Cancer',
      'Esophageal Neoplasms',
      'Gastric Cancer',
      'Gastric Neoplasm',
      'Rectal Cancer',
      'Stomach Cancer',
      'Stomach Tumors',
    ],
  },
  {
    condition: 'Kidney Disease',
    subtypes: [
      'Acute Kidney Injury',
      'Chronic Kidney Disease',
      'Chronic Kidney Failure',
      'Cystinuria',
      'Diabetic Kidney Disease',
      'Focal Segmental Glomerulosclerosis',
      'Glomerulonephritis',
      'Henoch-Schonlein Purpura',
      'IgA Nephropathy',
      'Immunoglobulin A Nephropathy',
      'Light Chain Deposition Disease',
      'Lupus Nephritis',
      'Membranous Glomerulonephritis',
      'Nephrotic Syndrome',
      'Polycystic Kidney Disease',
      'Primary Hyperoxaluria',
      'Thrombotic Microangiopathy',
    ],
  },
  {
    condition: 'Insulin Resistance',
    subtypes: ['Cardiometabolic Syndrome', 'Metabolic Syndrome'],
  },
  {
    condition: 'Hearing Loss',
    subtypes: [
      'Age-Related Hearing Loss',
      'Bilateral Hearing Loss',
      'Sensorineural Hearing Loss',
      'Unilateral Hearing Loss',
    ],
  },
  {
    condition: 'Bone Marrow Transplant',
    subtypes: ['Bone Marrow Transplant Recipient'],
  },
  {
    condition: 'Congenital Heart Disease',
    subtypes: [
      'Atrial Septal Defect',
      'Bicuspid Aortic Valve',
      'Hypoplastic Left Heart Syndrome',
      'Patent Ductus Arteriosus',
      'Patent Foramen Ovale',
      'Single Ventricle Heart',
      'Tetralogy of Fallot',
      'Ventricular Septal Defects',
    ],
  },
  {
    condition: 'Peritoneal Cancer',
    subtypes: [
      'Peritoneal Adenocarcinoma',
      'Peritoneal Carcinomatosis',
      'Peritoneal Neoplasm',
      'Peritoneal Tumors',
    ],
  },
  {
    condition: 'Alcohol Consumption',
    subtypes: [
      'Adolescent Alcohol Use',
      'Binge Drinking',
      'Excessive Alcohol Consumption',
      'Underage Drinking',
    ],
  },
  {
    condition: 'Malignant Glioma',
    subtypes: [
      'Anaplastic Astrocytoma',
      'Astrocytoma',
      'Diffuse Astrocytoma',
      'Gliosarcoma',
      'Pilocytic Astrocytoma',
    ],
  },
  {
    condition: 'Lung Disease',
    subtypes: [
      'Acute Lung Injury',
      'Acute Respiratory Distress Syndrome',
      'Acute Respiratory Failure',
      'Bronchiolitis',
      'Bronchiolitis Obliterans',
      'Bronchiolitis Obliterans Syndrome',
      'Bronchitis',
      'Bronchopulmonary Dysplasia',
      'Collapsed Lung',
      'Idiopathic Pulmonary Fibrosis',
      'Interstitial Lung Disease',
      'Langerhans Cell Histiocytosis',
      'Pulmonary Arterial Hypertension',
      'Pulmonary Fibrosis',
      'Pulmonary Hypertension',
      'Respiratory Distress Syndrome',
      'Ventilator-Induced Lung Injury',
    ],
  },
  {
    condition: 'Anxiety Disorders',
    subtypes: [
      'Agoraphobia',
      'Obsessive-Compulsive Disorder',
      'Panic Disorder',
      'Social Anxiety Disorder',
    ],
  },
  {
    condition: 'Arthritis',
    subtypes: [
      'Ankylosing Spondylitis',
      'Juvenile Idiopathic Arthritis',
      'Osteoarthritis',
      'Psoriatic Arthritis',
      'Rheumatoid Arthritis',
      'Septic Arthritis',
    ],
  },
  { condition: 'Neurofibromatosis', subtypes: ['Neurofibroma'] },
  {
    condition: 'Oropharyngeal Carcinoma',
    subtypes: [
      'Head and Neck Squamous Cell Carcinoma',
      'Oral Squamous Cell Carcinoma',
    ],
  },
  {
    condition: 'Sepsis',
    subtypes: ['Bacterial Blood Infection', 'Neonatal Sepsis', 'Septic Shock'],
  },
  { condition: 'Deep Vein Thrombosis', subtypes: ['Post-Thrombotic Syndrome'] },
  {
    condition: 'Heart Disease',
    subtypes: [
      'Acute Coronary Syndrome',
      'Angina',
      'Aortic Regurgitation',
      'Aortic Stenosis',
      'Aortic Valve Disease',
      'Aortic Valve Stenosis',
      'Atrial Fibrillation',
      'Cardiac Arrest',
      'Cardiomyopathy',
      'Chronic Heart Failure',
      'Congestive Heart Failure',
      'Dilated Cardiomyopathy',
      'Heart Failure',
      'Heart Valve Disease',
      'Hypertrophic Cardiomyopathy',
      'Mitral Regurgitation',
      'Mitral Valve Disease',
      'Mitral Valve Regurgitation',
      'Myocarditis',
      'Pulmonary Valve Regurgitation',
      'Right Ventricular Dysfunction',
      'Takotsubo Syndrome',
      'Transthyretin Amyloid Cardiomyopathy',
      'Valvular Heart Disease',
      'Ventricular Arrhythmia',
      'Ventricular Tachycardia',
    ],
  },
  {
    condition: 'Interstitial Lung Disease',
    subtypes: ['Idiopathic Pulmonary Fibrosis', 'Pulmonary Fibrosis'],
  },
  { condition: 'Rectal Cancer', subtypes: ['Colorectal Cancer'] },
  {
    condition: 'Peripheral Neuropathy',
    subtypes: [
      'Amyloidosis',
      'Brachial Plexus Injury',
      'Carpal Tunnel Syndrome',
      'Cervical Radiculopathy',
      'Charcot-Marie-Tooth Disease',
      'Chronic Inflammatory Demyelinating Polyradiculoneuropathy',
      'Cubital Tunnel Syndrome',
      'Diabetic Neuropathy',
      'Foot Drop',
      "Morton's Neuroma",
      'Neurofibroma',
      'Peripheral Nerve Injury',
      'Peripheral Nerve Sheath Tumor',
      'Plexiform Neurofibroma',
      'Radiculopathy',
      'Spastic Paraplegia',
      'Thoracic Outlet Syndrome',
    ],
  },
  {
    condition: 'High Cholesterol',
    subtypes: ['Hypercholesterolemia', 'Hypertriglyceridemia'],
  },
  {
    condition: 'Pneumonia',
    subtypes: ['Bacterial Pneumonia', 'Viral Pneumonia'],
  },
  {
    condition: 'Eating Disorders',
    subtypes: ['Anorexia Nervosa', 'Binge Eating Disorder', 'Bulimia'],
  },
  {
    condition: 'Anesthesia',
    subtypes: ['General Anesthesia', 'Local Anesthesia'],
  },
  { condition: 'Sleep Apnea', subtypes: ['Central Sleep Apnea'] },
  {
    condition: 'Gastroparesis',
    subtypes: [
      'Diabetes',
      'Diabetes Mellitus',
      'Diabetic Neuropathy',
      'Diabetic Retinopathy',
      'Gestational Diabetes',
      'Prediabetes',
      'Type 1 Diabetes',
      'Type 2 Diabetes',
    ],
  },
  {
    condition: 'Brain Cancer',
    subtypes: [
      'Diffuse Intrinsic Pontine Glioma',
      'Neoplasms',
      'Pituitary Adenomas',
      'Pituitary Tumors',
    ],
  },
  {
    condition: 'Infections',
    subtypes: [
      'Bacterial Infection',
      'Clostridioides difficile Infection',
      'Coronavirus Infection',
      'Fungal Infection',
      'Gonorrhea',
      'HIV Infection',
      'Lyme Disease',
      'Malaria',
      'Nontuberculous Mycobacterial Infections',
      'Pneumonia',
      'Sexually Transmitted Diseases',
      'Syphilis',
      'Tuberculosis',
      'Viral Infections',
    ],
  },
  {
    condition: 'Sleep Disorders',
    subtypes: [
      'Central Sleep Apnea',
      'Excessive Daytime Sleepiness',
      'Idiopathic Hypersomnia',
      'Insomnia',
      'Narcolepsy',
      'Obstructive Sleep Apnea',
      'Restless Legs Syndrome',
      'Sleep Apnea',
    ],
  },
  { condition: 'Brain Metastasis', subtypes: ['Solid Tumors'] },
  { condition: 'Cardiomyopathy', subtypes: ['Dilated Cardiomyopathy'] },
  {
    condition: 'Chronic Myelogenous Leukemia',
    subtypes: ['Chronic Myeloid Leukemia'],
  },
  {
    condition: 'Pediatric Cancer',
    subtypes: [
      'Acute Leukemia',
      'Acute Lymphoblastic Leukemia',
      'Acute Myelogenous Leukemia',
      'Ewing Sarcoma',
      'Germ Cell Tumors',
      'Hepatoblastoma',
      'Medulloblastoma',
      'Neuroblastoma',
      'Osteosarcoma',
      'Retinoblastoma',
      'Rhabdomyosarcoma',
    ],
  },
  {
    condition: 'Anemia',
    subtypes: [
      'Alpha-Thalassemia',
      'Autoimmune Hemolytic Anemia',
      'Beta Thalassemia',
      'Hemolytic Anemia',
      'Iron-Deficiency Anemia',
      'Iron Overload',
      'Myelodysplastic Syndrome',
      'Paroxysmal Nocturnal Hemoglobinuria',
      'Sickle Cell Anemia',
      'Sickle Cell Crisis',
      'Sickle Cell Disease',
      'Sickle Cell Thalassemia',
      'Thalassemia',
    ],
  },
  {
    condition: 'Health Behaviors',
    subtypes: [
      'Patient Adherence',
      'Quitting Smoking',
      'Smoking Cessation',
      'Tobacco Cessation',
      'Treatment Compliance',
    ],
  },
  {
    condition: 'T-Cell Lymphoma',
    subtypes: [
      'Anaplastic Large Cell Lymphoma',
      'Cutaneous T-Cell Lymphoma',
      'Peripheral T-Cell Lymphoma',
      'Sezary Syndrome',
    ],
  },
  {
    condition: 'Oral Cancers',
    subtypes: [
      'Adenocarcinoma',
      'Adenoid Cystic Carcinoma',
      'Oral Squamous Cell Carcinoma',
      'Squamous Cell Carcinoma',
      'Tongue Cancer',
    ],
  },
  {
    condition: 'Germ Cell Tumors',
    subtypes: ['Seminoma', 'Testicular Germ Cell Tumor', 'Yolk Sac Tumor'],
  },
  {
    condition: 'Atherosclerosis',
    subtypes: ['Peripheral Arterial Disease', 'Peripheral Artery Disease'],
  },
  {
    condition: 'Urinary Incontinence',
    subtypes: ['Urge Incontinence', 'Urge Urinary Incontinence'],
  },
  {
    condition: 'Bleeding',
    subtypes: [
      'Gastrointestinal Bleeding',
      'Hematoma',
      'Hemorrhage',
      'Postoperative Hemorrhage',
      'Postpartum Hemorrhage',
      'Upper Gastrointestinal Bleeding',
    ],
  },
  {
    condition: 'Ovarian Tumors',
    subtypes: ['Endometrioid Adenocarcinoma', 'Ovarian Cancer'],
  },
  {
    condition: 'Bariatric Surgery Candidates',
    subtypes: ['Sleeve Gastrectomy'],
  },
  {
    condition: 'Metabolic Diseases',
    subtypes: [
      'AL Amyloidosis',
      'Amyloidosis',
      'Congenital Hyperinsulinism',
      'Diabetes',
      'Diabetes Mellitus',
      'Diabetic Macular Edema',
      'Diabetic Neuropathy',
      'Diabetic Retinopathy',
      'Glycogen Storage Disease',
      'Gout',
      'Hypercholesterolemia',
      'Hyperinsulinemia',
      'Hyperkalemia',
      'Hypernatremia',
      'Hypertriglyceridemia',
      'Hypoglycemia Unawareness',
      'Hypophosphatasia',
      'Inborn Errors of Metabolism',
      'Insulin Resistance',
      'Insulin Sensitivity',
      'Iron Overload',
      'Ketosis',
      'Lipid Disorders',
      'Lipodystrophy',
      'Metabolic Syndrome',
      'Mitochondrial Disease',
      'Non-alcoholic Fatty Liver Disease',
      'Pompe Disease',
      'Prediabetes',
      'Primary Hyperparathyroidism',
      'Propionic Acidemia',
      'Pseudohypoparathyroidism',
      'Transthyretin Amyloid Cardiomyopathy',
      'Type 1 Diabetes',
      'Type 2 Diabetes',
    ],
  },
  {
    condition: 'Low Grade Glioma',
    subtypes: [
      'Anaplastic Astrocytoma',
      'Astrocytoma',
      'Diffuse Astrocytoma',
      'Ganglioglioma',
      'Pilocytic Astrocytoma',
      'Pituitary Adenomas',
    ],
  },
  { condition: 'Amyloidosis', subtypes: ['AL Amyloidosis'] },
  {
    condition: 'Vascular Disease',
    subtypes: [
      'Abdominal Aortic Aneurysm',
      'Aortic Aneurysm',
      'Aortic Disease',
      'Aortic Dissection',
      'Arterial Occlusive Disease',
      'Atherosclerosis',
      'Fibromuscular Dysplasia',
      'Giant Cell Arteritis',
      'Peripheral Arterial Disease',
      'Peripheral Vascular Disease',
      'Polyarteritis Nodosa',
      'Takayasu Arteritis',
      'Thoracic Aortic Aneurysm',
      'Thoracoabdominal Aortic Aneurysms',
      'Vasculitis',
    ],
  },
  {
    condition: 'Arrhythmia',
    subtypes: ['Atrial Flutter', 'Heart Block', 'Supraventricular Tachycardia'],
  },
  {
    condition: 'Alcohol Use Disorder',
    subtypes: [
      'Alcohol Abuse',
      'Alcoholism',
      'Binge Drinking',
      'Problem Drinking',
    ],
  },
  {
    condition: 'Fallopian Tube Carcinoma',
    subtypes: [
      'Fallopian Tube Adenocarcinoma',
      'Fallopian Tube Serous Adenocarcinoma',
    ],
  },
  { condition: 'Avascular Necrosis', subtypes: ['Osteonecrosis'] },
  { condition: 'Surgical Site Infection', subtypes: ['Cesarean Section'] },
  { condition: 'Biliary Tract Cancer', subtypes: ['Bile Duct Cancer'] },
  { condition: 'Sickle Cell Anemia', subtypes: ['Sickle Cell Disease'] },
  {
    condition: 'Chronic Myeloid Leukemia',
    subtypes: ['Chronic Myelogenous Leukemia'],
  },
  {
    condition: 'Gliomas',
    subtypes: [
      'Anaplastic Astrocytoma',
      'Astrocytoma',
      'Diffuse Astrocytoma',
      'Ganglioglioma',
      'Gliosarcoma',
      'Pilocytic Astrocytoma',
    ],
  },
  {
    condition: 'Adenocarcinoma',
    subtypes: [
      'Clear Cell Carcinoma',
      'Ductal Carcinoma',
      'Intrahepatic Cholangiocarcinoma',
      'Pancreatic Carcinoma',
      'Prostate Carcinoma',
      'Thyroid Carcinoma',
      'Thyroid Gland Carcinoma',
    ],
  },
  {
    condition: 'Genetic Disorder',
    subtypes: [
      'Angelman Syndrome',
      'Batten Disease',
      'Cowden Syndrome',
      'Glycogen Storage Disease',
      'Hemophilia A',
      'Hunter Syndrome',
      'Hurler Syndrome',
      'Lynch Syndrome',
      'Multiple Endocrine Neoplasia',
      'Phenylketonuria',
      'Sanfilippo Syndrome',
      'Severe Combined Immunodeficiency',
    ],
  },
  {
    condition: 'Pregnancy Complications',
    subtypes: [
      'Abnormal Uterine Bleeding',
      'Cardiovascular Complications',
      'Fetal Alcohol Spectrum Disorders',
      'Intrauterine Growth Restriction',
      'Labor Complications',
      'Miscarriage',
      'Neonatal Abstinence Syndrome',
      'Obstetric Labor Complications',
      'Pelvic Floor Dysfunction',
      'Postpartum Depression',
      'Pre-eclampsia',
      'Premature Birth',
      'Premature Infants',
      'Premature Labor',
      'Premature Rupture of Membranes',
      'Prenatal Disorder',
      'Preterm Birth',
      'Preterm Labor',
      'Prophylaxis of Preeclampsia',
      'Respiratory Distress Syndrome',
      'Sepsis',
    ],
  },
  { condition: 'Esophageal Carcinoma', subtypes: ['Urothelial Carcinoma'] },
  {
    condition: 'Menopause',
    subtypes: ['Hot Flashes', 'Insomnia', 'Osteoporosis', 'Postmenopausal'],
  },
  {
    condition: 'Carcinoma',
    subtypes: [
      'Adenocarcinoma',
      'Adenoid Cystic Carcinoma',
      'Adrenocortical Cancer',
      'Anal Canal Carcinoma',
      'Basal Cell Carcinoma',
      'Bile Duct Cancer',
      'Breast Cancer',
      'Breast Carcinoma',
      'Cervical Adenocarcinoma',
      'Cholangiocarcinoma',
      'Clear Cell Carcinoma',
      'Ductal Carcinoma',
      'Endometrial Adenocarcinoma',
      'Esophageal Adenocarcinoma',
      'Fallopian Tube Adenocarcinoma',
      'Gastric Adenocarcinoma',
      'Gastroesophageal Junction Adenocarcinoma',
      'Head and Neck Squamous Cell Carcinoma',
      'Hepatocellular Carcinoma',
      'Intrahepatic Cholangiocarcinoma',
      'Laryngeal Squamous Cell Carcinoma',
      'Lung Cancer',
      'Lung Carcinoma',
      'Neuroendocrine Carcinoma',
      'Oral Squamous Cell Carcinoma',
      'Oropharyngeal Carcinoma',
      'Ovarian Adenocarcinoma',
      'Pancreatic Adenocarcinoma',
      'Pancreatic Carcinoma',
      'Pancreatic Endocrine Carcinoma',
      'Peritoneal Adenocarcinoma',
      'Prostate Adenocarcinoma',
      'Prostate Carcinoma',
      'Salivary Gland Carcinoma',
      'Small Cell Carcinoma',
      'Squamous Cell Carcinoma',
      'Stomach Cancer',
      'Thyroid Cancer',
      'Thyroid Carcinoma',
      'Thyroid Gland Carcinoma',
      'Transitional Cell Carcinoma',
    ],
  },
  {
    condition: 'Neurological Disorders',
    subtypes: [
      'Acquired Brain Injury',
      'Alzheimer Disease',
      "Alzheimer's Dementia",
      "Alzheimer's Disease",
      'Autonomic Failure',
      'Autonomic Nervous System Disorders',
      'Brain Diseases',
      'Brain Stem Stroke',
      'Cerebral Aneurysm',
      'Cerebral Ischemia',
      'Cerebral Palsy',
      'Central Nervous System Disorders',
      'Central Sleep Apnea',
      'Cervical Radiculopathy',
      'Charcot-Marie-Tooth Disease',
      'Chronic Inflammatory Demyelinating Polyradiculoneuropathy',
      'Coma',
      'Concussion',
      'Corticobasal Degeneration',
      'Dementia',
      'Dementia Caregivers',
      'Down Syndrome',
      'Dravet Syndrome',
      'Dysautonomia',
      'Encephalopathy',
      'Epilepsy',
      'Essential Tremor',
      'Friedreich Ataxia',
      'Headache',
      'Hemiparesis',
      'Hemorrhagic Stroke',
      "Huntington's Disease",
      'Hydrocephalus',
      'Hypoxic-Ischemic Brain Injury',
      'Hypoxic-Ischemic Encephalopathy',
      'Inborn Errors of Metabolism',
      'Intracranial Aneurysms',
      'Intracranial Hemorrhage',
      'Ischemic Optic Neuropathy',
      'Ischemic Stroke',
      'Krabbe Disease',
      'Leber Optic Neuropathy',
      'Lewy Body Dementia',
      'Lewy Body Disease',
      'Limb-Girdle Muscular Dystrophy',
      "Lou Gehrig's Disease",
      'Metachromatic Leukodystrophy',
      'Mild Cognitive Impairment',
      'Mini Stroke',
      'Mitochondrial Myopathy',
      'Motor Neuron Disease',
      'Movement Disorders',
      'Multiple Sclerosis',
      'Multiple System Atrophy',
      'Muscular Dystrophy',
      'Myasthenia Gravis',
      'Myositis',
      'Myotonic Dystrophy',
      'Neurodegeneration',
      'Neurodegenerative Disease',
      'Neurofibromatosis',
      'Neuromuscular Disease',
      'Neuromyelitis Optica (NMO)',
      'Neuromyelitis Optica Spectrum Disorder',
      'Neuromyelitis Optica Spectrum Disorders',
      'Neuropathy',
      'Normal Pressure Hydrocephalus',
      'Obsessive-Compulsive Disorder',
      'Optic Neuritis',
      'Optic Neuropathy',
      "Parkinson's Disease",
      'Pelvic Floor Dysfunction',
      'Peripheral Nerve Injury',
      'Peripheral Neuropathy',
      'Polymyositis',
      'Post-Concussion Syndrome',
      'Post-Traumatic Headache',
      'Post-Traumatic Stress Disorder',
      'Primary Lateral Sclerosis',
      'Progressive Multifocal Leukoencephalopathy',
      'Pseudotumor Cerebri',
      'Radiculopathy',
      'Rehabilitation',
      'Rett Syndrome',
      'Schizophrenia',
      'Seizures',
      'Sleep Apnea',
      'Sleep Disorders',
      'Spasticity',
      'Spinal Cord Disease',
      'Spinal Cord Injury',
      'Spinal Muscular Atrophy',
      'Spinocerebellar Ataxias',
      'Stroke',
      'Subarachnoid Hemorrhage',
      'Subdural Hematoma',
      'Temporal Lobe Epilepsy',
      'Tic Disorders',
      'Tourette Syndrome',
      'Transverse Myelitis',
      'Traumatic Brain Injury',
      'Trigeminal Neuralgia',
      'Usher Syndrome',
      'Vascular Dementia',
      'White Matter Disease',
      "Wilson's Disease",
    ],
  },
  { condition: 'Endometrial Adenocarcinoma', subtypes: ['Adenocarcinoma'] },
  {
    condition: 'Neurocognitive Disorder',
    subtypes: [
      'Dementia',
      'Mild Cognitive Impairment',
      'Postoperative Cognitive Dysfunction',
      'Vascular Cognitive Impairment',
    ],
  },
  { condition: 'Ductal Carcinoma', subtypes: ['Breast Cancer'] },
  {
    condition: 'Autoimmune Diseases',
    subtypes: [
      'Antiphospholipid Syndrome',
      'Multiple Sclerosis',
      'Myasthenia Gravis',
      'Neuromyelitis Optica (NMO)',
      'Neuromyelitis Optica Spectrum Disorder',
      'Transverse Myelitis',
    ],
  },
  {
    condition: 'Neurodegenerative Disease',
    subtypes: ['Corticobasal Degeneration', 'Motor Neuron Disease'],
  },
  {
    condition: 'Swallowing Difficulty',
    subtypes: ['Achalasia', 'Gastroesophageal Reflux Disease', 'Hiatal Hernia'],
  },
  { condition: 'Seizures', subtypes: ['Epilepsy'] },
  {
    condition: 'Mood Disorders',
    subtypes: [
      'Bipolar Disorder',
      'Major Depressive Disorder',
      'Major Depressive Episode',
      'Persistent Depressive Disorder',
    ],
  },
  {
    condition: 'Pre-eclampsia',
    subtypes: ['High Blood Pressure', 'Hypertension', 'Pulmonary Hypertension'],
  },
  {
    condition: 'Respiratory Diseases',
    subtypes: [
      'Acute Lung Injury',
      'Acute Respiratory Distress Syndrome',
      'Acute Respiratory Failure',
      'Bronchiectasis',
      'Bronchiolitis',
      'Bronchiolitis Obliterans',
      'Bronchiolitis Obliterans Syndrome',
      'Bronchitis',
      'Chronic Rhinitis',
      'Chronic Rhinosinusitis',
      'Chronic Sinusitis',
      'Collapsed Lung',
      'Hypoxemic Respiratory Failure',
      'Idiopathic Pulmonary Fibrosis',
      'Interstitial Lung Disease',
      'Langerhans Cell Histiocytosis',
      'Laryngitis',
      'Lung Disease',
      'Pleural Effusion',
      'Pneumonia',
      'Pulmonary Arterial Hypertension',
      'Pulmonary Fibrosis',
      'Pulmonary Hypertension',
      'Respiratory Distress Syndrome',
      'Respiratory Failure',
      'Respiratory Infection',
      'Respiratory Infections',
      'Respiratory Insufficiency',
      'Sinus Infection',
      'Sinusitis',
      'Solitary Pulmonary Nodule',
      'Spasmodic Dysphonia',
      'Thoracic Diseases',
      'Ventilator-Induced Lung Injury',
      'Viral Pneumonia',
      'Viral Respiratory Infection',
    ],
  },
  {
    condition: 'Nervous System Disorders',
    subtypes: [
      'Acquired Brain Injury',
      'Autonomic Nervous System Disorders',
      'Central Nervous System Disorders',
      'Dementia',
      'Encephalopathy',
      'Epilepsy',
      "Huntington's Disease",
      'Intracranial Aneurysms',
      'Intracranial Hemorrhage',
      'Ischemic Optic Neuropathy',
      'Ischemic Stroke',
      'Lewy Body Dementia',
      'Migraine',
      'Mild Cognitive Impairment',
      'Mitochondrial Myopathy',
      'Motor Neuron Disease',
      'Movement Disorders',
      'Multiple Sclerosis',
      'Muscular Dystrophy',
      'Myasthenia Gravis',
      'Myositis',
      'Neuromuscular Disease',
      'Neuropathy',
      'Normal Pressure Hydrocephalus',
      'Optic Neuritis',
      'Optic Neuropathy',
      "Parkinson's Disease",
      'Peripheral Neuropathy',
      'Polymyositis',
      'Post-Concussion Syndrome',
      'Primary Lateral Sclerosis',
      'Progressive Multifocal Leukoencephalopathy',
      'Radiculopathy',
      'Rehabilitation',
      'Rett Syndrome',
      'Seizures',
      'Sleep Apnea',
      'Sleep Disorders',
      'Spasticity',
      'Spinal Cord Disease',
      'Spinal Cord Injury',
      'Spinal Muscular Atrophy',
      'Spinocerebellar Ataxias',
      'Stroke',
      'Subarachnoid Hemorrhage',
      'Subdural Hematoma',
      'Traumatic Brain Injury',
      'Trigeminal Neuralgia',
      'Vascular Dementia',
      'White Matter Disease',
    ],
  },
  { condition: 'Back Pain', subtypes: ['Low Back Pain', 'Lower Back Pain'] },
  { condition: 'Hypertension', subtypes: ['High Blood Pressure'] },
  {
    condition: 'Gallbladder Cancer',
    subtypes: ['Bile Duct Cancer', 'Cholangiocarcinoma'],
  },
  {
    condition: 'Pulmonary Fibrosis',
    subtypes: ['Idiopathic Pulmonary Fibrosis'],
  },
  {
    condition: 'Ovarian Carcinoma',
    subtypes: [
      'Fallopian Tube Adenocarcinoma',
      'Fallopian Tube Carcinoma',
      'Fallopian Tube Serous Adenocarcinoma',
      'Ovarian Adenocarcinoma',
      'Ovarian Cancer',
    ],
  },
  {
    condition: 'Mental Health Disorders',
    subtypes: [
      'Agoraphobia',
      'Alcohol Abuse',
      'Alcohol Intoxication',
      'Alcoholism',
      'Alcohol Use Disorder',
      'Amphetamine Use Disorder',
      'Anorexia Nervosa',
      'Anxiety Disorders',
      'Asperger Syndrome',
      'Attention Deficit Disorder',
      'Attention Deficit Hyperactivity Disorder (ADHD)',
      'Autism',
      'Autism Spectrum Disorder',
      'Binge Eating Disorder',
      'Bipolar Disorder',
      'Body Dysmorphic Disorder',
      'Borderline Personality Disorder',
      'Bulimia',
      'Cannabis Abuse',
      'Cannabis Dependence',
      'Cannabis Use',
      'Cannabis Use Disorder',
      'Conduct Disorder',
      'Delayed Sleep Phase Syndrome',
      'Dementia',
      'Depression',
      'Depression',
      'Bipolar Disorder',
      'Eating Disorder',
      'Eating Disorders',
      'First Episode Psychosis',
      'Gender Dysphoria',
      'Generalized Anxiety Disorder',
      'Hoarding Disorder',
      'Idiopathic Hypersomnia',
      'Insomnia',
      'Late-Life Depression',
      'Major Depression',
      'Major Depressive Disorder',
      'Major Depressive Episode',
      'Mild Cognitive Impairment',
      'Mood Disorders',
      'Narcolepsy',
      'Neurocognitive Disorder',
      'Neurodevelopmental Disorder',
      'Neurodevelopmental Disorders',
      'Nicotine Addiction',
      'Obsessive-Compulsive Disorder',
      'Obsessive Hoarding',
      'Opioid Abuse',
      'Opioid Addiction',
      'Opioid Use Disorder',
      'Oppositional Defiant Disorder',
      'Panic Disorder',
      'Persistent Depressive Disorder',
      'Pervasive Developmental Disorder',
      'Post-Traumatic Stress Disorder',
      'Problem Drinking',
      'Psychosis',
      'REM Sleep Behavior Disorder',
      'Restless Legs Syndrome',
      'Schizoaffective Disorder',
      'Schizophrenia',
      'Schizophreniform Disorder',
      'Social Anxiety Disorder',
      'Social Communication Disorder',
      'Speech Sound Disorder',
      'Stuttering',
      'Substance Abuse',
      'Substance Addiction',
      'Substance Use',
      'Substance Use Disorder',
      'Substance Use Disorders',
      'Tobacco Addiction',
      'Tobacco Use Disorder',
      'Trauma and Stress Disorders',
      'Trauma and Stressor Related Disorders',
      'Traumatic Stress Disorders',
    ],
  },
  {
    condition: 'Myeloid Leukemia',
    subtypes: [
      'Acute Myelogenous Leukemia',
      'Acute Myeloid Leukemia',
      'Acute Myelomonocytic Leukemia',
      'Acute Promyelocytic Leukemia',
      'Chronic Myelogenous Leukemia',
      'Megakaryoblastic Leukemia',
      'Monoblastic Leukemia',
      'Monocytic Leukemia',
      'Myelodysplastic Syndrome',
      'Myelodysplastic Syndromes',
    ],
  },
  { condition: 'Alopecia Areata', subtypes: ['Alopecia'] },
  {
    condition: 'Insulin Sensitivity',
    subtypes: ['Cardiometabolic Syndrome', 'Metabolic Syndrome'],
  },
  {
    condition: 'Blood Diseases',
    subtypes: [
      'Acute Leukemia',
      'Acute Lymphoblastic Leukemia',
      'Acute Myeloid Leukemia',
      'Aplastic Anemia',
      'Autoimmune Hemolytic Anemia',
      'Beta Thalassemia',
      'Diamond-Blackfan Anemia',
      'Essential Thrombocythemia',
      'Fanconi Anemia',
      'Hemolytic Anemia',
      'Hemophilia',
      'Hemophilia A',
      'Hemophilia B',
      'Iron-Deficiency Anemia',
      'Iron Overload',
      'Leukemia',
      'Low Platelet Count',
      'Myelodysplasia',
      'Myelodysplastic Syndrome',
      'Myelodysplastic Syndromes',
      'Myelofibrosis',
      'Myeloproliferative Disorder',
      'Neutropenia',
      'Paroxysmal Nocturnal Hemoglobinuria',
      'Polycythemia Vera',
      'Primary Myelofibrosis',
      'Sickle Cell Anemia',
      'Sickle Cell Crisis',
      'Sickle Cell Disease',
      'Sickle Cell Thalassemia',
      'Thalassemia',
      'Thrombocytopenia',
      'Von Willebrand Disease',
    ],
  },
  {
    condition: 'Health Care Utilization',
    subtypes: ['Medication Adherence', 'Medication Nonadherence'],
  },
  { condition: 'Intellectual Disability', subtypes: ['Fragile X Syndrome'] },
  { condition: 'Esophageal Adenocarcinoma', subtypes: ['Adenocarcinoma'] },
  {
    condition: 'Congenital Heart Defects',
    subtypes: [
      'Atrial Septal Defect',
      'Bicuspid Aortic Valve',
      'Hypoplastic Left Heart Syndrome',
      'Patent Ductus Arteriosus',
      'Patent Foramen Ovale',
      'Single Ventricle Heart',
      'Tetralogy of Fallot',
      'Ventricular Septal Defects',
    ],
  },
  {
    condition: 'Movement Disorders',
    subtypes: [
      'Dyskinesia',
      'Dystonia',
      'Essential Tremor',
      "Huntington's Disease",
    ],
  },
  {
    condition: 'Lymphoproliferative Disorders',
    subtypes: [
      'Adult T-Cell Leukemia/Lymphoma',
      'Anaplastic Large Cell Lymphoma',
      'B-Cell Lymphoma',
      'B-Cell Non-Hodgkin Lymphoma',
      'Chronic B-Cell Leukemia',
      'Chronic Lymphocytic Leukemia',
      'Cutaneous T-Cell Lymphoma',
      'Diffuse Large B-Cell Lymphoma',
      'Extranodal Lymphoma',
      'Follicular Lymphoma',
      'Hepatosplenic T-cell Lymphoma',
      "Hodgkin's Lymphoma",
      'Large Cell Lymphoma',
      'Lymphoblastic Leukemia',
      'Lymphoblastic Lymphoma',
      'Lymphocytic Leukemia',
      'Lymphoma',
      'MALT Lymphoma',
      'Mantle Cell Lymphoma',
      'Marginal Zone Lymphoma',
      'Peripheral T-Cell Lymphoma',
      'Primary Effusion Lymphomas',
      'Sezary Syndrome',
      'T-Cell Lymphoma',
    ],
  },
  {
    condition: 'Allergy',
    subtypes: [
      'Allergic Asthma',
      'Allergy or Hay Fever',
      'Anaphylaxis',
      'Extrinsic Allergic Alveolitis',
      'Chronic Urticaria',
      'Food Allergy',
      'Peanut Allergy',
    ],
  },
  {
    condition: 'Eye Diseases',
    subtypes: [
      'Age-Related Macular Degeneration',
      'Aphakia',
      'Astigmatism',
      'Cataract',
      'Conjunctivitis',
      'Corneal Dystrophy',
      'Corneal Edema',
      'Corneal Opacity',
      'Droopy Eyelid',
      'Dry Eye Syndrome',
      "Fuchs' Dystrophy",
      'Glaucoma',
      'Keratoconus',
      'Leber Congenital Amaurosis',
      'Macular Degeneration',
      'Macular Edema',
      'Meibomian Gland Dysfunction',
      'Myopia',
      'Near-sightedness',
      'Ocular Hypertension',
      'Open-Angle Glaucoma',
      'Presbyopia',
      'Proliferative Vitreoretinopathy',
      'Refractive Errors',
      'Retinal Degeneration',
      'Retinal Detachment',
      'Retinal Disease',
      'Retinal Dystrophy',
      'Retinitis Pigmentosa',
      'Stargardt Disease',
      'Uveitis',
    ],
  },
  {
    condition: 'Ovarian Adenocarcinoma',
    subtypes: [
      'Fallopian Tube Adenocarcinoma',
      'Fallopian Tube Serous Adenocarcinoma',
      'Clear Cell Carcinoma',
    ],
  },
  { condition: 'Hip Fracture', subtypes: ['Femur Fractures'] },
  {
    condition: 'Chronic Disease',
    subtypes: [
      'Allergic Asthma',
      'Alpha-1 Antitrypsin Deficiency',
      'Asthma',
      'Chronic Bronchitis',
      'Chronic Encephalopathy',
      'Chronic Fatigue Syndrome',
      'Chronic Illness',
      'Chronic Obstructive Pulmonary Disease',
      'Chronic Pancreatitis',
      'Chronic Traumatic Encephalopathy',
      'Chronic Urticaria',
      'Cognitive Impairment',
      'Comorbidity',
      'Emphysema',
      'Multiple Chronic Conditions',
      'Traumatic Brain Injury',
    ],
  },
  {
    condition: 'Endocrine Disorders',
    subtypes: [
      'Conn Syndrome',
      "Cushing's Syndrome",
      'Cushing Syndrome',
      'Diabetes',
      'Diabetes Mellitus',
      'Diabetic Neuropathy',
      'Diabetic Retinopathy',
      'Gastroparesis',
      'Hyperparathyroidism',
      'Hypoparathyroidism',
      'Hypothyroidism',
      'Primary Hyperparathyroidism',
      'Parathyroid Disease',
      'Testicular Hypogonadism',
      'Hypogonadotropic Hypogonadism',
    ],
  },
  {
    condition: 'Neurodevelopmental Disorders',
    subtypes: [
      'Asperger Syndrome',
      'Autism',
      'Autism Spectrum Disorder',
      'Pervasive Developmental Disorder',
      'Social Communication Disorder',
    ],
  },
  {
    condition: 'Substance Use Disorders',
    subtypes: [
      'Alcohol Use Disorder',
      'Amphetamine Use Disorder',
      'Cannabis Use Disorder',
      'Cocaine Use Disorder',
      'Opioid Use Disorder',
      'Substance Abuse',
      'Substance Addiction',
      'Substance Use Disorder',
      'Tobacco Use Disorder',
    ],
  },
  {
    condition: 'Peripheral Nerve Sheath Tumor',
    subtypes: ['Neurofibroma', 'Plexiform Neurofibroma'],
  },
  {
    condition: 'Genetic Disorders',
    subtypes: [
      'Angelman Syndrome',
      'Batten Disease',
      'Cowden Syndrome',
      'Glycogen Storage Disease',
      'Hemophilia A',
      'Hunter Syndrome',
      'Hurler Syndrome',
      'Lynch Syndrome',
      'Mucopolysaccharidosis',
      'Multiple Endocrine Neoplasia',
      'Neuronal Ceroid Lipofuscinosis',
      'Phenylketonuria',
      'Primary Immune Deficiency',
      'Sanfilippo Syndrome',
      'Severe Combined Immunodeficiency',
      'Thalassemia',
    ],
  },
  { condition: 'Spinal Fusion', subtypes: ['Osteoarthritis'] },
  {
    condition: 'Monoclonal Gammopathy',
    subtypes: [
      'Lymphoplasmacytic Lymphoma',
      'Multiple Myeloma',
      'Plasma Cell Myeloma',
      'Waldenstrom Macroglobulinemia',
      "Waldenstrom's Macroglobulinemia",
    ],
  },
  {
    condition: 'Mental Illness',
    subtypes: [
      'Agoraphobia',
      'Alcohol Abuse',
      'Alcohol Intoxication',
      'Alcoholism',
      'Alcohol Use Disorder',
      'Amphetamine Use Disorder',
      'Anorexia Nervosa',
      'Anxiety Disorders',
      'Asperger Syndrome',
      'Attention Deficit Disorder',
      'Attention Deficit Hyperactivity Disorder (ADHD)',
      'Autism',
      'Autism Spectrum Disorder',
      'Binge Eating Disorder',
      'Bipolar Disorder',
      'Body Dysmorphic Disorder',
      'Borderline Personality Disorder',
      'Bulimia',
      'Cannabis Abuse',
      'Cannabis Dependence',
      'Cannabis Use',
      'Cannabis Use Disorder',
      'Conduct Disorder',
      'Delayed Sleep Phase Syndrome',
      'Dementia',
      'Depression',
      'Depression',
      'Bipolar Disorder',
      'Eating Disorder',
      'Eating Disorders',
      'First Episode Psychosis',
      'Gender Dysphoria',
      'Generalized Anxiety Disorder',
      'Hoarding Disorder',
      'Idiopathic Hypersomnia',
      'Insomnia',
      'Late-Life Depression',
      'Major Depression',
      'Major Depressive Disorder',
      'Major Depressive Episode',
      'Mild Cognitive Impairment',
      'Mood Disorders',
      'Narcolepsy',
      'Neurocognitive Disorder',
      'Neurodevelopmental Disorder',
      'Neurodevelopmental Disorders',
      'Nicotine Addiction',
      'Obsessive-Compulsive Disorder',
      'Obsessive Hoarding',
      'Opioid Abuse',
      'Opioid Addiction',
      'Opioid Use Disorder',
      'Oppositional Defiant Disorder',
      'Panic Disorder',
      'Persistent Depressive Disorder',
      'Pervasive Developmental Disorder',
      'Post-Traumatic Stress Disorder',
      'Problem Drinking',
      'Psychosis',
      'REM Sleep Behavior Disorder',
      'Restless Legs Syndrome',
      'Schizoaffective Disorder',
      'Schizophrenia',
      'Schizophreniform Disorder',
      'Social Anxiety Disorder',
      'Social Communication Disorder',
      'Speech Sound Disorder',
      'Stuttering',
      'Substance Abuse',
      'Substance Addiction',
      'Substance Use',
      'Substance Use Disorder',
      'Substance Use Disorders',
      'Tobacco Addiction',
      'Tobacco Use Disorder',
      'Trauma and Stress Disorders',
      'Trauma and Stressor Related Disorders',
    ],
  },
]
