import type { TrialInDatabaseForAlgolia } from '../types/TrialInDatabaseForAlgolia'
import getParsedEligibilityCriteriaForTrial from './parseEligibilityCriteria/getParsedEligibilityCriteriaForTrial'
import { hasMatch } from './parseKeywordsFromTrial'
import type { FilterItem } from './parseKeywordsFromTrial/ngramData'
import { veteranFilterOptions } from './parseKeywordsFromTrial/ngramData'

export default function isVeteranInclusionCriteriaFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const content = getParsedEligibilityCriteriaForTrial(trial)
    .map((item) => item.criterion)
    .join(' ')

  const trialMatchesTag = (tag: FilterItem) =>
    tag.matches.some((match) => hasMatch(content, match))

  const isVeteran = veteranFilterOptions.some(trialMatchesTag)

  return isVeteran
}
