import { addMonths } from 'date-fns'
import type { TrialInDatabaseForAlgolia } from '../types/TrialInDatabaseForAlgolia'

/**
 * Enrollment for the trial ends on the "primary completion date". If this is set on the trial,
 * tag this trial as "hasEnrollmentClosingSoon" when we are less than three months away.
 *
 * @param trial Clinical trial in database
 */
export default function hasEnrollmentClosingSoonFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const trialCompletionDate = trial.primaryCompletionDate

  if (!trialCompletionDate) {
    return false
  }

  const threeMonthsFromNow = addMonths(new Date(), 3)
  return trialCompletionDate < threeMonthsFromNow
}
