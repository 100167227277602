import { isRealNumber } from '@lib/utilities/number/isRealNumber'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { ParsedCriterionType } from './parseEligibilityCriteria/getParsedEligibilityCriteriaForTrial'
import getParsedEligibilityCriteriaForTrial from './parseEligibilityCriteria/getParsedEligibilityCriteriaForTrial'

/**
 * ECOG scores range from 0 - 5: where lower is more active and 5 is dead. Find the highest
 * score given a trial eligibility criteria
 *
 * @see https://ecog-acrin.org/resources/ecog-performance-status/
 * @param trial Trial to search across all eligibility criteria
 * @returns Highest score eligible
 */
export default function getEcogScoreFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const eligibilityCriteria = getParsedEligibilityCriteriaForTrial(trial)
  const scores = eligibilityCriteria
    .map(parseEcogScoreFromEligibilityCriterion)
    .filter((maybeScore) => typeof maybeScore !== 'undefined')

  return getHighestEcogScore(scores)
}

export function parseEcogScoreFromEligibilityCriterion(
  eligibilityCriterion: ParsedCriterionType,
) {
  if (hasEcogPhrase(eligibilityCriterion.criterion)) {
    return extractScore(eligibilityCriterion.criterion)
  }

  return
}

export function hasEcogPhrase(criterion: string) {
  return (
    /ECOG/.test(criterion) ||
    /Eastern Cooperative Oncology Group/i.test(criterion)
  )
}

function extractScore(criterion: string) {
  const matches = criterion.match(/\d+/g)
  if (!matches) {
    return
  }

  // Sort the numbers from highest to lowest, no need to worry about uniqueness since N is small
  return getHighestEcogScore(matches.map((match) => parseInt(match, 10)))
}

function getHighestEcogScore(scores: (number | undefined)[]) {
  const sortedScores = scores
    .filter(isRealNumber)
    .filter((number) => typeof number !== 'undefined' && number <= 4)
    .sort()
    .reverse()

  return sortedScores[0]
}
