import { TrialSummaryCard } from '@components/app/TrialSummaryCard'
import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import Button from '@components/primitives/Button'
import { Icon } from '@components/primitives/Icon'
import { LoadingBlur } from '@components/primitives/LoadingBlur'
import { Text } from '@components/primitives/Text'
import { faRefresh } from '@fortawesome/pro-regular-svg-icons/faRefresh'
import type { TrackingSource } from '@lib/tracking'
import { trackEvent, TrackingEvent } from '@lib/tracking'
import type { UserSearchParams } from '@pages/api/v1/search'
import { mockTrialInAlgolia } from '@tests/fixtures/mocks/mockTrialInAlgolia'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useInstantSearch } from 'react-instantsearch'

type MissingTrialsViewProps = {
  trackingSource: TrackingSource
  userSearchParams?: UserSearchParams
}

export const MissingTrialsView: FC<MissingTrialsViewProps> = ({
  trackingSource,
  userSearchParams,
}) => {
  // If this is instantiated by a direct server call (by a crawler)
  // then show the preloaded trials. Other clients that execute JS should be unchanged.
  const [serverRender, setServerRender] = useState<boolean>(true)
  const { status } = useInstantSearch()
  const { clearSearchState } = useSearchUrlParameters()

  useEffect(() => setServerRender(false), [setServerRender])

  const isInitialInstantSearchLoad =
    serverRender || status === 'loading' || status === 'stalled'

  useEffect(() => {
    if (!isInitialInstantSearchLoad) {
      trackEvent(TrackingEvent.SEARCH_EMPTY_STATE_VIEWED, {
        source: trackingSource,
        val: JSON.stringify(userSearchParams),
      })
    }
  }, [isInitialInstantSearchLoad, trackingSource, userSearchParams])

  if (isInitialInstantSearchLoad) {
    return (
      <LoadingBlur>
        {[mockTrialInAlgolia, mockTrialInAlgolia].map(
          (trial, index: number) => (
            <TrialSummaryCard
              isFiltered={true}
              key={index}
              trackingSource={trackingSource}
              trial={trial}
            />
          ),
        )}
      </LoadingBlur>
    )
  }

  return (
    <div className='flex flex-col items-center gap-4 lg:my-16'>
      <Text
        className='text-center'
        styleName='p-small'
        value={`Oops! Looks like we don't have a perfect match for that. Try clearing filters to see more trials.`}
      />

      <Button
        className='rounded-full'
        color='black'
        onClick={() => {
          trackEvent(TrackingEvent.DIRECTORY_CLEAR_FILTERS_CLICKED, {
            type: 'No Results',
          })
          clearSearchState()
        }}
        value={
          <>
            <Icon className='mr-2' icon={faRefresh} /> Clear Filters
          </>
        }
      />
    </div>
  )
}
