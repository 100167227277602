import { searchParamFromUrl } from '@components/hooks/ParamsProvider/searchParams'
import { useIsTabletOrMobileScreen } from '@components/hooks/useIsMobileScreen'
import { PaginationContainerClassNames } from '@components/primitives/PaginationControl/paginationCssClasses'
import { useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { usePagination } from 'react-instantsearch'
import { NextPageLink } from './buildNextPageLink'
import { PageLinks } from './buildPageLinks'
import { PreviousPageLink } from './buildPreviousPageLink'

export const CustomPagination = () => {
  const { nbPages } = usePagination()
  const isMobile = useIsTabletOrMobileScreen()
  const searchParams = useSearchParams()
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParamFromUrl('page') ?? '1', 10),
  )

  useEffect(() => {
    const currPage = parseInt(searchParamFromUrl('page') ?? '1', 10)
    setCurrentPage(currPage)
  }, [searchParams])

  if (nbPages <= 1) {
    return <></>
  }

  return (
    <div className={PaginationContainerClassNames}>
      <PreviousPageLink currentPage={currentPage} />
      {isMobile ? (
        <div> Page {currentPage}</div>
      ) : (
        <PageLinks currentPage={currentPage} totalPages={nbPages} />
      )}
      <NextPageLink currentPage={currentPage} totalPages={nbPages} />
    </div>
  )
}
