export const cancerSubtypes = [
  'Adenocarcinoma',
  'Adenoid Cystic Carcinoma',
  'Adenoma',
  'Adenomatous Polyposis',
  'Adrenal Cancer',
  'Adult Acute Myeloid Leukemia',
  'Adult T-Cell Leukemia/Lymphoma',
  'Alveolar Rhabdomyosarcoma',
  'Alveolar Soft Part Sarcoma',
  'Ampullary Cancer',
  'Anal Canal Carcinoma',
  'Anal Cancer',
  'Anal Squamous Cell Carcinoma',
  'Anaplastic Astrocytoma',
  'Anaplastic Ependymoma',
  'Angiosarcoma',
  'Astrocytoma',
  'Atypical Teratoid/Rhabdoid Tumor',
  'B-Cell Leukemia',
  'Basal Cell Carcinoma',
  'Bile Duct Cancer',
  'Biliary Cancers',
  'Biliary Tract Cancer',
  'Bone Cancer',
  'Bone Tumors',
  'Brain Cancer',
  'Breast Cancer',
  'Breast Carcinoma',
  'Bronchial Tumors',
  'Carcinoid Syndrome',
  'Carcinoid Tumor',
  'Carcinoma',
  'Carcinosarcoma',
  'Central Nervous System Cancer',
  'Cerebral Cavernous Malformations',
  'Cervical Adenocarcinoma',
  'Cervical Carcinoma',
  'Childhood Brain Tumors',
  'Childhood Cancer',
  'Cholangiocarcinoma',
  'Chondrosarcoma',
  'Chordoma',
  'Clear Cell Carcinoma',
  'Clear Cell Sarcoma of Soft Tissue',
  'Colon Adenomas',
  'Colon Cancer',
  'Colorectal Adenomas',
  'Colorectal Cancer',
  'Colorectal Tumor',
  'Craniopharyngioma',
  'Cubital Tunnel Syndrome',
  'Desmoid Tumor',
  'Desmoplastic Small Round Cell Tumor',
  'Diabetic Macular Edema',
  'Diffuse Astrocytoma',
  'Diffuse Intrinsic Pontine Glioma',
  'Diffuse Midline Glioma',
  'Digestive System Tumors',
  'Ductal Carcinoma',
  'Embryonal Tumor',
  'Endocrine Tumors',
  'Endometrial Adenocarcinoma',
  'Ependymoma',
  'Epithelioid Sarcoma',
  'Esophageal Adenocarcinoma',
  'Esophageal Cancer',
  'Esophageal Carcinoma',
  'Esophageal Neoplasms',
  'Esthesioneuroblastoma',
  'Ewing Sarcoma',
  'Extranodal Lymphoma',
  'Fallopian Tube Adenocarcinoma',
  'Fallopian Tube Carcinoma',
  'Fallopian Tube Serous Adenocarcinoma',
  'Fibroid',
  'Gallbladder Cancer',
  'Ganglioglioma',
  'Ganglioneuroblastoma',
  'Gastric Adenocarcinoma',
  'Gastric Cancer',
  'Gastric Neoplasm',
  'Gastroesophageal Junction Adenocarcinoma',
  'Gastroesophageal Junction Cancer',
  'Gastrointestinal Cancer',
  'Gastrointestinal Stromal Tumor',
  'Gastrointestinal Stromal Tumors',
  'Gastrointestinal Tumors',
  'Genital Neoplasms',
  'Genitourinary Cancers',
  'Germ Cell Tumors',
  'Giant Cell Tumor',
  'Glioblastoma',
  'Gliomas',
  'Gliosarcoma',
  'Head and Neck Cancers',
  'Hemangiosarcoma',
  'Hepatoblastoma',
  'Hepatocellular Carcinoma',
  'Insulinoma',
  'Intrahepatic Cholangiocarcinoma',
  'Kaposi Sarcoma',
  "Kaposi's Sarcoma",
  'Kidney Carcinoma',
  'Kidney Tumors',
  'Laryngeal Cancer',
  'Laryngeal Squamous Cell Carcinoma',
  'Leiomyosarcoma',
  'Leukemia',
  'Lip and Oral Cavity Cancer',
  'Liposarcoma',
  'Liver Cancer',
  'Liver Metastases',
  'Liver Metastasis',
  'Liver Tumors',
  'Low Grade Glioma',
  'Lung Adenocarcinoma',
  'Lung Cancer',
  'Lung Carcinoma',
  'Lymphoma',
  'Major Salivary Gland Cancer',
  'Malignant Glioma',
  'Malignant Mesothelioma',
  'Malignant Pleural Effusion',
  'Malignant Pleural Mesothelioma',
  'Malignant Rhabdoid Tumor',
  'Medulloblastoma',
  'Megakaryoblastic Leukemia',
  'Melanoma',
  'Meningioma',
  'Merkel Cell Carcinoma',
  'Mesothelioma',
  'Metastases',
  'Metastasis',
  'Minimal Residual Disease',
  'Monoblastic Leukemia',
  'Monocytic Leukemia',
  "Morton's Neuroma",
  'Multiple Endocrine Neoplasia',
  'Myasthenia Gravis',
  'Myelodysplastic Syndrome',
  'Myelodysplastic Syndromes',
  'Myeloid Leukemia',
  'Nasopharyngeal Cancer',
  'Nasopharyngeal Carcinoma',
  'Neck Cancer',
  'Neoplasm',
  'Neoplasms',
  'Neuroblastoma',
  'Neuroectodermal Tumors',
  'Neuroendocrine Carcinoma',
  'Neuroendocrine Tumors',
  'Neurofibroma',
  'Neurofibromatosis',
  'Non-Small Cell Carcinoma',
  'Non-Small Cell Lung Cancer',
  'Non-small Cell Lung Cancer (NSCLC)',
  'Oligoastrocytoma',
  'Oligodendroglioma',
  'Oral Cancers',
  'Oral Leukoplakia',
  'Oral Squamous Cell Carcinoma',
  'Oropharyngeal Cancer',
  'Oropharyngeal Carcinoma',
  'Osteosarcoma',
  'Ovarian Adenocarcinoma',
  'Ovarian Carcinoma',
  'Pancreatic Adenocarcinoma',
  'Pancreatic Cancer',
  'Pancreatic Carcinoma',
  'Pancreatic Cyst',
  'Pancreatic Cysts',
  'Pancreatic Endocrine Carcinoma',
  'Pancreatic Neoplasm',
  'Pancreatic Neuroendocrine Tumors',
  'Pancreatic Tumors',
  'Paraganglioma',
  'Parathyroid Tumors',
  'Pelvic Cancer',
  'Penile Cancer',
  'Penile Squamous Cell Carcinoma',
  'Peritoneal Adenocarcinoma',
  'Peritoneal Cancer',
  'Peritoneal Carcinomatosis',
  'Peritoneal Neoplasm',
  'Peritoneal Tumors',
  'Pheochromocytoma',
  'Pilocytic Astrocytoma',
  'Pinealoblastoma',
  'Pituitary Adenomas',
  'Pituitary Tumors',
  'Plasmacytoma',
  'Pleomorphic Sarcoma',
  'Polyps',
  'Precancerous Conditions',
  'Primary Central Nervous System Neoplasm',
  'Primary Hyperparathyroidism',
  'Primary Sclerosing Cholangitis',
  'Primitive Neuroectodermal Tumor',
  'Prolymphocytic Leukemia',
  'Prostate Adenocarcinoma',
  'Prostate Cancer',
  'Prostate Carcinoma',
  'Pulmonary Nodules',
  'Rectal Cancer',
  'Renal Pelvis Cancer',
  'Retinoblastoma',
  'Rhabdoid Tumor',
  'Rhabdomyosarcoma',
  'Salivary Gland Cancer',
  'Salivary Gland Carcinoma',
  'Sarcoma',
  'Seminoma',
  'Skin Cancer',
  'Skin Disease',
  'Small Bowel Cancer',
  'Small Cell Carcinoma',
  'Small Cell Lung Cancer',
  'Soft Tissue Sarcoma',
  'Soft Tissue Sarcomas',
  'Solid Tumors',
  'Spinal Metastases',
  'Spinal Tumors',
  'Spinocerebellar Ataxias',
  'Squamous Cell Cancer',
  'Squamous Cell Carcinoma',
  'Stomach Cancer',
  'Stomach Tumors',
  'Synovial Sarcoma',
  'Systemic Mastocytosis',
  'T-Cell Lymphoblastic Leukemia',
  'Teratoma',
  'Testicular Cancer',
  'Testicular Germ Cell Tumor',
  'Throat Cancer',
  'Thrombocytopenia',
  'Thymic Cancer',
  'Thymic Carcinoma',
  'Thymoma',
  'Thyroid Cancer',
  'Thyroid Carcinoma',
  'Thyroid Gland Carcinoma',
  'Thyroid Nodule',
  'Tongue Cancer',
  'Transitional Cell Carcinoma',
  'Transthyretin Amyloidosis',
  'Urothelial Carcinoma',
  'Uterine Fibroids',
  'Uterine Leiomyosarcoma',
  'Uveal Melanoma',
  'Vascular Disease',
  'Warts',
  'Yolk Sac Tumor',
]
