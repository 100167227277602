import { GetNotifiedAboutTrialsCard } from '@components/app/ListingPage/GetNotifiedAboutTrialsCard'
import { TrialSummaryCard } from '@components/app/TrialSummaryCard'
import useIsPartnerPage from '@components/hooks/useIsPartnerPage'
import { ShimmerCards } from '@components/primitives/LoadingWrapper'
import { TrackingSource } from '@lib/tracking'

import type { TrialInAlgolia } from '@modules/trials/types/TrialInAlgolia'
import type { UserSearchParams } from '@pages/api/v1/search'
import type { FC } from 'react'
import { useState } from 'react'
import { useHits, useInstantSearch } from 'react-instantsearch'
import { MissingTrialsView } from '../missingTrialsView'

type TrialCardListProps = {
  trackingSource: TrackingSource
  userSearchParams?: UserSearchParams
}

const DISPLAY_CVR_CARD_EVERY_N_TRIALS = 9

export const TrialCardList: FC<TrialCardListProps> = ({
  trackingSource,
  userSearchParams,
}) => {
  const [hasSubmittedEmail, setHasSubmittedEmail] = useState(false)
  const { hits } = useHits<TrialInAlgolia>()
  const { status } = useInstantSearch()
  const viewingPartnerPage = useIsPartnerPage()

  if (status === 'loading') {
    return <ShimmerCards />
  }

  return (
    <div className='grid grid-cols-1 gap-1'>
      {hits.length === 0 ? (
        <MissingTrialsView
          trackingSource={trackingSource}
          userSearchParams={userSearchParams}
        />
      ) : (
        hits.map((hit, index: number) => {
          const showAlternateCard =
            !viewingPartnerPage &&
            index > 1 &&
            index % DISPLAY_CVR_CARD_EVERY_N_TRIALS === 0
          if (showAlternateCard) {
            return (
              <div key={`get-notified-about-trials-${index}`}>
                <div className='mb-4'>
                  <GetNotifiedAboutTrialsCard
                    hasSubmittedEmail={hasSubmittedEmail}
                    onSubmit={() => setHasSubmittedEmail(true)}
                    trial={hit}
                  />
                </div>
                <TrialSummaryCard
                  fireSearchEvent={true}
                  index={index}
                  isFiltered
                  trackingSource={trackingSource}
                  trial={hit}
                  userSearchParams={userSearchParams}
                />
              </div>
            )
          } else {
            return (
              <TrialSummaryCard
                fireSearchEvent={true}
                index={index}
                isFiltered
                key={`summary-card-${index}`}
                trackingSource={TrackingSource.SEARCH}
                trial={hit}
                userSearchParams={userSearchParams}
              />
            )
          }
        })
      )}
    </div>
  )
}
