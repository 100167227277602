import { stringCoordsToNumber } from '@components/hooks/GeolocationProvider/convertGeoToCoords'
import { GoogleMapsContext } from '@components/hooks/GooglePlacesAutocompleteProvider'
import { getAllSearchParams } from '@components/hooks/ParamsProvider/searchParams'
import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import { ANYWHERE_DISTANCE } from '@components/primitives/DistancePicker'
import type { UserSearchParams } from '@pages/api/v1/search'
import pickBy from 'lodash/pickBy'
import type { MouseEvent } from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useInstantSearch } from 'react-instantsearch'
import type { ConfigureGeolocationProps } from './LocationConfiguration'
import type { SearchCallbacks, SearchConfig } from './SearchBar/SearchInputs'
import { SearchInputs } from './SearchBar/SearchInputs'

interface SearchBarProps {
  autoFocus: boolean
  conditionPlaceholder?: string
  interventionPlaceholder?: string
  isSearchPage?: boolean
  locationPlaceholder?: string
  shadowStyle?: string
}

export type AlgoliaInstantSearchState = {
  configure?: ConfigureGeolocationProps
  page: number
  refinementList?: UserSearchParams
}

/**
 * Actual SearchBar providing inputs to control the search context passed to Algolia. Components use algolia react hooks
 * to `refine` searches given facets derived from `transformForAlgolia` method.
 *
 * @param options.conditionNameFallback String to use as the content in the condition search field
 */
export default function SearchBar({
  autoFocus = true,
  conditionPlaceholder,
  interventionPlaceholder,
  isSearchPage = false,
  locationPlaceholder,
  shadowStyle,
}: SearchBarProps) {
  // First, grab all of the search parameters from the URL to initialize state
  const { currentLocation: locationFromUrl, lat, lng } = getAllSearchParams()

  // Helper methods to update the URL with the search state
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()
  const { getLatLngFromPlaceName } = useContext(GoogleMapsContext)
  const { refresh } = useInstantSearch()

  const [conditionInput, setConditionInput] = useState(conditionPlaceholder)
  const [queryCoordinates, setQueryCoordinates] = useState(
    stringCoordsToNumber({ lat, lng }),
  )

  // If we've got a location name from the URL, we need to push the lat/lng to the search params so that we can filter
  useEffect(() => {
    const setUrlLatLngFromLocation = async () => {
      if (locationFromUrl && !lat && !lng) {
        const latLng = await getLatLngFromPlaceName(locationFromUrl)
        setQueryCoordinates(latLng)
        updateUrlToReflectSearchState({
          lat: String(latLng.lat),
          lng: String(latLng.lng),
        })
      }
    }
    setUrlLatLngFromLocation()
  }, [])

  const searchButtonClick = useCallback(
    (event: MouseEvent) => {
      refresh()
      event.preventDefault()
      updateUrlToReflectSearchState(
        pickBy({
          condition: conditionPlaceholder,
          currentLocation: locationPlaceholder,
          distance: locationPlaceholder ? ANYWHERE_DISTANCE : undefined,
          query: interventionPlaceholder,
        }),
      )
    },
    [conditionInput, updateUrlToReflectSearchState],
  )

  const propsToPassDown: SearchCallbacks & SearchConfig = {
    autoFocus,
    conditionPlaceholder,
    interventionPlaceholder,
    isSearchPage,
    locationPlaceholder,
    queryCoordinates,
    searchButtonClick,
    setConditionInput,
    setQueryCoordinates,
    shadowStyle,
  }

  return <SearchInputs {...propsToPassDown} />
}
