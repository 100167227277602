import isNotNull from '@lib/utilities/object/isNotNull'
import { profanities } from '@lib/utilities/text/profanities'

export const mergeQuotes = (quotes: string[]) => {
  // merge quotes until they are in strings that are at least 75 characters in length:
  const mergedQuotes: string[] = []

  let acc = ''
  for (let i = 0; i <= quotes.length; i++) {
    if (acc.length >= 75 || i === quotes.length) {
      mergedQuotes.push(acc)
      acc = ''
    }
    if (i === quotes.length) {
      break
    }

    acc += ' ' + quotes[i]
  }

  return mergedQuotes.filter((q) => isNotNull(q) && Boolean(q))
}

export const cleanQuotes = (quotes: string[]) => {
  return quotes.map((quote) => {
    let quoteWithoutMarks = quote.replace(/"/g, '')
    // if the last character isn't punctuation (period, question mark, exclamation etc)
    // add a period to the end of the quote
    if (!quoteWithoutMarks.match(/[.?!]$/)) {
      quoteWithoutMarks += '.'
    }
    return quoteWithoutMarks
  })
}

export const optimizePatientQuotes = (quotes: string[]) => {
  const cleanedQuotes = cleanQuotes(quotes)
  const appropriateQuotes = cleanedQuotes.filter(
    (q) =>
      !profanities.some((profanity) => q.toLowerCase().includes(profanity)),
  )
  const mergedQuotes = mergeQuotes(appropriateQuotes)
  return mergedQuotes
}
