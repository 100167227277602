import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'

function hasBackgroundTherapyAllowed(content: string | null) {
  return /background therapy allowed/i.test((content ?? '').toString())
}

/**
 * Do any of the interventions or arm group names or descriptions use "background therapy allowed"?
 *
 * @param trial Trial with interventions and arm groups
 */
export default function getInterventionsHaveBackgroundTherapyAllowedFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  return (
    hasBackgroundTherapyAllowed(
      [trial.detailedDescription, trial.briefSummary].join(' '),
    ) ||
    trial.interventions.some((intervention) => {
      return hasBackgroundTherapyAllowed(
        [
          intervention.description,
          intervention.name,
          (intervention.otherNames ?? []).join(' '),
          intervention.armGroupsJoin.map((armGroupsJoin) =>
            [
              armGroupsJoin.armGroup.description,
              armGroupsJoin.armGroup.label,
            ].join(' '),
          ),
        ].join(' '),
      )
    })
  )
}
