/**
 * Convert a number to the nearest base-10. For example 623 should be 100+, 1234 is 1000+.
 *
 * @param count Number to convert to one of `<10`, `10+`, `100+` or `1000+`
 */
export default function base10FloorToString(count: number): string {
  if (count === 1) {
    return '1'
  }
  if (count < 5) {
    return '<5'
  }
  if (count < 10) {
    return '<10'
  }
  if (count < 25) {
    return '<25'
  }
  if (count < 50) {
    return '<50'
  }
  if (count < 75) {
    return '<75'
  }
  if (count < 100) {
    return '<100'
  }
  if (count < 150) {
    return '<150'
  }
  if (count < 250) {
    return '<250'
  }
  if (count < 500) {
    return '<500'
  }
  if (count < 750) {
    return '<750'
  }
  if (count < 1000) {
    return '<1000'
  }

  return '1000+'
}
