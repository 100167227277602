import type { ArrayElement } from '@lib/types/arrayElement'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { EligibilityCriterionConcept, Prisma } from '@prisma/client'
import { literal, object, string } from 'zod'
import {
  PowerOntologyClass,
  PowerOntologyInclusiveSetAny,
  PowerOntologySource,
  PowerOntologyVocabulary,
} from '../../parseEligibilityCriteria/types'

/**
 * Retrieve a string array set of terms representing the inclusion for disease stage rated by TNM scale for
 * this trial. Should be of the form ["T0", "N0", "M0"] or ["any"]. If no terms are found, add "any" for
 * inclusion criteria
 */
export function getDiseaseTNMInclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const tnmTerms = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria(),
    new Set<string>(),
  )

  if (tnmTerms.size === 0) {
    tnmTerms.add(PowerOntologyInclusiveSetAny)
  }

  return Array.from(tnmTerms).sort()
}

/**
 * Retrieve a string array set of terms representing the exclusion set for disease stage
 * using the TNM scale for this trial. Should be of the form ["T0", "N0", "M0"]
 */
export function getDiseaseTNMExclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const tnmTerms = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria({ isInclusion: false }),
    new Set<string>(),
  )

  return Array.from(tnmTerms).sort()
}

function extractTermsFromEligibilityCriteria(
  { isInclusion }: { isInclusion: boolean } = { isInclusion: true },
) {
  return (
    terms: Set<string>,
    criterion: ArrayElement<
      TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
    >,
  ) => getTermsFromEligibilityCriteria(terms, criterion, isInclusion)
}

function getTermsFromEligibilityCriteria(
  terms: Set<string>,
  criterion: ArrayElement<
    TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
  >,
  isInclusion: boolean,
) {
  for (const component of criterion.criterionComponents) {
    if (hasValidDiseaseTNMData(component)) {
      const parsedTerms = parseDiseaseTNMFromRawData(
        component.rawData,
        isInclusion,
      )
      if (parsedTerms) {
        parsedTerms.map(terms.add, terms)
      }
    }
  }

  return terms
}

// Lots of noise in the table, keep just to Power ontology and isolated to class "classic"
function hasValidDiseaseTNMData({
  eligibilityCriterionConcept,
  rawData,
}: {
  eligibilityCriterionConcept: Pick<
    EligibilityCriterionConcept,
    | 'ontologyClass'
    | 'ontologyIdentifier'
    | 'ontologySource'
    | 'ontologyVocabulary'
  >
  rawData: Prisma.JsonValue
}) {
  return (
    hasValidRawData(rawData) &&
    eligibilityCriterionConcept.ontologyIdentifier &&
    eligibilityCriterionConcept.ontologyClass === PowerOntologyClass.TNM &&
    eligibilityCriterionConcept.ontologySource === PowerOntologySource &&
    eligibilityCriterionConcept.ontologyVocabulary ===
      PowerOntologyVocabulary.DiseaseStaging
  )
}

// Example: {"type": "t_n_m", "value": {"m": ["0"], "n": ["2", "3"], "t": ["1", "2", "3"]}, "status": "include"}
const DiseaseTNMRawDataValidator = object({
  status: literal('include').or(literal('exclude')),
  type: literal('t_n_m'),
  value: object({
    m: string().array(),
    n: string().array(),
    t: string().array(),
  }),
})

function hasValidRawData(rawData: Prisma.JsonValue) {
  return DiseaseTNMRawDataValidator.safeParse(rawData).success
}

function parseDiseaseTNMFromRawData(
  rawData: Prisma.JsonValue,
  useInclusion: boolean,
) {
  const parsedValue = DiseaseTNMRawDataValidator.safeParse(rawData)
  if (!parsedValue.success) {
    return
  }

  // Exit early if we are looking for inclusion and this is exclusion criterion or vice versa
  const statusHasExclusion = parsedValue.data.status.includes('exclu')
  if (
    (useInclusion && statusHasExclusion) ||
    (!useInclusion && !statusHasExclusion)
  ) {
    return
  }

  // Covers T1, T2, T3, N2, N3, M0
  return Object.entries(parsedValue.data.value).flatMap(([key, values]) => {
    return values
      .filter((v) => ['0', '1', '2', '3'].includes(v))
      .flatMap((v) => `${key.toUpperCase()}${v}`)
  })
}
