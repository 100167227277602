import type { TrialLocationNarrow } from '../types/TrialAssociatedWithLocations'
import { AVAILABLE_COUNTRIES, hasAvailableCountry } from './hasAvailableCountry'

type LocationNamesFromLocations = {
  distinctLocationByGeographyCount: number // Region by state + country counts
  locationCities: string[]
  locationCount: number
  locationCountries: string[]
  locationFacilities: string[]
  locationShortList: string[]
  locationStates: string[]
  locationZips: string[]
}

export const LOCATION_SHORT_LIST_MAX = 4
/**
 * Construct a short list of locations, starting with states if in the US,
 * and filling with countries when present
 * @param trialLocations Locations for a trial
 */
export default function parseLocationNamesFromLocations(
  trialLocations: TrialLocationNarrow[],
): LocationNamesFromLocations {
  // Construct a short list of locations, starting with states if in the US, and filling
  // with countries when present
  const locationCountriesSet = new Set<string>()
  const locationStatesSet = new Set<string>()
  const locationZipsSet = new Set<string>()
  const locationCitySet = new Set<string>()
  const locationFacilitiesSet = new Set<string>()
  trialLocations.map((location) => {
    // All US locations have country
    if (!!location.country) {
      locationCountriesSet.add(location.country)
      if (hasAvailableCountry(location.country)) {
        if (!!location.state) {
          locationStatesSet.add(location.state)
        }
        if (!!location.zip) {
          locationZipsSet.add(location.zip)
        }
        if (!!location.city) {
          locationCitySet.add(location.city)
        }
      }
    }
    if (location.facility !== null) {
      locationFacilitiesSet.add(location.facility)
    }
  })

  let locationShortList: string[] = []
  if (AVAILABLE_COUNTRIES.some(hasAvailableCountry)) {
    locationShortList = Array.from(locationStatesSet).slice(
      0,
      LOCATION_SHORT_LIST_MAX,
    )
  }
  // Exclude United States if we're adding countries in
  if (locationShortList.length < LOCATION_SHORT_LIST_MAX) {
    locationShortList.push(
      ...Array.from(locationCountriesSet)
        .filter((country) => !hasAvailableCountry(country))
        .slice(0, LOCATION_SHORT_LIST_MAX - locationShortList.length),
    )
  }

  const locationCountries = Array.from(locationCountriesSet)
  const locationStates = Array.from(locationStatesSet)
  const locationZips = Array.from(locationZipsSet)
  const locationCities = Array.from(locationCitySet)
  const locationFacilities = Array.from(locationFacilitiesSet)

  return {
    distinctLocationByGeographyCount:
      locationStates.length + locationCountries.length,
    locationCities,
    locationCount: trialLocations.length,
    locationCountries,
    locationFacilities,
    locationShortList,
    locationStates,
    locationZips,
  }
}
