import { Text } from '@components/primitives/Text'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { IconCircle } from '../IconCircle'

export const CustomSelectOption = ({
  className,
  label,
  selectedValue,
  showCheckCircle,
  value,
}: {
  className?: string
  label: string
  selectedValue?: string
  showCheckCircle: boolean
  value: string
}) => {
  const isSelected = value === selectedValue

  return (
    <div className='flex cursor-pointer items-center justify-between'>
      <Text className={className} value={label} />
      {showCheckCircle && isSelected && (
        <IconCircle className={className} icon={faCheck} />
      )}
    </div>
  )
}
