import type { ArrayElement } from '@lib/types/arrayElement'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { EligibilityCriterionConcept, Prisma } from '@prisma/client'
import {
  PowerOntologyClass,
  PowerOntologySource,
  PowerOntologyVocabulary,
} from '../../parseEligibilityCriteria/types'
import { doesOntologyStatusMatchCriterionStatus } from './doesOntologyStatusMatchCriterionStatus'

/**
 * Extracts comorbidity for inclusion and exclusion from Concept terms
 * Concept terms mapped and parsed in modules/trials/helpers/extractEntitiesFromEligibilityCriteria/getComorbidityFromCriterion.ts
 * @param {ArrayElement<TrialInDatabaseForAlgolia['parsedEligibilityCriteria']>} criterion - Trial criterion
 * @param {boolean} isInclusion - Inclusion or exclusion for each criterion
 * @param {Set<string>} terms - Set to upsert to Algolia
 * @returns {terms} Comorbidity unique terms from concept data
 */
export function getComorbidityTermsFromEligibilityCriteria({
  criterion,
  isInclusion,
  terms,
}: {
  criterion: ArrayElement<
    TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
  >
  isInclusion: boolean
  terms: Set<string>
}): Set<string> {
  for (const component of criterion.criterionComponents) {
    if (!hasValidComorbidityData(component)) {
      continue
    }
    if (!component.eligibilityCriterionConcept.ontologyIdentifier) {
      continue
    }

    const ontologyStatusMatchCriterionStatus =
      doesOntologyStatusMatchCriterionStatus({
        isInclusion,
        ontologyIdentifier:
          component.eligibilityCriterionConcept.ontologyIdentifier,
      })
    if (!ontologyStatusMatchCriterionStatus) {
      continue
    }

    if (
      component.eligibilityCriterionConcept.name &&
      component.eligibilityCriterionConcept.name.length > 0
    ) {
      terms.add(component.eligibilityCriterionConcept.name)
    }
  }

  return terms
}

function hasValidComorbidityData({
  eligibilityCriterionConcept,
}: {
  eligibilityCriterionConcept: Pick<
    EligibilityCriterionConcept,
    | 'ontologyClass'
    | 'ontologyIdentifier'
    | 'ontologySource'
    | 'ontologyVocabulary'
  >
  rawData: Prisma.JsonValue
}) {
  return (
    eligibilityCriterionConcept.ontologyIdentifier &&
    eligibilityCriterionConcept.ontologyClass ===
      PowerOntologyClass.Comorbidity &&
    eligibilityCriterionConcept.ontologySource === PowerOntologySource &&
    eligibilityCriterionConcept.ontologyVocabulary ===
      PowerOntologyVocabulary.Conditions
  )
}
