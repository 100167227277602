import type { ArrayElement } from '@lib/types/arrayElement'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { EligibilityCriterionConcept, Prisma } from '@prisma/client'
import { literal, object, string } from 'zod'
import {
  PowerOntologyClass,
  PowerOntologyInclusiveSetAny,
  PowerOntologySource,
  PowerOntologyVocabulary,
} from '../../parseEligibilityCriteria/types'
import { allowedTermsFilter } from './disallowTermsFilter'

/**
 * Retrieve a string array set of terms representing the inclusion set for conditions for
 * this trial. Should be of the form ["prostate cancer", "diabetes"] or ["any"]. If no
 * terms are found, add "any" for inclusion criteria
 */
export function getConditionsInclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const conditions = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria(),
    new Set<string>(),
  )

  if (conditions.size === 0) {
    conditions.add(PowerOntologyInclusiveSetAny)
  }

  return Array.from(conditions).sort()
}

/**
 * Retrieve a string array set of terms representing the exclusion set for conditions for
 * this trial. Should be of the form ["prostate cancer"]
 */
export function getConditionsExclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const conditions = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria({ isInclusion: false }),
    new Set<string>(),
  )

  return Array.from(conditions).sort()
}

function extractTermsFromEligibilityCriteria(
  { isInclusion }: { isInclusion: boolean } = { isInclusion: true },
) {
  return (
    terms: Set<string>,
    criterion: ArrayElement<
      TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
    >,
  ) => getTermsFromEligibilityCriteria(terms, criterion, isInclusion)
}

function getTermsFromEligibilityCriteria(
  terms: Set<string>,
  criterion: ArrayElement<
    TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
  >,
  isInclusion: boolean,
) {
  for (const component of criterion.criterionComponents) {
    if (hasValidConditionData(component)) {
      const conditions = parseConditionFromRawData(
        component.rawData,
        isInclusion,
      )
      if (conditions && conditions.length > 0) {
        conditions.map(terms.add, terms)
      }
    }
  }

  return terms
}

function hasValidConditionData({
  eligibilityCriterionConcept,
  rawData,
}: {
  eligibilityCriterionConcept: Pick<
    EligibilityCriterionConcept,
    | 'ontologyClass'
    | 'ontologyIdentifier'
    | 'ontologySource'
    | 'ontologyVocabulary'
  >
  rawData: Prisma.JsonValue
}) {
  return (
    hasValidRawData(rawData) &&
    eligibilityCriterionConcept.ontologyClass ===
      PowerOntologyClass.Condition &&
    eligibilityCriterionConcept.ontologySource === PowerOntologySource &&
    eligibilityCriterionConcept.ontologyVocabulary ===
      PowerOntologyVocabulary.Conditions
  )
}

// Example: {"name": "breast cancer", "type": "condition", "status": "included"}
const ConditionRawDataValidator = object({
  name: string(),
  status: literal('included').or(literal('excluded')),
  type: literal('comorbidity').or(literal('condition')),
})

function hasValidRawData(rawData: Prisma.JsonValue) {
  return ConditionRawDataValidator.safeParse(rawData).success
}

function parseConditionFromRawData(
  rawData: Prisma.JsonValue,
  useInclusion: boolean,
) {
  const parsedValue = ConditionRawDataValidator.safeParse(rawData)
  if (!parsedValue.success) {
    return []
  }

  // Exit early if we are looking for inclusion and this is exclusion criterion or vice versa
  const statusHasExclusion = parsedValue.data.status.includes('exclu')
  if (
    (useInclusion && statusHasExclusion) ||
    (!useInclusion && !statusHasExclusion)
  ) {
    return []
  }

  // Terms should look like ["prostate cancer"]
  return [parsedValue.data.name].filter(allowedTermsFilter) // should we look up synonyms or colloquial term here?
}
