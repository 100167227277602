import Button from '@components/primitives/Button'
import Modal from '@components/primitives/Modal/index.client'
import { Text } from '@components/primitives/Text'
import type { ReactNode } from 'react'
import { AgeRefinementList } from '../AgeRefinementList'
import { ViewTrialLabel } from '../FilterModal'
import { ConditionAutocomplete } from '../SearchableRefinementList'
import { FilterList } from '../TrialFilters'
import { TrialSearchDistancePicker } from '../TrialSearchDistancePicker'
import { LocationPicker } from './LocationPicker'
import type { SearchCallbacks } from './SearchInputs'

type FilterModalConfig = {
  modalVisible: boolean
  setModalVisible: (value: boolean) => void
}

export const MobileSearchFilterModal = ({
  conditionPlaceholder,
  modalVisible,
  queryCoordinates,
  setConditionInput,
  setModalVisible,
  setQueryCoordinates,
}: SearchCallbacks & FilterModalConfig) => {
  return (
    <Modal
      className='overflow-visible'
      footer={
        <div className='p-4 text-right'>
          <Button
            color='black'
            onClick={() => setModalVisible(false)}
            value={<ViewTrialLabel />}
          />
        </div>
      }
      onClose={() => setModalVisible(false)}
      size='Full'
      title='Filter Results'
      visible={modalVisible}
    >
      <ModalSearchFilterSection label='Condition'>
        <ConditionAutocomplete
          autoFocus={true}
          conditionPlaceholder={conditionPlaceholder}
          onInputChange={setConditionInput}
        />
      </ModalSearchFilterSection>
      {/*  let's do condition, age, location, distance  */}
      <ModalSearchFilterSection label='Where are you looking?'>
        <LocationPicker
          queryCoordinates={queryCoordinates}
          setQueryCoordinates={setQueryCoordinates}
        />
      </ModalSearchFilterSection>

      <Text
        className='mb-4'
        styleName='p-small'
        value={'How far are you willing to travel?'}
      />

      <TrialSearchDistancePicker />
      <div className='mb-8'></div>

      <ModalSearchFilterSection label='How old are you?'>
        <AgeRefinementList placeholderText='Type Age...' />
      </ModalSearchFilterSection>

      <div>
        <FilterList />
      </div>
    </Modal>
  )
}

const ModalSearchFilterSection = ({
  children,
  label,
  wrapper = true,
}: {
  children: ReactNode
  label: string
  wrapper?: boolean
}) => {
  const roundedInputStyles = wrapper
    ? 'p-4 mb-4 border-neutral100 border-2 w-full rounded-full'
    : ''
  return (
    <div className='relative mb-4'>
      <Text styleName='p-small' value={label} />
      <div className={roundedInputStyles}>{children}</div>
    </div>
  )
}
