import { SelectBox } from '@components/primitives/SelectBox/SelectBox.client'
import { classMerge } from '@components/utilities/classMerge'

export const ANYWHERE_DISTANCE = '90'

export type DistanceValue = '10' | '30' | '50' | '70' | typeof ANYWHERE_DISTANCE

export const distanceOptions = [
  { label: 'Any', value: ANYWHERE_DISTANCE },
  { label: '<10 miles', value: '10' },
  { label: '<30 miles', value: '30' },
  { label: '<50 miles', value: '50' },
  { label: '<70 miles', value: '70' },
]

export const DistancePicker = ({
  className,
  distance,
  setDistance,
}: {
  className?: string
  distance: DistanceValue
  setDistance: (val: DistanceValue) => void
}) => {
  return (
    <SelectBox
      className={classMerge('border border-neutral200', className)}
      instanceId='distance-picker'
      isClearable={false}
      items={distanceOptions}
      onChange={(value) => {
        if (value) {
          setDistance(value as DistanceValue)
        }
      }}
      value={distance}
    />
  )
}
