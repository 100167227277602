import { isTrialSiteMonetized } from '@modules/facilities/helpers/isTrialSiteMonetized'
import isResponsive from '@modules/trials/helpers/isResponsive'
import type { TrialSiteLocationInDatabaseForAlgolia } from '@modules/trials/types/TrialSiteLocationInDatabaseForAlgolia'
import type { TrialInDatabaseForAlgolia } from '../types/TrialInDatabaseForAlgolia'

/**
 * True if the trial location is monetized, otherwise defer
 * to isResponsive logic for trial.
 */
export default function isResponsiveOrMonetized(
  trial: TrialInDatabaseForAlgolia,
  trialLocation: TrialSiteLocationInDatabaseForAlgolia,
) {
  const isTrialResponsive = isResponsive(trial)
  const isTrialMonetized = isTrialSiteMonetized(trialLocation)

  return isTrialMonetized || isTrialResponsive
}
