import { searchParamFromUrl } from '@components/hooks/ParamsProvider/searchParams'
import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import useIsPartnerPage from '@components/hooks/useIsPartnerPage'
import { Checkbox } from '@components/primitives/Checkbox'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useRefinementList } from 'react-instantsearch'
import { AccordionFilterGroupContainer } from './AccordionFilterGroupContainer'

export const TrialStatusFilters = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()
  useRefinementList({
    attribute: 'overallStatus',
  })
  useRefinementList({
    attribute: 'hasNoPlacebo',
  })
  const viewingPartnerPage = useIsPartnerPage()

  const [excludePlacebo, setExcludePlacebo] = useState(
    searchParamFromUrl('hasNoPlacebo') === 'true',
  )

  const [recruitingStatus, setRecruitingStatus] = useState(
    searchParamFromUrl('overallStatus')?.split(',') ?? [], // Recruiting or ActiveNotRecruiting or both
  )

  useEffect(() => {
    setExcludePlacebo(searchParamFromUrl('hasNoPlacebo') === 'true')
    setRecruitingStatus(searchParamFromUrl('overallStatus')?.split(',') ?? [])
  }, [pathname, searchParams])

  return (
    <AccordionFilterGroupContainer title='Trial Status'>
      <div className='px-2'>
        <div className='px-2'>
          <Text
            className='text-neutral800'
            styleName='p-small'
            value='Recruiting status'
          />
          <RecruitingButtonToggle
            onChange={(label: string, value: boolean) => {
              if (value) {
                setRecruitingStatus([...recruitingStatus, label])
                updateUrlToReflectSearchState({
                  overallStatus: [...recruitingStatus, label].join(','),
                })
              } else {
                setRecruitingStatus(
                  recruitingStatus.filter((status) => status !== label),
                )
                updateUrlToReflectSearchState({
                  overallStatus: recruitingStatus
                    .filter((status) => status !== label)
                    .join(','),
                })
              }
            }}
            recruiting={recruitingStatus.includes('Recruiting')}
            waitlist={recruitingStatus.includes('ActiveNotRecruiting')}
          />
          <Text
            className='mt-3 text-neutral800'
            styleName='p-small'
            value='Placebo'
          />
        </div>

        <div className='group px-2 md:hover:bg-primary100'>
          <label className='group flex gap-4 py-3 md:hover:bg-primary100'>
            <Checkbox
              checked={excludePlacebo}
              className={classMerge(
                'md:[&:not(:checked)]:group-hover:bg-primary300',
                viewingPartnerPage ? 'border border-neutral500' : 'border-none',
              )}
              onChange={(e) => {
                const value = e.target.checked
                setExcludePlacebo(value)
                updateUrlToReflectSearchState({
                  hasNoPlacebo: value.toString(),
                })
              }}
            />
            <div className='flex items-center justify-between'>
              <Text
                className='py-0  leading-[inherit] text-neutral600 md:leading-[inherit]'
                styleName='p-small'
                value='Show Trials Without Placebo'
              />
            </div>
          </label>
        </div>
      </div>
    </AccordionFilterGroupContainer>
  )
}

const RecruitingButtonToggle = ({
  onChange,
  recruiting,
  waitlist,
}: {
  onChange: (label: string, value: boolean) => void
  recruiting: boolean
  waitlist: boolean
}) => {
  const viewingPartnerPage = useIsPartnerPage()

  return (
    <div className='flex w-full'>
      <div
        className={classMerge(
          recruiting
            ? 'bg-primary200 hover:bg-primary300'
            : 'bg-white hover:bg-primary100',
          viewingPartnerPage
            ? 'border border-neutral400'
            : 'border border-y-0 border-l-0 border-neutral400',
          'w-1/2 cursor-pointer rounded-l-md  py-2 text-center',
        )}
        onClick={() => onChange('Recruiting', !recruiting)}
      >
        Recruiting
      </div>
      <div
        className={classMerge(
          waitlist
            ? 'bg-primary200 hover:bg-primary300'
            : 'bg-white hover:bg-primary100',
          viewingPartnerPage && 'border border-l-0 border-neutral400',
          'w-1/2 cursor-pointer rounded-r-md py-2 text-center',
        )}
        onClick={() => onChange('ActiveNotRecruiting', !waitlist)}
      >
        Waitlist Available
      </div>
    </div>
  )
}
