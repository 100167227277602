import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import type { InterventionType } from '@prisma/client'

type InterventionLabel =
  | 'Behavior'
  | 'Device'
  | 'Dietary Supplement'
  | 'Drug'
  | 'Injection'

const interventionTypeMap: Partial<
  Record<keyof typeof InterventionType, InterventionLabel>
> = {
  Behavioral: 'Behavior',
  Device: 'Device',
  DietarySupplement: 'Dietary Supplement',
  Drug: 'Drug',
}

/**
 * Retrieve a sorted, unique list of intervention types for this trial.
 */
export default function getInterventionTypesFromTrial(
  interventions: TrialInDatabaseForAlgolia['interventions'],
) {
  const interventionTypes: Set<InterventionLabel> = new Set()
  for (const intervention of interventions) {
    if (!intervention) {
      continue
    }

    const interventionType = interventionTypeMap[intervention.type]
    if (interventionType) {
      interventionTypes.add(interventionType)
    }
  }

  return Array.from(interventionTypes).sort()
}
