import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import { Icon } from '@components/primitives/Icon'
import { PaginationControlDisabledModifier } from '@components/primitives/PaginationControl/PaginationControlDisabledModifier'
import { Text } from '@components/primitives/Text'
import { faArrowSquareRight } from '@fortawesome/pro-regular-svg-icons/faArrowSquareRight'
import { trackEvent, TrackingEvent } from '@lib/tracking'

export const NextPageLink = ({
  currentPage,
  totalPages,
}: {
  currentPage: number
  totalPages: number
}) => {
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()

  const onClick = () => {
    updateUrlToReflectSearchState({ page: (currentPage + 1).toString() })
    trackEvent(TrackingEvent.SEARCH_PAGINATION_NEXT_CLICKED, {
      type: 'current-page-number',
      val: currentPage,
    })
  }
  const hasNextPage = currentPage < totalPages

  return hasNextPage ? (
    <div onClick={onClick}>
      <Text
        className='mx-2 my-4 size-11 cursor-pointer  bg-neutral100 p-2 text-center text-neutral900'
        value={<Icon icon={faArrowSquareRight} />}
      />
    </div>
  ) : (
    /* Disabled button state: no next page, at last page */
    <PaginationControlDisabledModifier
      direction='increment'
      identifier={totalPages + 1}
    />
  )
}
