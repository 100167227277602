import type { AlgoliaHierarchicalMenuRecord } from '@modules/trials/helpers/algolia/convertLabelledUmlsTreeNumberToCategoryLevels'
import convertLabelledUmlsTreeNumberToCategoryLevels from '@modules/trials/helpers/algolia/convertLabelledUmlsTreeNumberToCategoryLevels'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'

export const buildConditionTree = (
  conditions: TrialInDatabaseForAlgolia['conditions'],
) => {
  const conditionsLabelledUmlsTreeNumbers = getUniqueUmlsTerms(conditions)

  return conditionsLabelledUmlsTreeNumbers.reduce(
    (tree: AlgoliaHierarchicalMenuRecord, labelledUmlsTreeNumber) => {
      return convertLabelledUmlsTreeNumberToCategoryLevels(
        labelledUmlsTreeNumber,
        tree,
      )
    },
    {},
  )
}

export const getUniqueUmlsTerms = (
  conditions: TrialInDatabaseForAlgolia['conditions'],
) => {
  return Array.from(
    new Set<string>(
      ...conditions.map(
        (condition) => condition.condition.umlsParentCategories,
      ),
    ),
  )
}
