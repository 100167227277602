import { usePrevious } from '@components/hooks/usePrevious'
import { useEffect } from 'react'
import { useInstantSearch } from 'react-instantsearch'

export const SearchStateListener = ({
  setLoading,
}: {
  setLoading?: (isLoading: boolean) => void
}) => {
  const { addMiddlewares, status } = useInstantSearch()
  const prevStatus = usePrevious(status)

  useEffect(() => {
    if (
      status === 'idle' &&
      (prevStatus === 'loading' || prevStatus === 'stalled') &&
      setLoading
    ) {
      setLoading(false)
    }
    setTimeout(() => {
      // fallback in the odd case a state change is missed
      setLoading && setLoading(false)
    }, 1000)
  }, [status])

  useEffect(() => {
    return addMiddlewares(middleware)
  }, [])

  return <></>
}

function middleware() {
  return {
    onStateChange({ uiState: _uiState }: any) {
      // Do something with `uiState` every time the state changes.
      // ui state looks like:  {"production-power-v0-trials-by-site-locations":{"configure":{"distinct":1,"facetingAfterDistinct":true,"aroundLatLng":"49.2827,-123.1207","aroundPrecision":1609,"aroundRadius":16093},"refinementList":{"hasNoPlacebo":["false","true"]}}}
      // saveUserSearch(
      //   convertSearchStateToUserSearchParams({
      //     searchState: updatedSearchState,
      //     type: 'searchFacet',
      //   }),
      // )
    },
    subscribe() {
      // Do something when the InstantSearch instance starts.
    },
    unsubscribe() {
      // Do something when the InstantSearch instance is disposed.
    },
  }
}
