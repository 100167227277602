// api-withpower-v1 retool@api_withpower_prod=> select distinct(unnest("phaseList")) from "ClinicalTrial" ;
// Not Applicable
// Early Phase 1
// Phase 1
// Phase 2
// Phase 3
// Phase 4
const phaseMatchers = [
  { label: 'Early Phase 1', matches: [/Early Phase 1/i] },
  { label: 'Phase 1', matches: [/Phase 1/i] },
  { label: 'Phase 2', matches: [/Phase 2/i] },
  { label: 'Phase 3', matches: [/Phase 3/i] },
  { label: 'Phase 4', matches: [/Phase 4/i] },
  { label: 'Not Applicable', matches: [/Not Applicable/i, /N\/A/i] },
]

/**
 * Get a normalized list of phases given to push up to Algolia:
 * Not Applicable, Early Phase 1, Phase 1, Phase 2, Phase 3, Phase 4
 */
export default function getPhaseList(phaseList: string[]) {
  const phaseSet = new Set<string>()

  for (const phase of phaseList) {
    const matchedPhase = phaseMatchers.find((matcher) =>
      matcher.matches.some((re) => re.test(phase)),
    )?.label

    if (matchedPhase) {
      phaseSet.add(matchedPhase)
    }
  }

  return Array.from(phaseSet).sort()
}
