import { DescriptiveToClassicLookup } from './descriptiveToClassicLookup'
import type { DiseaseStageClassic, DiseaseStageDescriptive } from './types'

/**
 * Get a list of stages that map to the descriptive stages. Descriptive stages are common keywords
 * to refer to stage of onclogy patients. This is a naive version using a simple look-up table.
 *
 */
export function convertFromDescriptiveToClassic(
  descriptives: DiseaseStageDescriptive[],
  condition?: string,
): DiseaseStageClassic[] {
  const stagesArray = descriptives.flatMap((descriptive) =>
    getClassicFromDescriptiveSingular({
      condition,
      descriptive,
    }),
  )

  const stages = new Set<DiseaseStageClassic>(stagesArray)

  return Array.from(stages)
}

function getClassicFromDescriptiveSingular({
  condition,
  descriptive,
}: {
  condition?: string
  descriptive: DiseaseStageDescriptive
}): DiseaseStageClassic[] {
  // Use the condition specific look-up table, or fallback to the default
  let table = DescriptiveToClassicLookup.default!
  if (condition) {
    table =
      DescriptiveToClassicLookup[condition.toLowerCase()] ??
      DescriptiveToClassicLookup.default!
  }

  // find exact descriptive keyword matches for this stage
  const matches = table.filter(
    (item) => item.descriptive.toLowerCase() === descriptive.toLowerCase(),
  )

  if (!matches || matches.length === 0) {
    return []
  }

  return matches.map((match) => match.classic)
}
