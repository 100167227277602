import { searchParamFromUrl } from '@components/hooks/ParamsProvider/searchParams'
import { useSearchUrlParameters } from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import { ANYWHERE_DISTANCE } from '@components/primitives/DistancePicker'
import DynamicRoutes from '@lib/routes/DynamicRoutes'
import debounce from 'lodash/debounce'
import { usePathname, useSearchParams } from 'next/navigation'
import { useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'

/**
 * Refine the query by numeric age input to the closest age block. Set the age facet given the number input.
 * Age blocks are some combination of one or more: `Child`, `Adult`, `Older Adult`.
 *
 * @see https://www.algolia.com/doc/api-reference/widgets/refinement-list/react/#connector
 */
export const AgeRefinementList = ({
  conditionPlaceholder,
  interventionPlaceholder,
  locationPlaceholder,
  placeholderText,
}: {
  conditionPlaceholder?: string
  interventionPlaceholder?: string
  locationPlaceholder?: string
  placeholderText: string
}) => {
  const pathName = usePathname()
  const searchParams = useSearchParams()
  const isSearchPage = DynamicRoutes.trialSearch() === pathName
  const ageFromUrl = searchParamFromUrl('ageList')
  const [age, setAge] = useState<string>(ageFromUrl ?? '')
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()

  useEffect(() => {
    setAge(searchParamFromUrl('ageList') ?? '')
  }, [searchParams, pathName])

  const pushSelectedAgeToUrl = useCallback(
    debounce((age) => {
      const queryParamsToPush = isSearchPage
        ? { ageList: age }
        : {
            ageList: age,
            condition: conditionPlaceholder,
            currentLocation: locationPlaceholder,
            distance: locationPlaceholder ? ANYWHERE_DISTANCE : undefined,
            query: interventionPlaceholder,
          }

      updateUrlToReflectSearchState(queryParamsToPush)
    }, 500),
    [],
  )

  return (
    <div className={styles['no-input-arrows']}>
      <input
        autoComplete='off'
        className='w-full text-lg font-semibold leading-[180%] placeholder:text-neutral500 focus:outline-none'
        id='search-bar-age-input'
        onChange={(event) => {
          setAge(event.currentTarget.value)
          pushSelectedAgeToUrl(event.currentTarget.value)
        }}
        placeholder={placeholderText}
        type='number'
        value={age}
      />
    </div>
  )
}
