import { Icon } from '@components/primitives/Icon'
import { classMerge } from '@components/utilities/classMerge'
import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons/'
import type { InputHTMLAttributes, RefObject } from 'react'

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  ariaLabel?: string
  error?: boolean
  icon?: IconDefinition
  noDefaultStyles?: boolean
  ref?: RefObject<HTMLInputElement>
  showSuccessWithValidValue?: boolean
}

export const inputBorderStyles = (
  value: InputHTMLAttributes<HTMLInputElement>['value'],
  error?: boolean,
  showSuccessWithValidValue?: boolean,
  readOnly?: boolean,
) => {
  const defaults = ['bg-neutral100', 'border-2', 'focus:outline-none']

  if (error) {
    return [...defaults, 'border-destructive300']
  }

  if (readOnly) {
    return defaults
  }

  // No value yet, so show neutral and add focus state
  if (!value) {
    return [
      ...defaults,
      'border-neutral100 hover:border-neutral400',
      'focus:border-primary300',
    ]
  }

  // Show success border if we have a validated state
  if (showSuccessWithValidValue) {
    return [...defaults, 'border-success200 focus:border-primary300']
  }

  // Show neutral border to indicate non-null content for non-validation inputs
  return [...defaults, 'border-neutral300']
}

/**
 * Generic Input wrapper to minimize requisite style choices.
 */
const Input = ({
  ariaLabel,
  autoFocus = false,
  className,
  disabled = false,
  error,
  icon,
  noDefaultStyles,
  ref,
  showSuccessWithValidValue = true,
  type = 'text',
  value,
  ...props
}: InputProps) => {
  const getClassName = () => {
    if (noDefaultStyles) {
      return className
    } else {
      return classMerge(
        'h-16 w-full rounded-full py-0',
        inputBorderStyles(value, error, showSuccessWithValidValue),
        {
          'hover:cursor-not-allowed': Boolean(disabled),
          'px-6': !Boolean(icon),
          'px-10 py-0': Boolean(icon),
        },
        className,
      )
    }
  }

  const input = (
    <input
      aria-label={ariaLabel}
      autoFocus={autoFocus}
      className={getClassName()}
      disabled={disabled}
      ref={ref}
      type={type}
      value={value}
      {...props}
    />
  )

  // TODO - https://linear.app/withpower/issue/POW-1879/text-input-icons-should-match-the-color-of-the-placeholder-and-not
  // Maybe we can stuff this into a component prop rather than relying on fragile pixel-absoluting
  return icon ? (
    <div className='relative w-full'>
      <Icon
        className={classMerge(
          'absolute left-4 top-1/2 -translate-y-1/2 transform text-base',
          value ? 'text-black' : 'text-neutral400',
        )}
        icon={icon}
      />
      {input}
    </div>
  ) : (
    input
  )
}

/**
 * Generic Input wrapper to minimize requisite style choices.
 */
export default Input
