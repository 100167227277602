import type { ClinicalTrialCollaborator, Organization } from '@prisma/client'

export function getSponsorNamesFromTrial(trial: {
  collaborators: (Partial<ClinicalTrialCollaborator> & {
    organization: Pick<Organization, 'class' | 'name'>
  })[]
}) {
  return trial.collaborators
    .filter(
      (collaborator) =>
        collaborator.organization.class === 'INDUSTRY' &&
        Boolean(collaborator.organization.name),
    )
    .map((collaborator) => collaborator.organization.name)
}
