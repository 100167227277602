import type { TrialAssociatedWithLocations } from '../types/TrialAssociatedWithLocations'

/**
 * Return a list of { lat, lng } objects
 * @param clinicalTrial  Clinical Trial with Locations
 */
export function getTrialLocationLatLngs(
  clinicalTrial: TrialAssociatedWithLocations,
) {
  const locations: Set<{ lat: number; lng: number }> = new Set()
  for (const location of clinicalTrial.trialLocations) {
    if (location?.lat && location?.lng) {
      locations.add({
        lat: parseFloat(location.lat),
        lng: parseFloat(location.lng),
      })
    }
  }
  return Array.from(locations)
}
