import type { Prisma } from '@prisma/client'
import { object, string } from 'zod'

// JSON blob looks like: {"OrgStudyId": "MCC-22-GU-80"}
export const OrgStudyIdInfoTypeValidator = object({
  OrgStudyId: string(),
}).partial()

export default function getOrgStudyId(trial: {
  orgStudyIdInfo: Prisma.JsonValue | null
}) {
  const { orgStudyIdInfo } = trial

  const validatedOrgStudyInfo =
    OrgStudyIdInfoTypeValidator.safeParse(orgStudyIdInfo)

  if (!validatedOrgStudyInfo.success) {
    return
  }

  return validatedOrgStudyInfo.data.OrgStudyId
}
