export default function removeSubstring(
  str: string,
  substrings: string | (string | RegExp)[],
  matchCase = true,
): string {
  const flags = matchCase ? 'gs' : 'gsi'
  if (typeof substrings === 'string') {
    return str.replace(new RegExp(substrings, flags), () => '').trim()
  }

  let result = str

  for (const sub of substrings) {
    // gs for global and "dot-all" to treat everything like a single line
    result = result.replace(new RegExp(sub, flags), '').trim()
  }

  return result
}
