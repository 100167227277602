import type {
  StatsConnectorParams,
  StatsRenderState,
  StatsWidgetDescription,
} from 'instantsearch.js/es/connectors/stats/connectStats'
import connectStats from 'instantsearch.js/es/connectors/stats/connectStats'
import { useConnector } from 'react-instantsearch'

/**
 * https://www.algolia.com/doc/api-reference/widgets/stats/react-hooks/?client=ts
 * The useStats Hook isn’t currently available in React InstantSearch Hooks,
 * but we can make it ourselves using useConnector() along with connectStats.
 */
export function useStats(props?: StatsConnectorParams): StatsRenderState {
  return useConnector<StatsConnectorParams, StatsWidgetDescription>(
    connectStats,
    props,
  )
}
