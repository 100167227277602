import styles from './styles.module.scss'

/**
 * This component can be used to wrap content that is being fetched. It will animate
 * a pulsing blur of skeleton content while real user content is being loaded.
 * @param children: A JSX.Element that we want to wrap with an animated blur
 */
export function LoadingBlur({
  centerOnBlur = false,
  children,
  isLoading = true,
}: {
  centerOnBlur?: boolean
  children: React.ReactNode
  isLoading?: boolean
}) {
  if (!isLoading) {
    return <>{children}</>
  }

  const className = centerOnBlur ? styles['centered-blur'] : styles.blur

  return <div className={className}>{children}</div>
}
