export function coerceToNumber<T = number>(
  maybeNumber: string | number | undefined,
  defaultValue: T | number = 0,
): number | T {
  if (typeof maybeNumber === 'number' || typeof maybeNumber === 'bigint') {
    return maybeNumber
  }

  const parsedNumber = parseFloat(maybeNumber?.toString() ?? '')
  if (Number.isNaN(parsedNumber)) {
    return defaultValue
  }

  return parsedNumber
}
