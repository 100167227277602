/**
 * We did an n-grams search through ct.gov trials text fields in order to find interesting attributes that we could
 * pull out into keywords.
 */

export type FilterItem = { label: string; matches: (string | RegExp)[] }

const GradeOfDisease = [
  {
    label: 'Grade I',
    matches: [/grade i[^iv]/, 'grade 1'], // don't match "grade ii" or "grade iv" for "grade i"
  },
  {
    label: 'Grade II',
    matches: [/grade ii[^i]/, 'grade 2'],
  },
  {
    label: 'Grade III',
    matches: ['grade iii', 'grade 3'],
  },
  {
    label: 'Grade IV',
    matches: ['grade iv', 'grade 4'],
  },
]

const GleasonScore = [
  {
    label: 'Gleason Score',
    matches: [/gleason score/, /gleason grade/],
  },
]

const StageOfDisease = [
  ...GradeOfDisease,
  {
    label: 'Stage I',
    matches: [/stage i[^iv]/i, 'stage 1'], // don't match "stage ii" or "stage iv" for "stage i"
  },
  {
    label: 'Stage II',
    matches: [/stage ii[^i]/, 'stage 2'], // don't match "stage iii" for "stage ii"
  },
  {
    label: 'Stage III',
    matches: ['stage iii', 'stage 3'],
  },
  {
    label: 'Stage IV',
    matches: ['stave iv', 'stage 4'],
  },
  {
    label: 'Locally Advanced',
    matches: ['locally advanced', 'advanced'],
  },
  {
    label: 'Class I',
    matches: [/class i[^iv]/, 'class 1'], // don't match "class ii" or "class iv" for "class i"
  },
  {
    label: 'Class II',
    matches: [/class ii[^i]/, 'class 2'],
  },
  {
    label: 'Class III',
    matches: ['class iii', 'class 3'],
  },
  {
    label: 'Class IV',
    matches: ['class iv', 'class 4'],
  },
  {
    label: 'Class V',
    matches: [/class v[^i]/, 'class 5'], // don't match "class vi" for "class v"
  },
  {
    label: 'Class VI',
    matches: ['class vi', 'class 6'],
  },
]

const Metastatic = [
  {
    label: 'Metastatic',
    matches: ['metastatic', 'metastasis', 'metastases'],
  },
]

const Risk = [
  {
    label: 'High Risk',
    matches: ['high risk'],
  },
  {
    label: 'Low Risk',
    matches: ['low risk'],
  },
  {
    label: 'Standard Risk',
    matches: ['standard risk'],
  },
]

const TreatmentStage = [
  {
    label: 'Recurrent',
    matches: ['recurrent'],
  },
  {
    label: 'Relapsed',
    matches: ['relapse', 'relapsed'],
  },
]
const TreatmentStageNonMutual = [
  {
    label: 'Refractory',
    matches: ['refractory'],
  },
]

export const PriorTreatments = [
  {
    label: 'Newly Diagnosed',
    matches: [
      'newly diagnosed',
      'naive to any treatment',
      'treatment naive',
      'treatment-naive',
      'treatment-naïve',
      'de novo',
    ],
  },

  {
    label: '1 Prior Treatment',
    matches: [
      'at least one prior',
      'at least one previous',
      '1 prior treatment',
      'recurrent',
      'relapsed',
      'relapse',
    ],
  },
  {
    label: '2 Prior Treatments',
    matches: [
      'at least two prior',
      'at least two previous',
      '2 Prior treatments',
    ],
  },
  {
    label: '3 Prior Treatments',
    matches: [
      'at least three prior',
      'at least three previous',
      '3 Prior treatments',
    ],
  },
]

const GeneticMarkers: FilterItem[] = [
  { label: 'triple negative', matches: ['triple negative'] },
  {
    label: 'her2 positive',
    matches: [
      'her2 positive',
      'her2-postive',
      'her-2 positive',
      'her2-enriched',
      /HER\(\+\)/i,
      /HER\+/i,
    ],
  },
  {
    label: 'her2 negative',
    matches: [
      'her2 negative',
      'her2-negative',
      'her-2 negative',
      /HER\(\-\)/i,
      /HER\-/i,
    ],
  },
  {
    label: 'BRCA positive',
    matches: [
      'BRCA positive',
      'positive BRCA status',
      'BRCA mutated',
      /BRCA(.*)mutat/i,
      /BRCA\(\+\)/i,
      /BRCA\+/i,
    ],
  },
  {
    label: 'BRCA1 positive',
    matches: [
      'BRCA1 positive',
      'positive BRCA1 status',
      'BRCA1 mutated',
      /BRCA1\(\+\)/i,
      /BRCA1\+/i,
    ],
  },
  {
    label: 'BRCA2 positive',
    matches: [
      'BRCA2 positive',
      'positive BRCA2 status',
      'BRCA2 mutated',
      /BRCA2\(\+\)/i,
      /BRCA2\+/i,
    ],
  },
  {
    label: 'BRCA negative',
    matches: [
      'BRCA negative',
      'BRCA negative',
      'negative BRCA status',
      /BRCA\(\-\)/i,
      /BRCA\-/i,
    ],
  },
  {
    label: 'BRCA1 negative',
    matches: [
      'BRCA1 negative',
      'BRCA1 negative',
      'negative BRCA1 status',
      /BRCA1\(\-\)/i,
      /BRCA1\-/i,
    ],
  },
  {
    label: 'BRCA2 negative',
    matches: [
      'BRCA2 negative',
      'BRCA2 negative',
      'negative BRCA2 status',
      /BRCA2\(\-\)/i,
      /BRCA2\-/i,
    ],
  },
  {
    label: 'estrogen receptor positive',
    matches: [
      'estrogen receptor positive',
      'ER positive',
      /ER\(\+\)/i,
      /ER\+/i,
    ],
  },
  {
    label: 'estrogen receptor negative',
    matches: [
      'estrogen receptor negative',
      'ER negative',
      /ER\(\-\)/i,
      /ER\-/i,
    ],
  },
  {
    label: 'progesterone receptor positive',
    matches: [
      'progesterone receptor positive',
      'PR positive',
      /PR\(\+\)/i,
      /PR\+/i,
    ],
  },
  {
    label: 'progesterone receptor negative',
    matches: [
      'progesterone receptor negative',
      'PR negative',
      /PR\(\-\)/i,
      /PR\-/i,
    ],
  },
  {
    label: 'BRAF mutation-positive',
    matches: ['BRAF mutation-positive', 'BRAF mutation'],
  },
  {
    label: 'NRAS mutation-positive',
    matches: ['NRAS mutation-positive', 'NRAS mutation'],
  },
  {
    label: 'V600 mutation-positive',
    matches: ['V600 mutation-positive', 'V600 mutation'],
  },
  {
    label: 'human epidermal growth factor receptor (HER2)-positive',
    matches: [
      /human epidermal growth factor receptor \(HER2\)\-positive/i,
      /HER2\(\+\)/,
      /HER2\+/,
    ],
  },
  {
    label: 'human epidermal growth factor receptor 2 negative (HER2-)',
    matches: [
      /human epidermal growth factor receptor \(HER2\)\-negative/i,
      /HER2\(\-\)/,
      /HER2\-/,
    ],
  },
  {
    label: 'epidermal growth factor receptor mutation',
    matches: [
      'epidermal growth factor receptor mutation',
      'EGFR mutation',
      /epidermal growth factor \(EDFR\) mutation/i,
    ],
  },
  {
    label: 'ALK-positive',
    matches: [
      'ALK-positive',
      'ALK positive',
      'ALK+',
      'ALK rearranged',
      'ALK rearrangement',
      'ALK mutation',
      'ALK mutated',
      'ALK translocation',
    ],
  },
  {
    label: 'ROS1 rearranged',
    matches: [
      'ROS1 rearranged',
      'ROS1 rearrangement',
      'ROS1-positive',
      'ROS1 translocation',
    ],
  },
  { label: 'CD19+', matches: [/CD19\+/i, 'CD19 positive', /CD19 \(\+\)/i] },
  { label: 'CD20+', matches: [/CD20\+/i, 'CD20 positive', /CD20 \(\+\)/i] },
  { label: 'CD22+', matches: [/CD22\+/i, 'CD22 positive', /CD22 \(\+\)/i] },
  { label: 'T315I mutation', matches: ['T315I mutation', 'mutation T315I'] },
  {
    label: 'Philadelphia chromosome positive',
    matches: [
      'Philadelphia chromosome positive',
      'Philadelphia chromosome-positive',
      'Philadelphia-positive',
      'Philadelphia positive',
    ],
  },
  {
    label: 'Philadelphia chromosome negative',
    matches: [
      'Philadelphia chromosome negative',
      'Philadelphia chromosome-negative',
      'Philadelphia-negative',
      'Philadelphia negative',
    ],
  },
  {
    label: 'TP53 mutated',
    matches: [
      'TP53 mutated',
      'TP53 gene mutated',
      'TP53 mutant',
      'TP53 mutation',
    ],
  },
  { label: 'BRAF wild-type', matches: ['BRAF wild-type'] },
  { label: 'CD7 positive', matches: ['CD7 positive', /CD7\+/i] },
  {
    label: 'CD30 positive',
    matches: [
      'CD30 positive',
      /CD30\+/i,
      'CD30-expressing',
      /CD 30\+/i,
      'CD30 expressing',
    ],
  },
]

const MonthlyVisitsMatchers: FilterItem[] = [
  { label: 'Monthly Visits', matches: ['monthly visits', /month(.+)? visit/i] },
]

const OnlineMatchers: FilterItem[] = [
  { label: 'Online', matches: ['online', 'remote', 'digital'] },
]

const VeteranMatcher = [
  {
    label: 'For Veterans',
    matches: ['veteran', 'armed forces', 'navy', 'army', 'air force'],
  },
]

const ReimbursementTravel = [
  {
    label: 'Travel Reimbursement',
    matches: [/travel.*reimburs/i, 'travel reimbursement', /reimburs.*travel/i],
  },
]

const ReimbursementAccomodations = [
  {
    label: 'Accommodation Reimbursement',
    matches: [
      /accom.*reimburs/i,
      'accommodation reimbursement',
      /reimburs.*accom/i,
    ],
  },
]

export const keywordMatchers = [
  StageOfDisease,
  Metastatic,
  Risk,
  TreatmentStage,
  TreatmentStageNonMutual,
  PriorTreatments,
]

export const diseaseStageFilterOptions: FilterItem[] = [
  ...StageOfDisease,
  ...Metastatic,
  ...Risk,
]
export const treatmentStageFilterOptions: FilterItem[] = PriorTreatments

export const geneticMarkerFilterOptions: FilterItem[] = GeneticMarkers

export const monthlyVisitsFilterOptions: FilterItem[] = MonthlyVisitsMatchers

export const onlineFilterOptions: FilterItem[] = OnlineMatchers

export const veteranFilterOptions: FilterItem[] = VeteranMatcher

export const reimbursementFilterOptions: FilterItem[] = [
  ...ReimbursementTravel,
  ...ReimbursementAccomodations,
]

export const gradeGleasonScoreOptions: FilterItem[] = [
  ...GradeOfDisease,
  ...GleasonScore,
]
