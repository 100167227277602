import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'

export function getPINamesFromTrial(trial: TrialAssociation) {
  return trial.overallOfficials
    .filter(
      (official) =>
        official.role === 'Principal Investigator' && Boolean(official.name),
    )
    .map((official) => official.name)
}
