const DISALLOWED_FACET_TERMS = [
  /gleason/i, // tag exists for grade + gleason scores: "grade gleason score"
  /line.*therapy/i, // tag exists for line of therapy: "treatments"
  /stage/i, // tag exists for stage I etc: "disease stage"
  /PSA/, // tag exists for "protein"
  /protein/, // random "protein" as single words
  /\/(L|ml)/, // ignore measurements
]

/**
 * Use a disallow list to filter out terms for Algolia faceting that won't be relevant,
 * such as removing "Gleason" from Biomarkers
 */
export function disallowTermsFilter(term: string): boolean {
  return DISALLOWED_FACET_TERMS.some((matcher) => matcher.test(term))
}

/**
 * Ensure the term is not on the disallow list. Filters out terms irrelevant terms for Algolia faceting,
 * such as removing "Gleason" from Biomarkers
 */
export function allowedTermsFilter(term: string): boolean {
  return !disallowTermsFilter(term)
}
