import { startOfMonth } from 'date-fns'

/**
 * Is the passed in date in the current month?
 *
 * @param when Date in question
 * @param current Date to compare
 */
export default function isInCurrentMonth(
  when = new Date(),
  current = new Date(),
): boolean {
  const currentMonthStart = startOfMonth(current)
  const isSameYear = currentMonthStart.getFullYear() === when.getFullYear()
  const isSameMonth = currentMonthStart.getMonth() === when.getMonth()

  // Must be same year and month to satisfy "current" month
  return isSameYear && isSameMonth
}
