import type { ArrayElement } from '@lib/types/arrayElement'
import { EFFECTOR_NSCLC } from '@modules/trialRecommendations/handleRecommendationsByColloquialTermStripped'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import { allowedTermsFilter } from './disallowTermsFilter'
import { getGeneticMarkerTermsFromEligibilityCriteria } from './getGeneticMarkerTermsFromEligibilityCriteria'

export function getGeneticMarkersInclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const geneticMarkers = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria(),
    new Set<string>(),
  )

  const markers = Array.from(geneticMarkers).sort().filter(allowedTermsFilter)

  // TODO, remove this https://linear.app/withpower/issue/POW-6531/%5Blisting-page-sanofi-demo%5D-remove-hardcoded-pl-d1-in-effector
  if (trial.nctId === EFFECTOR_NSCLC.nctId) {
    markers.push('PD-L1 positive')
  }

  return markers
}

export function getGeneticMarkersExclusionFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const geneticMarkers = trial.parsedEligibilityCriteria.reduce(
    extractTermsFromEligibilityCriteria({ isInclusion: false }),
    new Set<string>(),
  )

  return Array.from(geneticMarkers).sort()
}

function extractTermsFromEligibilityCriteria(
  { isInclusion }: { isInclusion: boolean } = { isInclusion: true },
) {
  return (
    terms: Set<string>,
    criterion: ArrayElement<
      TrialInDatabaseForAlgolia['parsedEligibilityCriteria']
    >,
  ) =>
    getGeneticMarkerTermsFromEligibilityCriteria({
      criterion,
      isInclusion,
      terms,
    })
}
