import type { Geolocation } from '@lib/types/Geolocation'
import { useCallback } from 'react'

interface UseGeocodeByPlaceIdProps {
  geocoderService?: google.maps.Geocoder
}

/**
 * Hook that acts as a function factory. The generated function takes a Google Place ID and a callback.
 * The callback is invoked with the result from the geocoding query given the Place ID. The result is
 * a lat / lng Geolocation object.
 *
 * @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#advanced-cost-optimization
 *
 * @param props.geocoderService Google Maps Geocoder service
 */
const useGeocodeByPlaceId = ({ geocoderService }: UseGeocodeByPlaceIdProps) => {
  /**
   * Get the geolocated latitude and longitude given a Google Place ID. Invoke the callback with this geolocation.
   */
  const geocodeByPlaceId = useCallback(
    (placeId: string, callback: (location: Geolocation) => void) => {
      if (geocoderService) {
        geocoderService.geocode(
          { placeId },
          (
            results: google.maps.GeocoderResult[] | null,
            status: google.maps.GeocoderStatus,
          ) => {
            if (
              status !== google.maps.GeocoderStatus.OK ||
              !results ||
              !results[0]
            ) {
              return
            }

            const location = {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng(),
            }

            callback(location)
          },
        )
      }
    },
    [geocoderService],
  )

  return geocodeByPlaceId
}

export default useGeocodeByPlaceId
