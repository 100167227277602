import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'

/**
 * Effectiveness Scores, lowest first if multiple phases present
 *
 * - Phase 4: 3
 * - Phase 3: 2
 * - Phase 1/2: 1
 * - Unphased: 1
 *
 * @param trial Trial in database with phase list
 */
export default function getEffectivenessScoreFromTrial(
  trial: Pick<TrialAssociation, 'phaseList'>,
) {
  if (
    trial.phaseList.includes('Phase 2') ||
    trial.phaseList.includes('Phase 1') ||
    trial.phaseList.includes('Not Applicable') ||
    trial.phaseList.includes('N/A')
  ) {
    return 1
  }

  if (trial.phaseList.includes('Phase 3')) {
    return 2
  }

  if (trial.phaseList.includes('Phase 4')) {
    return 3
  }

  return 1
}
