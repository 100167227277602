/**
 * A naive check for email format. Not intended to be used to guarantee an email address
 * is valid or active, but rather a simple test to ensure that clearly invalid values do not
 * get passed into an email input field.
 * @param email the email address to validate
 * @returns a boolean indication whether or not the email format is valid
 */
export default function validateEmailFormat(email: string): boolean {
  if (/\.$/.test(email)) {
    return false
  }

  if (/^([a-z0-9+_\.-]+)@(.+\.\..+)/i.test(email)) {
    return false
  }

  const reg = new RegExp(
    /^([a-z0-9+_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,8})$/,
    'i',
  )

  return reg.test(email)
}
