import type { DescriptiveToClassicLookupTable } from './types'

/**
 * Explicit table with a descriptive stage map to one or more classic stages.
 *
 * @see convertFromDescriptiveToClassic for usage
 */
export const DescriptiveToClassicLookup: DescriptiveToClassicLookupTable = {
  default: [
    { classic: 'Stage I', descriptive: 'early stage' },
    { classic: 'Stage II', descriptive: 'early stage' },
    { classic: 'Stage III', descriptive: 'locally advanced' },
    { classic: 'Stage IV', descriptive: 'advanced' },
    { classic: 'Stage IV', descriptive: 'metastatic' },
  ],
}
