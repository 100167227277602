import isPlacebo from '@modules/interventions/helpers/isPlacebo'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'

/**
 * Assert that no arm groups for this trial have a placebo.
 *
 * @param interventions List of interventions for a trial
 */
export default function getInterventionsHaveNoPlaceboForTrial(
  interventions: TrialInDatabaseForAlgolia['interventions'],
) {
  return !getInterventionsHavePlaceboForTrial(interventions)
}

function getInterventionsHavePlaceboForTrial(
  interventions: TrialInDatabaseForAlgolia['interventions'],
) {
  return interventions.some(isPlacebo)
}
