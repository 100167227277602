import { classMerge } from '@components/utilities/classMerge'
import type { ColorGroup } from '@lib/colors/colors'
import type { FormEvent } from 'react'
import { useCallback, useState } from 'react'

type ButtonBarItemValue = string

export type ButtonBarItem = {
  label: string
  value: ButtonBarItemValue
}

export interface ButtonBarProps {
  className?: string
  colorGroup?: ColorGroup
  defaultPicked?: ButtonBarItemValue[]
  fontSize?: 'text-base' | 'text-sm'
  items: ButtonBarItem[]
  onChange: (newValues: string[]) => void
}

export const ALL_SPECIAL_VALUE = 'all'

function getConfigByColorGroup(colorGroup: ColorGroup) {
  switch (colorGroup) {
    case 'blue':
      return {
        activeBackgroundColor: 'bg-neutral50',
        activeBorderColor: 'border-neutral100',
        activeTextColor: 'text-blue500',
        inactiveBackgroundColor: 'bg-white',
        inactiveBorderColor: 'border-neutral100',
        inactiveTextColor: 'text-neutral600',
      }
    case 'primary':
    default:
      return {
        activeBackgroundColor: 'bg-primary100',
        activeBorderColor: 'border-primary700',
        activeTextColor: 'text-primary700',
        inactiveBackgroundColor: 'bg-neutral100',
        inactiveBorderColor: 'border-neutral300',
        inactiveTextColor: 'text-black',
      }
  }
}

export default function ButtonBar({
  className,
  colorGroup = 'primary',
  defaultPicked = [],
  fontSize = 'text-base',
  items,
  onChange,
}: ButtonBarProps) {
  const [pickedValues, setPickedValues] =
    useState<ButtonBarItemValue[]>(defaultPicked)

  const onClick = useCallback(
    (event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault()
      const { value } = event.currentTarget

      setPickedValues((previousValues) => {
        if (value === ALL_SPECIAL_VALUE) {
          const newValues = [ALL_SPECIAL_VALUE]
          onChange(newValues)
          return newValues
        } else if (previousValues.includes(value)) {
          const newValues = previousValues.filter((v) => v !== value)
          const adjustedNewValues =
            newValues.length === 0 ? [ALL_SPECIAL_VALUE] : newValues
          onChange(adjustedNewValues)
          return adjustedNewValues
        } else {
          const newValues = previousValues
            .concat(value)
            .filter((v) => v !== ALL_SPECIAL_VALUE)
          onChange(newValues)
          return newValues
        }
      })
    },
    [onChange, setPickedValues],
  )

  return (
    <div aria-label={`Selected ${pickedValues}`} className='flex items-center'>
      {items.map(({ label, value }, index) => {
        const active = pickedValues.includes(value.toString())
        const config = getConfigByColorGroup(colorGroup)
        return (
          <button
            className={classMerge(
              'border-2',
              'cursor-pointer',
              'items-center',
              'first:rounded-l',
              'flex',
              'last:rounded-r',
              'justify-center',
              fontSize,
              {
                [config.activeBackgroundColor]: active,
                [config.activeBorderColor]: active,
                [config.activeTextColor]: active,
                [config.inactiveBackgroundColor]: !active,
                [config.inactiveBorderColor]: !active,
                [config.inactiveTextColor]: !active,
              },
              className,
            )}
            key={`bb-item-${label}-${index}`}
            onClick={onClick}
            tabIndex={0}
            value={value.toString()}
          >
            {label}
          </button>
        )
      })}
    </div>
  )
}
