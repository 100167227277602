import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import { hasMatch, parseInclusionStringFromTrial } from '.'
import type { FilterItem } from './ngramData'
import { onlineFilterOptions } from './ngramData'

export default function hasOnlineKeywordsFromTrial(
  trial: TrialInDatabaseForAlgolia,
) {
  const trialTextContent = parseInclusionStringFromTrial(trial)

  const trialMatchesTag = (tag: FilterItem) =>
    tag.matches.some((match) => hasMatch(trialTextContent, match))

  return onlineFilterOptions.some(trialMatchesTag)
}
