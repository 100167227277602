import type {
  DavinciBriefSummary,
  DavinciBriefSummaryWithStagesAndEpoch,
} from '@modules/trials/helpers/summaries/generateTrialSummary'
import type { TrialWithDavinciBriefSummaryBlob } from './getTrialBriefSummary'

export function getLatestDavinciBriefSummary(
  trial: TrialWithDavinciBriefSummaryBlob,
): DavinciBriefSummaryWithStagesAndEpoch | undefined {
  const davinciBriefSummary = trial.davinciBriefSummary as DavinciBriefSummary[]

  if (!Array.isArray(davinciBriefSummary)) {
    return
  }

  // FIXME: epochCreatedAt is actually unix timestamp as a string
  const latestDavinciBriefSummary = davinciBriefSummary.sort(
    (a, b) => parseInt(b.epochCreatedAt, 10) - parseInt(a.epochCreatedAt, 10),
  )[0]!
  if (!latestDavinciBriefSummary) {
    return
  }

  return {
    epochCreatedAt: latestDavinciBriefSummary.epochCreatedAt,
    stageOne: latestDavinciBriefSummary.stageOne.output,
    stageTwo: latestDavinciBriefSummary.stageTwo.output,
  }
}
