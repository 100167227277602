import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'

function isImmunotherapy(content: string | null) {
  return /immunotherapy|biologic/gi.test((content ?? '').toString())
}

/**
 * Do any of the interventions or arm group names or descriptions use "immunotherapy"?
 *
 * @param interventions Trial interventions with arm groups
 */
export default function hasImmunotherapyInterventionFromTrial(
  interventions: TrialInDatabaseForAlgolia['interventions'],
) {
  return interventions.some((intervention) => {
    return isImmunotherapy(
      [
        intervention.description,
        intervention.name,
        (intervention.otherNames ?? []).join(' '),
        intervention.armGroupsJoin.map((armGroupsJoin) =>
          [
            armGroupsJoin.armGroup.description,
            armGroupsJoin.armGroup.label,
          ].join(' '),
        ),
      ].join(' '),
    )
  })
}
